import React, { useState, useEffect, useRef } from 'react'
import { DataTable } from 'primereact/datatable';
import { Link, useNavigate } from "react-router-dom";
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import QRCode from "react-qr-code";
import { toPng } from "html-to-image";
import download from "downloadjs";
import 'primereact/resources/themes/lara-light-blue/theme.css';
import { makeRequest2 } from '../../../utils/makeRequest'
import { toastify } from '../../../utils/toast'
import { Dialog } from 'primereact/dialog';
import { getFamilyMembersURL, deleteFamilyMemberURL, handleFamilyMemberStatusURL, updateFamilyMemberURL } from '../../../utils/urls'
import { getItem } from '../../../utils/localStorage';
import Layout from '../component/layout'
import ConfirmDialog from '../component/confirmDialog';
import { filterObjectsByAllProperties } from '../../../utils/filterSearch';

function Family() {
  const qrCodeRef = useRef();
  const navigate = useNavigate()
  const [family, setFamily] = useState([])
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedQRCode, setSelectedQRCode] = useState({})
  const [visibleQRCode, setVisibleQRCode] = useState(false)
  const [customerId, setCustomerId] = useState('')
  const [customerNo, setCustomerNo] = useState("")
  const [selectedNo, setSelectedNo] = useState(null);
  const [confirmAction, setConfirmAction] = useState('');  // Track the current action ('delete', 'enable', 'disable')
  const [selectedDisableStatus, setSelectedDisableStatus] = useState(false);  // Track the current disable status
  const [isConfirmDialogVisible, setConfirmDialogVisible] = useState(false);
  const [selectedFamilyData, setSelectedFamilyData] = useState(null);
  const [selectedVehicleData, setSelectedVehicleData] = useState(null);
  const [selectedStaffData, setSelectedStaffData] = useState(null);
  const [actionType, setActionType] = useState('')
  const [visibleEdit, setVisibleEdit] = useState(false)
  const [fullName, setFullName] = useState('');
  const [relation, setRelation] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('')
  const [email, setEmail] = useState('')
  const qrCodeTemplate = (rowData) => {
    rowData.type = 'Family'
    rowData.customerId = customerId
    if (rowData.qrCode === undefined) {
      return `....`
    }
    else {
      if (rowData.qrCode) {
        return <button className="btn btn-dark btn-sm" onClick={
          () => {
            setSelectedQRCode(rowData)
            setSelectedNo(rowData.no);
            setVisibleQRCode(true)
          }
        }>QR Code</button>
      }
      else {
        return `....`
      }
    }
  }




  const handleDownloadQRCode = () => {
    if (qrCodeRef.current) {
      toPng(qrCodeRef.current)
        .then((dataUrl) => {

          download(dataUrl, "qrcode.png");
        })
        .catch((err) => {
          console.error("Oops, something went wrong!", err);
        });
    }
  };

  const fetchFamilyMembers = async () => {
    try {
      const CustomerId = await getItem('selectedCustomerId');
      const response = await makeRequest2(getFamilyMembersURL + '/' + CustomerId, 'GET', {})
      if (response.success) {
        setFamily(response.data.familyMembers)
      }
      else {
        throw new Error(response.error)
      }
    }
    catch (err) {
      throw new Error(err.message)
    }
  }
  const handleInputSearch = async (value) => {
    try {
      setSearchTerm(value);
      if (value === '') {
        fetchFamilyMembers()
      } else {
        const results = await filterObjectsByAllProperties(family, value);
        setFamily(results);
      }
    } catch (error) {
      toastify(error.message, 'error');
    }
  };





  const phoneNumberTemplate = (rowData) => {
    const phoneNumber = rowData.phoneNumber;
    return '+254' + phoneNumber
  }
  const statusTemplate = (rowData) => {
    const isDisabled = rowData.disabled;

    return (
      <span
        style={{
          fontWeight: 'bold',
          color: isDisabled ? '#ff4d4d' : '#4caf50',  // Red for Disabled, Green for Enabled
          padding: '5px 10px',
          borderRadius: '15px',
          backgroundColor: isDisabled ? '#ffebeb' : '#e8f5e9'  // Light red or green background
        }}
      >
        {isDisabled ? 'Disabled' : 'Enabled'}
      </span>
    );
  };
  const actionFamilyTemplate = (rowData) => {
    const isDisabled = rowData.disabled;


    return (
      <div className="actions">
        <Button
          icon={`ti ti-edit`}  // Fixed template literal for dynamic icon
          className="p-button-rounded p-button-success"
          onClick={() => {
            setSelectedFamilyData(rowData)
            setFullName(rowData.name)
            setEmail(rowData.email)
            setPhoneNumber(rowData.phoneNumber)
            setRelation(rowData.relation)
            setVisibleEdit(true)



          }}
          tooltip={'Edit'}  // Fixed dynamic tooltip
        />
        <Button
          icon="ti ti-trash"
          className="p-button-rounded p-button-danger mr-2"
          onClick={() => {
            setSelectedFamilyData(rowData)
            setConfirmAction('delete');
            setConfirmDialogVisible(true);
          }}
          tooltip="Delete"
        />
        <Button
          icon={`ti ${isDisabled ? 'ti-lock-open' : 'ti-lock'}`}  // Fixed template literal for dynamic icon
          className="p-button-rounded p-button-warning"
          onClick={() => {
            setSelectedFamilyData(rowData)
            setConfirmAction(isDisabled ? 'enable' : 'disable');  // Set action to enable/disable
            setSelectedDisableStatus(isDisabled);
            setConfirmDialogVisible(true);

          }}
        />

      </div>



    );
  };

  const handleFamilyDelete = async (Id) => {
    try {
      const customerId = await getItem('selectedCustomerId');
      const response = await makeRequest2(deleteFamilyMemberURL + '/' + customerId + '/' + Id, 'DELETE', '');
      if (response.success) {
        setConfirmDialogVisible(false);
        setConfirmAction('');
        fetchFamilyMembers()
        toastify(response.data, 'success')
      }
      else {
        throw new Error(response.error)
      }
    }
    catch (err) {
      toastify(err.message, 'error')
    }
  };
  const handleFamilyStatus = async (Id) => {
    try {
      const customerId = await getItem('selectedCustomerId');

      const response = await makeRequest2(handleFamilyMemberStatusURL + '/' + customerId + '/' + Id, 'POST', {
        disabled: !selectedFamilyData.disabled
      });
      if (response.success) {
        setConfirmDialogVisible(false);
        setConfirmAction('');
        fetchFamilyMembers()
        toastify(response.data, 'success')
      }
      else {
        throw new Error(response.error)
      }
    }
    catch (err) {
      toastify(err.message, 'error')
    }
  };
  const handleConfirmAction = async () => {
    setConfirmDialogVisible(false);


    if (confirmAction === 'delete') {
      // Handle delete logic here
      await handleFamilyDelete(selectedFamilyData._id);
    } else if (confirmAction === 'enable' || confirmAction === 'disable') {
      // Handle enable/disable logic here
      await handleFamilyStatus(selectedFamilyData._id);
    }

  };
  const getCustomerId = async () => {
    const cID = await getItem('selectedCustomerId');
    setCustomerId(cID)
  }
  const getCustomerNo = async () => {
    try {
      const customerNo = await getItem('selectedCustomerNo')
      setCustomerNo(customerNo)
    }
    catch (err) {
      console.log(err.message)
    }
  }
  const updateFamilyMember = async () => {
    try {
      if (fullName === '') {
        throw new Error('Full Name is required.')
    }
    else if (relation === '') {
        throw new Error('Relation is required.')
    }
    else if (phoneNumber === '') {
        throw new Error('Phone Number is required.')
    }
    else if (!/^(07\d{8}|254\d{9})$/.test(phoneNumber)) {
        throw new Error('Phone number must be in the format "0712345678" or "254712345678".');
      }
  
    else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        throw new Error('Invalid email address format.');
    }
    else
    {
      const familyId = selectedFamilyData._id;
      const customerId = await getItem('selectedCustomerId');
      const response = await makeRequest2(updateFamilyMemberURL + '/' + customerId + '/' + familyId, 'POST', {
        fullName,
        relation,
        phoneNumber,
        email
      })
      if (response.success) {
        toastify(response.data, 'success');
        setVisibleEdit(false);
        fetchFamilyMembers()
      }
      else {
        throw new Error(response.error)
      }
    }
     
    }
    catch (err) {
      toastify(err.message, 'error')
    }
  }
  useEffect(() => {
    fetchFamilyMembers()
    getCustomerId()
    getCustomerNo()
  }, [])

  const header = (
    <div className="flex align-items-center justify-content-end gap-2">
      <Button type="button" label="CSV" rounded onClick={() => { }} data-pr-tooltip="CSV" />
      <Button type="button" label="XLS" severity="success" rounded onClick={() => { }} data-pr-tooltip="XLS" />
      <Button type="button" label="PDF" severity="warning" rounded onClick={() => { }} data-pr-tooltip="PDF" />
    </div>
  );
  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/resident/"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"#"}>Visitor Access</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/resident/visitor_access/family"}>Family </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">


          <div className="row">

            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">


                    <div className="col-md-3 col-xs-12 mb-3">
                      <input
                        className="form-control"
                        placeholder="Search here"
                        value={searchTerm}
                        onChange={(e) => { handleInputSearch(e.target.value) }}
                      />
                    </div>
                    <div className="col-md-9 col-xs-12 mt-3" >
                      <div style={{ float: 'right' }}>
                        <br />
                        <button className="btn btn-primary" onClick={() => { navigate('/resident/visitor_access/add_family') }}><i className="ti ti-plus pt-2"></i> &nbsp;Add Family</button>
                      </div>

                    </div>
                  </div>

                </div>
                <div className="card-body">
                  <div className="dt-responsive table-responsive">
                    <DataTable value={family} emptyMessage="No family found." sortMode="multiple" paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>
                      <Column field="no" header="Number"></Column>
                      <Column field="name" header="Name"></Column>
                      <Column body={phoneNumberTemplate} header="Phone Number"></Column>
                      <Column field="email" header="Email"></Column>
                      <Column field="relation" header="Relation"></Column>
                      <Column body={qrCodeTemplate} header="QRCode" />
                      <Column body={statusTemplate} header="Status" />

                      <Column body={actionFamilyTemplate} header="Actions"></Column>

                    </DataTable>


                  </div>
                </div>
              </div>
            </div>


          </div>

        </div>
      </div>
      <Dialog
        header={'Edit'}
        visible={visibleEdit}
        style={{ width: '50vw' }}
        onHide={() => {
          if (visibleEdit) setVisibleEdit(false);
        }}
      >
        <div className="row">
          <div className="col-md-12">
            {
              selectedFamilyData && <>
                <div className="mt-2">
                  <label>Fullname:</label>
                  <input className="form-control mt-2" placeholder={selectedFamilyData.name} value={fullName} onChange={(e) => {
                    setFullName(e.target.value)
                  }}></input>
                </div>

                <div className="mt-2">
                  <label>Relation:</label>
                  <input className="form-control mt-1" placeholder={selectedFamilyData.relation} value={relation} onChange={(e) => {
                    setRelation(e.target.value)
                  }}></input>
                </div>
                <div className="mt-2">
                  <label>Phone Number:</label>
                  <input className="form-control mt-1" placeholder={"254" + selectedFamilyData.phoneNumber} value={phoneNumber} onChange={(e) => {
                    setPhoneNumber(e.target.value)
                  }}></input>
                </div>
                <div className="mt-2">
                  <label>Email:</label>
                  <input className="form-control mt-1" placeholder={selectedFamilyData.email} value={email} onChange={(e) => {
                    setEmail(e.target.value)
                  }}></input>
                </div>
                <div className="mt-2" style={{ float: 'right' }}>
                  <button className="btn btn-primary" onClick={updateFamilyMember}>Update</button>
                </div>

              </>
            }
          </div>
        </div>


      </Dialog>
      <Dialog
        header={'QR Code'}
        visible={visibleQRCode}
        onHide={() => {
          if (visibleQRCode) setVisibleQRCode(false);
        }}
      >
        <div className="text-center mt-4">
          <div ref={qrCodeRef}>
            <QRCode value={JSON.stringify(selectedQRCode)} />
            <br />
            <br />
            <p style={{ color: 'white', backgroundColor: 'black' }}>{selectedNo}</p>
          </div>
          <br />
          <button className="btn btn-primary mt-3" onClick={handleDownloadQRCode}>
            Download QR Code
          </button>
        </div>


      </Dialog>
      <ConfirmDialog
        visible={isConfirmDialogVisible}
        onHide={() => setConfirmDialogVisible(false)}
        message={`Are you sure you want to ${confirmAction}`}
        onConfirm={handleConfirmAction}
      />

    </Layout>
  )
}


export default Family

import React, { useState, useEffect } from 'react'
import { Button } from 'primereact/button';
import { Link, useNavigate } from "react-router-dom";
import 'primereact/resources/themes/lara-light-blue/theme.css';
import { toastify } from '../../../utils/toast'
import { Dialog } from 'primereact/dialog';
import Layout from '../component/layout'
import { filterObjectsByAllProperties } from '../../../utils/filterSearch';
import { makeRequest2 } from '../../../utils/makeRequest';
import { getItem } from '../../../utils/localStorage';
import { addVehicleURL, residentUnitsURL } from '../../../utils/urls'
function AddVehicle() {
    const [unit, setUnit] = useState([])
    const [name, setName] = useState('');
    const [plateNumber, setPlateNumber] = useState('');
    const [model, setModel] = useState('')
    const [color, setColor ] =useState('')
    const [visitorQRCode, setVisitorQRCode] = useState(false)

    const navigate = useNavigate()

    const generateUniqueCode = () => {
        return Math.floor(10000 + Math.random() * 90000).toString(); // Generates a 5-digit unique number
    };

    const fetchResidentUnits = async () => {
        try {
            const CustomerId = await getItem('selectedCustomerId');
            const FacilityId = await getItem('selectedFacilityId');

            const response = await makeRequest2(residentUnitsURL + '/' + CustomerId + '/' + FacilityId, 'GET', {})
            if (response.success) {

                setUnit(response.data[0]._id)
            }
            else {
                throw new Error(response.error)
            }
        }
        catch (err) {
         
        }
    }

    const HandleSubmit = async () => {
        try {


            if (name === '') {
                throw new Error('Brand is required.')
            }
            if (model === '') {
                throw new Error('Model is required.')
            }
            if (color === '') {
                throw new Error('Color is required.')
            }
            if (plateNumber === '') {
                throw new Error('Plate Number is required.')
            }
            const CustomerId = await getItem('selectedCustomerId');
            const uniqueCode = generateUniqueCode();


            const response = await makeRequest2(addVehicleURL + '/' + CustomerId, 'POST', {
                name, plateNumber, model, color, visitorQRCode, uniqueCode, unit
            })
            console.log("Unit", unit)
            if (response.success) {

                toastify(response.data, 'success')
                navigate('/resident/visitor_access/vehicles')
            }
            else {
                throw new Error(response.error)
            }

        }
        catch (err) {
            console.log(err)
            toastify(err.message, 'error')
        }
    }

    useEffect(()=>{
        fetchResidentUnits()
    },[])


    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" label="CSV" rounded onClick={() => { }} data-pr-tooltip="CSV" />
            <Button type="button" label="XLS" severity="success" rounded onClick={() => { }} data-pr-tooltip="XLS" />
            <Button type="button" label="PDF" severity="warning" rounded onClick={() => { }} data-pr-tooltip="PDF" />
        </div>
    );
    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/resident/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Visitor Access</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/resident/visitor_access/vehicles"}>Vehicles</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Add Vehicle</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <Link to="/resident/visitor_access/vehicles">
                                <i className="ti ti-arrow-left"></i>
                                &nbsp;
                                Back
                            </Link>

                        </div>

                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label className="form-label">Brand</label>
                                        <input type="text" className="form-control" value={name} placeholder="Brand e.g Toyota" onChange={(e) => {
                                            setName(e.target.value)
                                        }} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label className="form-label">Model</label>
                                        <input type="text" className="form-control" value={model} placeholder='Model' onChange={(e) => {
                                            setModel(e.target.value)
                                        }} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label className="form-label">Color</label>
                                        <input type="text" className="form-control" value={color} placeholder='Color' onChange={(e) => {
                                            setColor(e.target.value)
                                        }} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label className="form-label">Plate Number</label>
                                        <input type="text" className="form-control" value={plateNumber} placeholder='Plate Number' onChange={(e) => {
                                            setPlateNumber(e.target.value)
                                        }} />
                                    </div>
                                </div>
                               
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <input type="checkbox" placeholder="name"
                                            value={visitorQRCode}
                                            checked={visitorQRCode}
                                            onChange={(e) => {

                                                setVisitorQRCode(!visitorQRCode)

                                            }}
                                        ></input>
                                        &nbsp;&nbsp;
                                        <label>Generate Access QR Code</label>
                                    </div>

                                </div>


                            </div>
                        </div>
                        <div className="card-footer text-end btn-page">

                            <div className="btn btn-primary" onClick={() => {
                                HandleSubmit()
                            }}>Submit</div>
                        </div>
                    </div>
                </div>
            </div>

        </Layout>
    )
}


export default AddVehicle
// ServiceHistoryList.js
import React from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

const StatusTemplate = ({ status }) => {
    const getStatusConfig = (status) => {
        switch (status.toLowerCase()) {
            case 'pending':
                return {
                    color: '#ff9800',
                    bgColor: '#fff3e0'
                };
            case 'awaiting':
                return {
                    color: '#2196f3',
                    bgColor: '#e3f2fd'
                };
            case 'in progress':
                return {
                    color: '#2196f3',
                    bgColor: '#e3f2fd'
                };
            case 'completed':
                return {
                    color: '#4caf50',
                    bgColor: '#e8f5e9'
                };
            case 'cancelled':
                return {
                    color: '#ff4d4d',
                    bgColor: '#ffebeb'
                };
            default:
                return {
                    color: '#2196f3',
                    bgColor: '#e3f2fd'
                };
        }
    };

    const config = getStatusConfig(status);

    return (
        <span
            style={{
                fontWeight: "bold",
                color: config.color,
                padding: "5px 10px",
                borderRadius: "15px",
                backgroundColor: config.bgColor,
                display: "inline-block",
                textAlign: "center",
                minWidth: "100px"
            }}
        >
            {status}
        </span>
    );
};

const ServiceHistoryList = ({
    serviceData,
    searchTerm,
    onSearchChange,
    getServiceName,
    onViewDetails
}) => {
    const filteredData = serviceData.filter(request =>
        getServiceName(request.serviceId).toLowerCase().includes(searchTerm.toLowerCase()) ||
        request.status.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="col-lg-6 mb-4">
            <div className="d-flex justify-content-between align-items-center mb-4">
                <h6 className="mb-0">Request History</h6>
                <div className="col-md-6 px-0">
                    <span className="p-input-icon-left w-100">
                        <i className="pi pi-search" />
                        <InputText
                            value={searchTerm}
                            onChange={(e) => onSearchChange(e.target.value)}
                            placeholder="Search requests..."
                            className="form-control"
                        />
                    </span>
                </div>
            </div>

            <ul className="list-group">
                {filteredData.length > 0 ? (
                    filteredData
                        .slice(0, 5)
                        .map((service, index) => (
                            <li className="list-group-item" key={index}>
                                <div className="row align-items-center">
                                    <div className="col-5">
                                        {getServiceName(service.serviceId)}
                                    </div>
                                    <div className="col-4 text-center">
                                        <StatusTemplate status={service.status} />
                                    </div>
                                    <div className="col-3 text-end">
                                        <Button
                                            icon="fas fa-eye"
                                            className="p-button-text"
                                            onClick={() => onViewDetails(service)}
                                        />
                                    </div>
                                </div>
                            </li>
                        ))
                ) : (
                    <li className="list-group-item text-center py-4">
                        <div className="text-muted">
                            <i className="pi pi-inbox mb-2" style={{ fontSize: '24px' }}></i>
                            <p className="mb-0">No service requests yet</p>
                        </div>
                    </li>
                )}
            </ul>
        </div>
    );
};

export default ServiceHistoryList;
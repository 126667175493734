import React, { useState, useEffect } from 'react'
import Layout from '../component/layout'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link } from 'react-router-dom'
import { toastify } from '../../../utils/toast';
import { makeRequest2 } from '../../../utils/makeRequest';
import { getCustomerContractsURL } from '../../../utils/urls';

import { getItem } from '../../../utils/localStorage';

const Contracts = () => {
    const [contracts, setContracts] = useState([]);

    const fetchContracts = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');
            const customerId = await getItem('selectedCustomerId');

            const response = await makeRequest2(getCustomerContractsURL + '/' + facilityId + '/' + customerId, 'GET', {});
            if (response.success) {
                setContracts(response.data.contracts);
            } else {
                toastify('Failed to fetch contracts', 'error');
            }
        } catch (err) {
            toastify(err.message, 'error');
        }
    };

    const statusTemplate = (rowData) => {
        const statusStyles = {
            Active: { color: '#28a745', backgroundColor: '#e9fbe9' },
            Inactive: { color: '#dc3545', backgroundColor: '#fde9e9' },
        };

        const style = statusStyles[rowData.status] || {
            color: '#6c757d',
            backgroundColor: '#f1f1f1',
        };

        return (
            <span
                style={{
                    ...style,
                    padding: '0.5em 1em',
                    borderRadius: '12px',
                    fontWeight: 'bold',
                    fontSize: '0.9rem',
                    display: 'inline-block',
                }}
            >
                {rowData.status}
            </span>
        );
    };

    useEffect(() => {
        fetchContracts();
    }, [])
    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/resident/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Levy Contracts</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h5>Levy Contracts</h5>
                        </div>
                        <div className="card-body">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-3 mb-3">
                                            <input
                                                className="form-control"
                                                placeholder="Search here"
                                                onChange={(e) => {
                                                    const searchTerm = e.target.value.toLowerCase();
                                                    const filtered = contracts.filter(contract =>
                                                        contract.contractName.toLowerCase().includes(searchTerm)
                                                    );
                                                    setContracts(filtered);
                                                }}
                                            />
                                        </div>
                                        <div className="table-responsive">
                                            <DataTable value={contracts} emptyMessage="No Contract found." sortMode="multiple" paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>
                                                <Column field="contractName" header="Contract Name"></Column>
                                                <Column field="startDate" header="Start Date" body={(rowData) => new Date().toISOString().split('T')[0]}></Column>
                                                <Column field="endDate" header="End Date" body={(rowData) => new Date().toISOString().split('T')[0]}></Column>
                                                <Column field="status" header="Status" body={statusTemplate}></Column>
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );

}

export default Contracts
import React, { useState, useEffect } from "react";
import Layout from "../../component/layout";
import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { TabView, TabPanel } from 'primereact/tabview';
import { toastify } from '../../../../utils/toast';
import { getItem } from "../../../../utils/localStorage";
import { makeRequest2 } from "../../../../utils/makeRequest";
import { getCustomerInvoicesURL } from "../../../../utils/urls";

const InvoiceManagement = () => {
  const [invoices, setInvoices] = useState([]);
  const [summary, setSummary] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    recipient: '',
    startDate: '',
    endDate: ''
  });
  const [activeIndex, setActiveIndex] = useState(0);

  const fetchInvoices = async () => {
    setLoading(true);
    try {
      const facilityId = await getItem('selectedFacilityId');
      const customerId = await getItem('selectedCustomerId');
      console.log("CustomerId", customerId);
      console.log("FacilityId", facilityId);

      if (!facilityId || !customerId) {
        toastify("Facility ID or Customer ID not found", 'error');
        return;
      }

      const response = await makeRequest2(
        `${getCustomerInvoicesURL}/${facilityId}/${customerId}`,
        "GET",
        {}
      );
      console.log(response);

      if (response.success && response.data?.data) {
        const fetchedInvoices = response.data.data.invoices || [];

        // Filter invoices to only include those with invoiceType 'Contract'
        const contractInvoices = fetchedInvoices.filter(invoice =>
          invoice.whatFor && invoice.whatFor.invoiceType === 'Contract'
        );

        setInvoices(contractInvoices); // Set only 'Contract' invoices
        setSummary(response.data.data.summary);
      } else {
        toastify("Failed to fetch invoices", 'error');
      }
    } catch (err) {
      toastify(err.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  // Format currency with the provided currencyCode
  const formatCurrency = (amount, currencyCode = 'KES') => {
    if (typeof amount !== 'number') return `${currencyCode} 0.00`;
    return `${currencyCode} ${amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
  };

  // Get currency code for individual invoice
  const getInvoiceCurrency = (invoice) => {
    // Try to get currency from the invoice object if it exists
    if (invoice.currency && invoice.currency.code) {
      return invoice.currency.code;
    }

    // Default to KES if no currency indicator is found
    return 'KES';
  };

  const statusTemplate = (rowData) => {
    const statusStyles = {
      Unpaid: { color: '#dc3545', backgroundColor: '#fde9e9' },
      Paid: { color: '#28a745', backgroundColor: '#e9fbe9' },
      Pending: { color: '#007bff', backgroundColor: '#e3f2fd' },
      Overdue: { color: '#dc3545', backgroundColor: '#fde9e9' },
      'Partially Paid': { color: '#ffc107', backgroundColor: '#fff3cd' },
      Cancelled: { color: '#6c757d', backgroundColor: '#f8f9fa' }
    };

    const style = statusStyles[rowData.status] || {
      color: '#6c757d',
      backgroundColor: '#f1f1f1',
    };

    return (
      <span
        style={{
          ...style,
          padding: '0.5em 1em',
          borderRadius: '12px',
          fontWeight: 'bold',
          fontSize: '0.9rem',
          display: 'inline-block',
        }}
      >
        {rowData.status}
      </span>
    );
  };

  const actionTemplate = (rowData) => (
    <Link
      to={`/resident/statement/view_invoice/${rowData._id}`}
      style={{
        color: '#007bff',
        textDecoration: 'none',
        fontSize: '18px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      title="View Invoice"
    >
      <i className="ti ti-eye"></i>
    </Link>
  );

  const handleSearch = () => {
    // Implement search functionality here
    console.log('Search with filters:', filters);
  };

  useEffect(() => {
    fetchInvoices();
  }, []);

  // Render summaries based on whether there are multiple currencies
  const renderSummaries = () => {
    if (!summary) return null;

    // If there are no mixed currencies, show standard summary
    if (!summary.hasMixedCurrencies) {
      const currencyCode = summary.currencyCode || 'KES';
      return (
        <div className="card-header bg-light">
          <div className="row text-center">
            <div className="col-md-3">
              <h6>Total Invoices</h6>
              <strong>{summary.totalInvoices}</strong>
            </div>
            <div className="col-md-3">
              <h6>Total Amount</h6>
              <strong>{formatCurrency(summary.totalAmount, currencyCode)}</strong>
            </div>
            <div className="col-md-3">
              <h6>Total Paid</h6>
              <strong>{formatCurrency(summary.totalPaid, currencyCode)}</strong>
            </div>
            <div className="col-md-3">
              <h6>Outstanding</h6>
              <strong>{formatCurrency((summary.totalAmount || 0) - (summary.totalPaid || 0), currencyCode)}</strong>
            </div>
          </div>
        </div>
      );
    }

    // If there are mixed currencies, show tabbed summaries
    if (summary.hasMixedCurrencies && summary.currencySummaries) {
      const currencies = Object.keys(summary.currencySummaries);

      return (
        <div className="card-header bg-light">
          <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
            {currencies.map((currencyCode, index) => {
              const currencySummary = summary.currencySummaries[currencyCode];
              return (
                <TabPanel key={currencyCode} header={`${currencyCode} Summary`}>
                  <div className="row text-center mt-3">
                    <div className="col-md-3">
                      <h6>Total Invoices</h6>
                      <strong>{currencySummary.totalInvoices}</strong>
                    </div>
                    <div className="col-md-3">
                      <h6>Total Amount</h6>
                      <strong>{formatCurrency(currencySummary.totalAmount, currencyCode)}</strong>
                    </div>
                    <div className="col-md-3">
                      <h6>Total Paid</h6>
                      <strong>{formatCurrency(currencySummary.totalPaid, currencyCode)}</strong>
                    </div>
                    <div className="col-md-3">
                      <h6>Outstanding</h6>
                      <strong>
                        {formatCurrency(
                          (currencySummary.totalAmount || 0) - (currencySummary.totalPaid || 0),
                          currencyCode
                        )}
                      </strong>
                    </div>
                  </div>
                </TabPanel>
              );
            })}
          </TabView>
        </div>
      );
    }

    // Fallback for incomplete data
    return (
      <div className="card-header bg-light">
        <div className="row text-center">
          <div className="col-md-12">
            <h6>Invoice Summary</h6>
            <p>Total Invoices: {summary.totalInvoices}</p>
          </div>
        </div>
      </div>
    );
  };

  // Filter invoices by currency code for tabbed view
  const getFilteredInvoices = () => {
    if (!summary || !summary.hasMixedCurrencies) {
      return invoices;
    }

    // If on "All" tab
    if (activeIndex === 0) {
      return invoices;
    }

    // Get the selected currency code
    const currencies = Object.keys(summary.currencySummaries);
    if (activeIndex <= currencies.length) {
      const selectedCurrency = currencies[activeIndex - 1];
      return invoices.filter(invoice =>
        getInvoiceCurrency(invoice) === selectedCurrency
      );
    }

    return invoices;
  };

  return (
    <div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            {/* Render summaries based on currency situation */}
            {/* {renderSummaries()} */}

            <div className="card-header">
              <div className="row">
                <div className="col-3">
                  <label>Enter Recipient:</label>
                  <input
                    type="text"
                    className="form-control mt-2"
                    placeholder="Enter Recipient"
                    value={filters.recipient}
                    onChange={(e) => setFilters({ ...filters, recipient: e.target.value })}
                  />
                </div>
                <div className="col-3">
                  <label>Start Date:</label>
                  <input
                    type="date"
                    className="form-control mt-2"
                    value={filters.startDate}
                    onChange={(e) => setFilters({ ...filters, startDate: e.target.value })}
                  />
                </div>
                <div className="col-3">
                  <label>End Date:</label>
                  <input
                    type="date"
                    className="form-control mt-2"
                    value={filters.endDate}
                    onChange={(e) => setFilters({ ...filters, endDate: e.target.value })}
                  />
                </div>
                <div className="col-3">
                  <button
                    className="btn btn-primary mt-4"
                    onClick={handleSearch}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="dt-responsive table-responsive">
                <DataTable
                  value={invoices}
                  emptyMessage="No invoices found."
                  sortMode="multiple"
                  paginator
                  rows={5}
                  stripedRows
                  loading={loading}
                  tableStyle={{ minWidth: '50rem' }}
                >
                  <Column field="invoiceNumber" header="Invoice Number" sortable />
                  <Column
                    field="totalAmount"
                    header="Amount"
                    style={{ minWidth: "120px" }}
                    body={(rowData) => {
                      const currencyCode = getInvoiceCurrency(rowData);
                      return formatCurrency(rowData.totalAmount, currencyCode);
                    }}
                    sortable
                  />
                  <Column
                    field="issueDate"
                    header="Issue Date"
                    sortable
                    body={(rowData) => formatDate(rowData.issueDate)}
                  />
                  <Column
                    field="dueDate"
                    header="Due Date"
                    sortable
                    body={(rowData) => formatDate(rowData.dueDate)}
                  />
                  <Column
                    field="status"
                    header="Status"
                    sortable
                    body={statusTemplate}
                  />
                  <Column
                    field="actions"
                    header="Actions"
                    body={actionTemplate}
                  />
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceManagement;
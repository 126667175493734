
import React, { useState, useEffect } from 'react'
import { DataTable } from 'primereact/datatable';
import { Link } from 'react-router-dom'
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import { makeRequest2 } from '../../../utils/makeRequest'
import { toastify } from '../../../utils/toast'
import { getItem } from '../../../utils/localStorage';
import { getTicketsURL, getTransactionsURL } from '../../../utils/urls'
import Layout from '../component/layout'
function ViewTickets() {
    const [tickets, setTickets] = useState([])

    const getTickets = async () => {
        try {
            const customerId = await getItem('selectedCustomerId');
            const facilityId = await getItem('selectedFacilityId');
            const response = await makeRequest2(getTicketsURL + '/' + facilityId + '/' + customerId, 'GET', {})
            console.log("Response", response)
            if (response.success) {
                setTickets(response.data)
                console.log("Response data:", response.data);

            }
        } catch (err) {
            toastify(err.message, 'error');
        }
    }



    const totalTicketsCount = tickets.length;

    const allTickets = tickets;
    const ResolvedTickets = tickets.filter((ticket) => ticket.status === 'resolved');
    const resolvedCount = tickets.filter(ticket => ticket.status === 'resolved').length;
    const openTickets = tickets.filter((ticket) => ticket.status === 'open');
    const openTicketsCount = tickets.filter(ticket => ticket.status === 'open').length;
    const onHoldTickets = tickets.filter((ticket) => ticket.status === 'on hold');
    const onHoldTicketsCount = tickets.filter(ticket => ticket.status === 'on hold').length;
    const ongoingTickets = tickets.filter((ticket) => ticket.status === 'ongoing');
    const ongoingTicketsCount = tickets.filter(ticket => ticket.status === 'ongoing').length;
    const cancelledTickets = tickets.filter((ticket) => ticket.status === 'cancelled');
    const cancelledTicketsCount = tickets.filter(ticket => ticket.status === 'cancelled').length;
    const closedTickets = tickets.filter((ticket) => ticket.status === 'closed');
    const closedTicketsCount = tickets.filter(ticket => ticket.status === 'closed').length;

    const statusTemplate = (rowData) => {
        const statusStyles = {
            open: { color: '#28a745', backgroundColor: '#e9fbe9' },
            closed: { color: '#007bff', backgroundColor: '#e0f7ff' },
            resolved: { color: '#17a2b8', backgroundColor: '#e0f4f7' },
            cancelled: { color: '#dc3545', backgroundColor: '#fde9e9' },
            ongoing: { color: '#ffc107', backgroundColor: '#fff8e1' },
        };

        const style = statusStyles[rowData.status] || {
            color: '#6c757d',
            backgroundColor: '#f1f1f1',
        };

        return (
            <span
                style={{
                    ...style,
                    padding: '0.5em 1em',
                    borderRadius: '12px',
                    fontWeight: 'bold',
                    fontSize: '0.9rem',
                    display: 'inline-block',
                }}
            >
                {rowData.status}
            </span>
        );
    };
    const priorityTemplate = (rowData) => {
        const priorityStyles = {
            low: { color: '#28a745', backgroundColor: '#e9fbe9' },
            medium: { color: '#ffc107', backgroundColor: '#fff8e1' },
            high: { color: '#dc3545', backgroundColor: '#fde9e9' },
        };
    
        const style = priorityStyles[rowData.priority.toLowerCase()] || {
            color: '#6c757d',
            backgroundColor: '#f1f1f1',
        };
    
        return (
            <span
                style={{
                    ...style,
                    padding: '0.5em 1em',
                    borderRadius: '12px',
                    fontWeight: 'bold',
                    fontSize: '0.9rem',
                    display: 'inline-block',
                }}
            >
                {rowData.priority || 'N/A'}
            </span>
        );
    };


    useEffect(() => {
        getTickets()
    }, [])

    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" label="CSV" rounded onClick={() => { }} data-pr-tooltip="CSV" />
            <Button type="button" label="XLS" severity="success" rounded onClick={() => { }} data-pr-tooltip="XLS" />
            <Button type="button" label="PDF" severity="warning" rounded onClick={() => { }} data-pr-tooltip="PDF" />
        </div>
    );
    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/resident/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/resident/view_tickets"}>View Tickets</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <ul className="nav nav-tabs invoice-tab border-bottom mb-3" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="analytics-tab-1" data-bs-toggle="tab"
                                        data-bs-target="#analytics-tab-1-pane" type="button" role="tab" aria-controls="analytics-tab-1-pane"
                                        aria-selected="true">
                                        <span className="d-flex align-items-center gap-2">All <span
                                            className="avtar rounded-circle bg-light-primary">{totalTicketsCount}</span></span>
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="analytics-tab-2" data-bs-toggle="tab"
                                        data-bs-target="#analytics-tab-2-pane" type="button" role="tab" aria-controls="analytics-tab-2-pane"
                                        aria-selected="false">
                                        <span className="d-flex align-items-center gap-2">Open <span
                                            className="avtar rounded-circle bg-light-success">{openTicketsCount}</span></span>
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="analytics-tab-3" data-bs-toggle="tab"
                                        data-bs-target="#analytics-tab-3-pane" type="button" role="tab" aria-controls="analytics-tab-3-pane"
                                        aria-selected="false">
                                        <span className="d-flex align-items-center gap-2">On Hold <span
                                            className="avtar rounded-circle bg-light-warning">{onHoldTicketsCount}</span></span>
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="analytics-tab-4" data-bs-toggle="tab"
                                        data-bs-target="#analytics-tab-4-pane" type="button" role="tab" aria-controls="analytics-tab-4-pane"
                                        aria-selected="false">
                                        <span className="d-flex align-items-center gap-2">Ongoing <span
                                            className="avtar rounded-circle bg-light-warning">{ongoingTicketsCount}</span></span>
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="analytics-tab-5" data-bs-toggle="tab"
                                        data-bs-target="#analytics-tab-5-pane" type="button" role="tab" aria-controls="analytics-tab-5-pane"
                                        aria-selected="false">
                                        <span className="d-flex align-items-center gap-2">Resolved <span
                                            className="avtar rounded-circle bg-light-info">{resolvedCount}</span></span>
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="analytics-tab-6" data-bs-toggle="tab"
                                        data-bs-target="#analytics-tab-6-pane" type="button" role="tab" aria-controls="analytics-tab-6-pane"
                                        aria-selected="false">
                                        <span className="d-flex align-items-center gap-2">Cancelled <span
                                            className="avtar rounded-circle bg-light-danger">{cancelledTicketsCount}</span></span>
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="analytics-tab-7" data-bs-toggle="tab"
                                        data-bs-target="#analytics-tab-7-pane" type="button" role="tab" aria-controls="analytics-tab-7-pane"
                                        aria-selected="false">
                                        <span className="d-flex align-items-center gap-2">Closed <span
                                            className="avtar rounded-circle bg-light-danger">{closedTicketsCount}</span></span>
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active mt-2" id="analytics-tab-1-pane" role="tabpanel"
                                    aria-labelledby="analytics-tab-1" tabindex="0">
                                    <div className="table-responsive">
                                        <DataTable value={allTickets} emptyMessage="No tickets found." sortMode="multiple" paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>
                                            <Column field="ticketNumber" header="Ticket Number"></Column>
                                            <Column field="ticketType" header="Ticket Type"></Column>
                                            <Column field="subject" header="Subject"></Column>
                                            <Column field="priority" header="Priority" body={priorityTemplate}></Column>
                                            <Column field="status" header="Status" body={statusTemplate}></Column>
                                            <Column field="date" header="Added On" body={(rowData) => new Date().toISOString().split('T')[0]}></Column>
                                        </DataTable>
                                    </div>
                                </div>
                                <div className="tab-pane fade mt-2" id="analytics-tab-2-pane" role="tabpanel"
                                    aria-labelledby="analytics-tab-2" tabindex="0">
                                    <div className="table-responsive">
                                        <DataTable value={openTickets} emptyMessage="No tickets found." sortMode="multiple" paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>
                                            <Column field="ticketNumber" header="Ticket Number"></Column>
                                            <Column field="ticketType" header="Ticket Type"></Column>
                                            <Column field="subject" header="Subject"></Column>
                                            <Column field="priority" header="Priority" body={priorityTemplate}></Column>
                                            <Column field="status" header="Status" body={statusTemplate}></Column>
                                            <Column field="date" header="Added On"></Column>
                                        </DataTable>
                                    </div>
                                </div>
                                <div className="tab-pane fade mt-2" id="analytics-tab-3-pane" role="tabpanel"
                                    aria-labelledby="analytics-tab-3" tabindex="0">
                                    <div className="table-responsive">
                                        <DataTable value={onHoldTickets} emptyMessage="No tickets found." sortMode="multiple" paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>
                                            <Column field="ticketNumber" header="Ticket Number"></Column>
                                            <Column field="ticketType" header="Ticket Type"></Column>
                                            <Column field="subject" header="Subject"></Column>
                                            <Column field="priority" header="Priority" body={priorityTemplate}></Column>
                                            <Column field="status" header="Status" body={statusTemplate}></Column>
                                            <Column field="date" header="Added On" body={(rowData) => new Date().toISOString().split('T')[0]}></Column>
                                        </DataTable>
                                    </div>
                                </div>
                                <div className="tab-pane fade mt-2" id="analytics-tab-4-pane" role="tabpanel"
                                    aria-labelledby="analytics-tab-4" tabindex="0">
                                    <div className="table-responsive">
                                        <DataTable value={ongoingTickets} emptyMessage="No tickets found." sortMode="multiple" paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>
                                            <Column field="ticketNumber" header="Ticket Number"></Column>
                                            <Column field="ticketType" header="Ticket Type"></Column>
                                            <Column field="subject" header="Subject"></Column>
                                            <Column field="priority" header="Priority" body={priorityTemplate}></Column>
                                            <Column field="status" header="Status" body={statusTemplate}></Column>
                                            <Column field="date" header="Added On" body={(rowData) => new Date().toISOString().split('T')[0]}></Column>
                                        </DataTable>
                                    </div>
                                </div>
                                <div className="tab-pane fade mt-2" id="analytics-tab-5-pane" role="tabpanel"
                                    aria-labelledby="analytics-tab-5" tabindex="0">
                                    <div className="table-responsive">
                                        <DataTable
                                            value={ResolvedTickets}
                                            emptyMessage="No resolved tickets found."
                                            paginator
                                            rows={5}
                                            stripedRows
                                            tableStyle={{ minWidth: '50rem' }}
                                        >
                                            <Column field="ticketNumber" header="Ticket Number"></Column>
                                            <Column field="ticketType" header="Ticket Type"></Column>
                                            <Column field="subject" header="Subject"></Column>
                                            <Column field="priority" header="Priority" body={priorityTemplate}></Column>
                                            <Column field="status" header="Status" body={statusTemplate}></Column>
                                            <Column field="date" header="Added On" body={(rowData) => new Date().toISOString().split('T')[0]} ></Column>
                                            <Column field="date" header="Resolved On" body={(rowData) => new Date().toISOString().split('T')[0]} ></Column>
                                        </DataTable>
                                    </div>
                                </div>

                                <div className="tab-pane fade mt-2" id="analytics-tab-6-pane" role="tabpanel"
                                    aria-labelledby="analytics-tab-6" tabindex="0">
                                    <div className="table-responsive">
                                        <DataTable value={cancelledTickets} emptyMessage="No tickets found." sortMode="multiple" paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>
                                            <Column field="ticketNumber" header="Ticket Number"></Column>
                                            <Column field="ticketType" header="Ticket Type"></Column>
                                            <Column field="subject" header="Subject"></Column>
                                            <Column field="priority" header="Priority" body={priorityTemplate}></Column>
                                            <Column field="status" header="Status" body={statusTemplate}></Column>
                                            <Column field="date" header="Added On" body={(rowData) => new Date().toISOString().split('T')[0]}></Column>
                                            <Column field="date" header="Cancelled On" body={(rowData) => new Date().toISOString().split('T')[0]}></Column>
                                        </DataTable>
                                    </div>
                                </div>

                                <div className="tab-pane fade mt-2" id="analytics-tab-6-pane" role="tabpanel"
                                    aria-labelledby="analytics-tab-6" tabindex="0">
                                    <div className="table-responsive">
                                        <DataTable value={closedTickets} emptyMessage="No tickets found." sortMode="multiple" paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>
                                            <Column field="ticketNumber" header="Ticket Number"></Column>
                                            <Column field="ticketType" header="Ticket Type"></Column>
                                            <Column field="subject" header="Subject"></Column>
                                            <Column field="priority" header="Priority" body={priorityTemplate}></Column>
                                            <Column field="status" header="Status" body={statusTemplate}></Column>
                                            <Column field="date" header="Added On" body={(rowData) => new Date().toISOString().split('T')[0]}></Column>
                                            <Column field="date" header="Closed On" body={(rowData) => new Date().toISOString().split('T')[0]}></Column>
                                        </DataTable>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Layout>
    )
}


export default ViewTickets
import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Layout from '../component/layout';
import { getVisitLogURL, acceptVisitRequestURL, denyVisitRequestURL } from '../../../utils/urls';
import { makeRequest2 } from '../../../utils/makeRequest';
import { toastify } from '../../../utils/toast';
import formatISODate from '../../../utils/formatDate';

const statusStyles = {
    'Visit Confirmation': {
        color: '#ff9800',         // Orange
        backgroundColor: '#fff3e0' // Light Orange
    },
    'Scheduled': {
        color: '#4caf50',         // Green
        backgroundColor: '#e8f5e9' // Light Green
    },
    'Checked In': {
        color: '#2196f3',         // Blue
        backgroundColor: '#e3f2fd' // Light Blue
    },
    'Checked Out': {
        color: '#9c27b0',         // Purple
        backgroundColor: '#f3e5f5' // Light Purple
    },
    'Cancelled': {
        color: '#f44336',         // Red
        backgroundColor: '#ffebee' // Light Red
    }
};
const GetDigitalLog = () => {
    const navigate = useNavigate()
    const { visitLogId } = useParams();
    const [log, setLog] = useState({});
    const [visitor, setVisitor] = useState({});
    const [resident, setResident] = useState({})



    const getVisitorLog = async () => {
        try {
            const response = await makeRequest2(getVisitLogURL + '/' + visitLogId, 'GET', {});

            if (response.success) {
                setLog(response.data.visitLog);
                setVisitor(response.data.visitor);
                setResident(response.data.resident);


            } else {
                throw new Error(response.error);
            }
        }
        catch (err) {
            toastify(err.message, 'error')
        }
    }

    const handleDenyVisit = async (visitId) => {
        try {
            const response = await makeRequest2(denyVisitRequestURL + '/' + visitId, 'GET', {});
            if (response.success) {
                toastify(response.data, 'success')
                getVisitorLog()
            }
            {
                throw new Error(response.error)
            }
        }
        catch (err) {
            console.log(err)
        }

    }
    const handleAcceptVisit = async (visitId) => {
        try {
            const response = await makeRequest2(acceptVisitRequestURL + '/' + visitId, 'GET', {});
            if (response.success) {
                toastify(response.data, 'success')
                getVisitorLog()
            }
            {
                throw new Error(response.error)
            }
        }
        catch (err) {
            console.log(err)
        }

    }


    useEffect(() => {
        getVisitorLog()
    }, [])
    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Visitor Access</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Digital Logs</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>View Digital Log</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <Link to={"#"} onClick={() => navigate(-1)}><span><i className="ti ti-arrow-narrow-left"></i> Back</span></Link>
                                </div>
                            </div>
                            <div className="row p-3">
                                <div className="col-md-3">

                                    <div className="card">
                                        <div className="card-body position-relative">
                                            <div className="text-center mt-3">
                                                <div className="chat-avtar d-inline-flex mx-auto">
                                                    <i className="ti ti-user me-2" style={{ fontSize: 100 }}></i>

                                                </div>
                                                <h5 className="mb-3"></h5>
                                                <span

                                                    style={{
                                                        fontWeight: 'bold',
                                                        padding: '5px 10px',
                                                        borderRadius: '15px',
                                                        ...statusStyles[log.status] // Apply styles based on status
                                                    }}
                                                >
                                                    {log.status}
                                                </span>

                                                <hr className="my-3 border border-secondary-subtle" />

                                                <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                    <p className="mb-0"><strong>Visitor Name: </strong>{visitor.firstName} {visitor.lastName}</p>
                                                </div>
                                                <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                    <p className="mb-0"><strong>ID Number: </strong>{visitor.idNumber}</p>
                                                </div>
                                                <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                    <p className="mb-0"><strong>Phone Number: </strong>+254{visitor.phoneNumber}</p>
                                                </div>
                                                {
                                                    log.status === 'Visit Confirmation' &&
                                                    <> <button className="btn btn-success" onClick={() => {
                                                        handleAcceptVisit(log._id)
                                                    }}>Confirm Visit</button>
                                                        &nbsp;
                                                        <button className="btn btn-danger" onClick={() => {
                                                            handleDenyVisit(log._id)
                                                        }}>Deny Visit</button>
                                                    </>

                                                }

                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div className="col-md-9">
                                    <div className="card">
                                        <div className="card-body">
                                            <p style={{ fontSize: 20 }}>VISIT DETAILS</p>
                                            <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                <p className="mb-0"><strong>Visitor Name: </strong>{log.visitorName}</p>
                                            </div>
                                            <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                <p className="mb-0"><strong>ID Number: </strong>{visitor.idNumber}</p>
                                            </div>
                                            <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                <p className="mb-0"><strong>Phone Number: </strong>+254{visitor.phoneNumber || 'N/A'}</p>
                                            </div>
                                            {
                                                log.startTime !== null &&
                                                <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                    <p className="mb-0"><strong>Visit Start Time: </strong>{formatISODate(log.startTime)}</p>
                                                </div>
                                            }
                                            {
                                                log.endTime !== null &&
                                                <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                    <p className="mb-0"><strong>Visit End Time: </strong>{formatISODate(log.endTime)}</p>
                                                </div>
                                            }
                                            {
                                                log.entryPoint !== null &&
                                                <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                    <p className="mb-0"><strong>Entry Point: </strong>{log.entryPoint}</p>
                                                </div>
                                            }
                                            {
                                                log.exitPoint !== null &&
                                                <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                    <p className="mb-0"><strong>Exit Point: </strong>{log.exitPoint}</p>
                                                </div>
                                            }


                                            {
                                                log.vehicle &&
                                                <>
                                                    <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                        <p className="mb-0"><strong>Vehicle Registration Number: </strong>{log.vehicle.registration} </p>
                                                    </div>
                                                    <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                        <p className="mb-0"><strong>Vehicle Make: </strong>{log.vehicle.make}</p>
                                                    </div>
                                                    <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                        <p className="mb-0"><strong>Vehicle Color: </strong>{log.vehicle.color}</p>
                                                    </div>
                                                    <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                        <p className="mb-0"><strong>Vehicle Occupants: </strong>{log.vehicle.occupants}</p>
                                                    </div>
                                                </>
                                            }
                                        </div>


                                    </div>

                                </div>

                            </div>




                        </div>
                    </div>
                </div>
            </div>





        </Layout>
    );
};






export default GetDigitalLog;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toastify } from "../../../utils/toast";
import { getItem } from "../../../utils/localStorage";
import { makeRequest2 } from "../../../utils/makeRequest";
import ConfirmDialog from "../component/confirmDialog";
import { approveWorkOrderURL, denyWorkOrderURL, getApprovalTicketURL } from "../../../utils/urls";

const MinimalApprovalPage = () => {
    const { ticketId } = useParams();

    const [ticket, setTicket] = useState('');
    const [confirmAction, setConfirmAction] = useState("");
    const [isConfirmDialogVisible, setConfirmDialogVisible] = useState(false);

    const [showCloseMessage, setShowCloseMessage] = useState(false);


    const fetchTicketDetails = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');
            const response = await makeRequest2(`${getApprovalTicketURL}/${facilityId}/${ticketId}`, 'GET', {});

            if (response.success) {
                setTicket(response.data);
            }
            else {
                throw new Error(response.message || 'Failed to fetch ticket details');
            }
        } catch (err) {
            toastify(err.message, 'error');
        }
    };

    const handleAccept = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');
            const customerId = await getItem('selectedCustomerId');

            const response = await makeRequest2(`${approveWorkOrderURL}/${facilityId}/${ticketId}`, 'POST', {
                status: 'in progress',
                requester: customerId,
            });

            if (response.success) {
                toastify('Work order activated successfully!', 'success');
                setShowCloseMessage(true);
                // setTimeout(() => window.close(), 5000);
            } else {
                throw new Error(response.message || 'Failed to activate work order');
            }
        } catch (err) {
            toastify(err.message, 'error');
        }
    };

    const handleDeny = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');
            const customerId = await getItem('selectedCustomerId');


            const response = await makeRequest2(`${denyWorkOrderURL}/${facilityId}/${ticketId}`, 'POST', {
                status: 'cancelled',
                requester: customerId,
            });

            if (response.success) {
                toastify('Work order cancelled successfully!', 'success');
            } else {
                throw new Error(response.message || 'Failed to cancel work order');
            }
        } catch (err) {
            toastify(err.message, 'error');
        }
    };

    const actionBodyTemplate = () => {
        return (
            <div className="d-flex justify-content-center gap-3 mt-4">
                <button
                    className="btn btn-success px-4 py-2"
                    onClick={() => {
                        setConfirmAction("accept");
                        setConfirmDialogVisible(true);
                    }}
                >
                    Accept
                </button>
                <button
                    className="btn btn-danger px-4 py-2"
                    onClick={() => {
                        setConfirmAction("deny");
                        setConfirmDialogVisible(true);
                    }}
                >
                    Deny
                </button>
            </div>
        );
    };

    const handleConfirmAction = async () => {
        setConfirmDialogVisible(false);

        if (confirmAction === "accept") {
            await handleAccept();
        } else if (confirmAction === "deny") {
            await handleDeny();
        }
    };

    useEffect(() => {
        fetchTicketDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="row">
            <div className="col-sm-12">
                <div className="row justify-content-center">
                    {showCloseMessage && (
                        <div className="alert alert-success text-center w-50">
                            The work order has been activated successfully.
                            <br />
                            <button
                                className="btn btn-primary btn-sm mt-3"
                                onClick={() => window.close()}
                            >
                                Close Page
                            </button>
                        </div>
                    )}
                    <div className="col-lg-8 col-md-10">
                        <div className="card shadow-sm">
                            <div className="card-header text-center py-3 h4">
                                🎟️ Ticket Details
                            </div>
                            <div className="card-body px-4 py-3">
                                <div className="row g-3">
                                    {/* Ticket Number */}
                                    <div className="col-md-6">
                                        <h6 className="fw-bold text-muted">
                                            <i className="fas fa-hashtag me-2"></i>Ticket Number:
                                        </h6>
                                        <p className="mb-1">{ticket?.ticketNumber || "N/A"}</p>
                                    </div>

                                    {/* Ticket Type */}
                                    <div className="col-md-6">
                                        <h6 className="fw-bold text-muted">
                                            <i className="fas fa-clipboard-list me-2"></i>Ticket Type:
                                        </h6>
                                        <p className="mb-1">{ticket?.ticketType || "N/A"}</p>
                                    </div>

                                    {/* Description */}
                                    <div className="col-md-12">
                                        <h6 className="fw-bold text-muted">
                                            <i className="fas fa-info-circle me-2"></i>Description:
                                        </h6>
                                        <p
                                            className="mb-1"
                                            style={{ textAlign: "justify", fontSize: "1rem" }}
                                        >
                                            {ticket?.description || "No description provided."}
                                        </p>
                                    </div>

                                    {/* Review Text */}
                                    <div className="col-md-12">
                                        <h6 className="fw-bold text-muted">
                                            <i className="fas fa-comment-alt me-2"></i>Review Text:
                                        </h6>
                                        <p
                                            className="mb-1"
                                            style={{ textAlign: "justify", fontSize: "1rem" }}
                                        >
                                            {ticket?.reviewText || "No review provided."}
                                        </p>
                                    </div>

                                    {/* Services */}
                                    <div className="col-md-12">
                                        <h6 className="fw-bold text-muted">
                                            <i className="fas fa-concierge-bell me-2"></i>Services:
                                        </h6>
                                        {ticket?.services && ticket.services.length > 0 ? (
                                            <ul className="list-unstyled mb-0">
                                                {ticket.services.map((service, index) => (
                                                    <li key={index}>
                                                        <span>
                                                            {service.description}:{" "}
                                                            <span className="text-primary fw-bold">
                                                                ${service.amount}
                                                            </span>
                                                        </span>
                                                    </li>
                                                ))}
                                            </ul>
                                        ) : (
                                            <p className="mb-0">No services available for this ticket.</p>
                                        )}
                                    </div>

                                    {/* Cost Attachment */}
                                    {ticket?.costAttachment && (
                                        <div className="col-md-12 text-center">
                                            <h6 className="fw-bold text-muted">
                                                <i className="fas fa-paperclip me-2"></i>Cost Attachment:
                                            </h6>
                                            <a
                                                href={`/${ticket.costAttachment}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="btn btn-link text-primary p-0"
                                            >
                                                View Attachment
                                            </a>
                                        </div>
                                    )}

                                    {/* Total Amount */}
                                    <div className="col-md-6">
                                        <h6 className="fw-bold text-muted">
                                            <i className="fas fa-dollar-sign me-2"></i>Total Amount:
                                        </h6>
                                        <p className="mb-1 text-primary fw-bold">
                                            ${ticket?.totalAmount || 0}
                                        </p>
                                    </div>

                                    {/* Action Buttons */}
                                    <div className="text-center mt-4">{actionBodyTemplate()}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Confirm Dialog */}
            <ConfirmDialog
                visible={isConfirmDialogVisible}
                onHide={() => setConfirmDialogVisible(false)}
                message={`Are you sure you want to ${confirmAction} this ticket?`}
                onConfirm={handleConfirmAction}
            />
        </div>


    );

};

export default MinimalApprovalPage;

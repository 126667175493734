import React, { useState, useEffect } from 'react'
import Layout from '../component/layout'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Link, useNavigate } from 'react-router-dom'
import { InputText } from 'primereact/inputtext'
import { toastify } from '../../../utils/toast'
import { makeRequest2 } from '../../../utils/makeRequest'
import { getServiceInvoices } from '../../../utils/urls'
import { getItem } from '../../../utils/localStorage'

const Invoices = () => {
    const [invoices, setInvoices] = useState([])
    const [loading, setLoading] = useState(false)
    const [searchKeyword, setSearchKeyword] = useState('')
    const navigate = useNavigate()

    const fetchInvoices = async () => {
        try {
            setLoading(true)
            const facilityId = await getItem('selectedFacilityId')
            const customerId = await getItem('selectedCustomerId')

            const response = await makeRequest2(
                `${getServiceInvoices}/${facilityId}/${customerId}`,
                'GET',
                {}
            )

            if (response.success) {
                setInvoices(response.data.data || [])
            } else {
                toastify('Failed to fetch VAS invoices', 'error')
            }
        } catch (err) {
            toastify(err.message, 'error')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchInvoices()
    }, [])

    const statusTemplate = (rowData) => {
        const statusStyles = {
            Paid: { color: '#28a745', backgroundColor: '#e9fbe9' },
            Pending: { color: '#ffc107', backgroundColor: '#fff8e1' },
            Overdue: { color: '#dc3545', backgroundColor: '#fde9e9' }
        }

        const style = statusStyles[rowData.status] || {
            color: '#6c757d',
            backgroundColor: '#f1f1f1'
        }

        return (
            <span
                className="px-3 py-1 rounded-full font-semibold inline-block"
                style={{ ...style, fontSize: '0.9rem' }}
            >
                {rowData.status}
            </span>
        )
    }

    const actionTemplate = (rowData) => {
        return (
            <div className="flex gap-2">
                <Button
                    icon="pi pi-eye"
                    className="p-button-rounded p-button-info p-button-outlined"
                    tooltip="View Details"
                    tooltipOptions={{ position: 'top' }}
                    onClick={() => navigate(`/resident/value_added_services/invoice_details/invoice_details/${rowData._id}`, {
                        state: { invoice: rowData }
                    })}
                />
            </div>
        )
    }

    const amountTemplate = (rowData) => {
        return rowData.amount ?
            `KSH ${Number(rowData.amount).toLocaleString()}` :
            'KSH 0';
    }


    const dateTemplate = (date) => {
        return date ? new Date(date).toLocaleDateString() : '-'
    }

    const serviceTemplate = (rowData) => (
        <span className="text-gray-800">
            {rowData.serviceName || 'N/A'}
        </span>
    )

    const header = (
        <div className="flex justify-between items-center mb-4">
            <div className="relative flex items-center">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        value={searchKeyword}
                        onChange={(e) => setSearchKeyword(e.target.value)}
                        placeholder="Search invoices..."
                        className="p-inputtext-sm"
                        style={{ width: '300px' }}
                    />
                </span>
            </div>
        </div>
    )

    const filteredInvoices = invoices.filter((invoice) =>
        Object.values(invoice)
            .join(' ')
            .toLowerCase()
            .includes(searchKeyword.toLowerCase())
    )

    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/resident" className="text-gray-700 hover:text-blue-600">
                                        Dashboard
                                    </Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to="/resident/vas-invoices" className="text-gray-700 hover:text-blue-600">
                                        VAS Management
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    VAS Invoices
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="text-xl font-semibold text-gray-800">VAS Invoices</h5>
                        </div>
                        <div className="card-body">
                            <div className="card">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        {header}
                                        <DataTable
                                            value={filteredInvoices}
                                            emptyMessage="No VAS invoices found."
                                            sortMode="multiple"
                                            paginator
                                            rows={10}
                                            stripedRows
                                            className="p-datatable-sm"
                                            responsiveLayout="scroll"
                                            loading={loading}
                                            rowHover
                                        >
                                            <Column
                                                field="invoiceNumber"
                                                header="Invoice Number"
                                                sortable
                                            />
                                            <Column
                                                field="serviceName"
                                                header="Service"
                                                body={serviceTemplate}
                                                sortable
                                            />
                                            <Column
                                                field="createdAt"
                                                header="Issue Date"
                                                body={(rowData) => dateTemplate(rowData.createdAt)}
                                                sortable
                                            />
                                            <Column
                                                field="dueDate"
                                                header="Due Date"
                                                body={(rowData) => dateTemplate(rowData.dueDate)}
                                                sortable
                                            />
                                            <Column
                                                field="amount"
                                                header="Total Amount"
                                                body={amountTemplate}
                                                sortable
                                                className="text-right"
                                            />
                                            <Column
                                                field="status"
                                                header="Status"
                                                body={statusTemplate}
                                                sortable
                                            />
                                            <Column
                                                body={actionTemplate}
                                                header="Actions"
                                                style={{ width: '8rem' }}
                                            />
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Invoices;
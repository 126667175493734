import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getItem } from "../../../utils/localStorage";
import { makeRequest2 } from "../../../utils/makeRequest";
import { toastify } from "../../../utils/toast";
import { residentUnitsURL, getApprovalTicketsURL } from "../../../utils/urls";
import Layout from "../component/layout";

function Dashboard() {
  const [units, setUnits] = useState([]);
  const [customerType, setCustomerType] = useState("");
  const [occupancyStatus, setOccupancyStatus] = useState("Unoccupied");
  const [approvalCount, setApprovalCount] = useState(0);

  const fetchResidentUnits = async () => {
    try {
      const CustomerId = await getItem("selectedCustomerId");
      const FacilityId = await getItem("selectedFacilityId");

      const response = await makeRequest2(
        `${residentUnitsURL}/${CustomerId}/${FacilityId}`,
        "GET",
        {}
      );
      if (response.success) {
        setUnits(response.data);
        // Determine the customer type from the first unit
        if (response.data.length > 0) {
          setCustomerType(response.data[0].customerType); // Home Owner or Tenant
          const firstUnit = response.data[0];
          setOccupancyStatus(firstUnit.isOccupied ? "Occupied" : "Unoccupied");
        }
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      toastify(err.message, "error");
    }
  };

  const fetchApprovalCount = async () => {
    try {
      const customerId = await getItem("selectedCustomerId");
      const facilityId = await getItem("selectedFacilityId");

      const response = await makeRequest2(
        `${getApprovalTicketsURL}/${facilityId}/${customerId}`,
        "GET",
        {}
      );

      if (response.success) {
        // Filter for approvals with status "on hold"
        const filteredTickets = response.data.filter(
          (ticket) => ticket.payer === "tenant" && ticket.status === "on hold"
        );
        setApprovalCount(filteredTickets.length);
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      toastify(err.message, "error");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      fetchResidentUnits();
      fetchApprovalCount();
    }, 1000);
  }, []);

  return (
    <Layout>
      <div className="row">
        {/* My Units Section */}
        <div className="col-12"></div>
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 ms-3">
                  <h6 className="mb-0">My Units</h6>
                </div>
              </div>
              <div className="row">
                {units.map((unit, index) => (
                  <div
                    key={index + 1}
                    className="col-md-3"
                    style={{ borderColor: "green", borderWidth: 2 }}
                  >
                    <div className="bg-body p-3 mt-3 rounded">
                      <div className="mt-3 row align-items-center">
                        <div className="col-12">
                          <span>
                            Unit Name:{" "}
                            <strong className="mb-1">{unit.name}</strong>
                          </span>
                          <br />
                          <span>
                            Unit Type:
                            <strong className="mb-1"> {unit.unitType}</strong>
                          </span>
                          <br />
                          {unit.customerType === "Home Owner" && (
                            <span className="badge rounded-pill text-bg-info mt-2">
                              <strong className="mb-1"> Home Owner </strong>
                            </span>
                          )}
                          {unit.customerType === "Tenant" && (
                            <span className="badge rounded-pill text-bg-warning mt-2">
                              <strong className="mb-1"> Tenant </strong>
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Sections for Home Owners Only */}
        {customerType === "Home Owner" && (
          <>
            {/* Invoices Section */}
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <h4 className="mb-0 text-primary">Invoices</h4>
                    <button className="btn btn-sm btn-primary">View All</button>
                  </div>
                  <div className="row g-4">
                    {/* Overdue */}
                    <div className="col-sm-6">
                      <div className="bg-gradient-danger p-4 rounded shadow-sm">
                        <div className="d-flex align-items-center mb-2">
                          <div className="flex-shrink-0">
                            <span className="p-1 d-block bg-danger rounded-circle">
                              <i className="bi bi-exclamation-triangle-fill"></i>
                            </span>
                          </div>
                          <div className="flex-grow-1 ms-3">
                            <p className="mb-1">Overdue</p>
                          </div>
                        </div>
                        <h5 className="mb-0">KES 120,000,000</h5>
                      </div>
                    </div>
                    {/* Open */}
                    <div className="col-sm-6">
                      <div className="bg-gradient-warning p-4 rounded shadow-sm">
                        <div className="d-flex align-items-center mb-2">
                          <div className="flex-shrink-0">
                            <span className="p-1 d-block bg-warning rounded-circle">
                              <i className="bi bi-hourglass-split"></i>
                            </span>
                          </div>
                          <div className="flex-grow-1 ms-3">
                            <p className="mb-1">Open</p>
                          </div>
                        </div>
                        <h5 className="mb-0">KES 23,876</h5>
                      </div>
                    </div>
                    {/* Paid */}
                    <div className="col-sm-6">
                      <div className="bg-gradient-success p-4 rounded shadow-sm">
                        <div className="d-flex align-items-center mb-2">
                          <div className="flex-shrink-0">
                            <span className="p-1 d-block bg-success rounded-circle">
                              <i className="bi bi-check-circle-fill"></i>
                            </span>
                          </div>
                          <div className="flex-grow-1 ms-3">
                            <p className="mb-1">Paid</p>
                          </div>
                        </div>
                        <h5 className="mb-0">KES 200,000,000</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Revenue Reports Section */}
            <div className="col-md-12 mt-4">
              <div className="card">
                <div className="card-body">
                  <h4 className="text-success">Key Metrics</h4>
                  <div className="row g-4 mt-3">
                    <div className="col-md-4">
                      <div className="bg-gradient-info p-4 rounded shadow-sm text-center">
                        <h6>Monthly Revenue</h6>
                        <p className="fs-5">KES 5,000,000</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="bg-gradient-primary p-4 rounded shadow-sm text-center">
                        <h6>Annual Revenue</h6>
                        <p className="fs-5">KES 60,000,000</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="bg-gradient-secondary p-4 rounded shadow-sm text-center">
                        <h6>Occupancy Status</h6>
                        <p className="fs-5">{occupancyStatus}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Task Approvals Section */}
            <div className="col-md-12 mt-4">
              <div className="card">
                <div className="card-body">
                  <h4 className="text-warning">Task Approvals</h4>
                  <div className="row g-4 mt-3">
                    <div className="col-md-4">
                      <div className="bg-light p-4 rounded shadow-sm">
                        <h6 className="mb-3">
                          Maintenance Approvals{" "}
                          <span
                            style={{
                              display: "inline-block",
                              width: "24px",
                              height: "24px",
                              borderRadius: "50%",
                              backgroundColor: "#ffcc00",
                              color: "#000",
                              textAlign: "center",
                              lineHeight: "24px",
                              fontWeight: "bold",
                              fontSize: "14px",
                            }}
                          >
                            {approvalCount}
                          </span>
                        </h6>
                        <Link to="/resident/approvals/my_approvals" className="btn btn-warning w-100">
                          View Approvals
                        </Link>
                      </div>

                    </div>
                    <div className="col-md-4">
                      <div className="bg-light p-4 rounded shadow-sm">
                        <h6 className="mb-3">Expense Approvals</h6>
                        <button className="btn btn-secondary w-100">View Approvals</button>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="bg-light p-4 rounded shadow-sm">
                        <h6 className="mb-3">Lease Approvals</h6>
                        <button className="btn btn-success w-100">View Approvals</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Notifications Section */}
            <div className="col-md-12 mt-4">
              <div className="card">
                <div className="card-body text-start">
                  <h4 className="text-danger">Notifications and Alerts</h4>
                  {approvalCount > 0 ? (
                    <p className="text-success mt-3">
                      You have <strong>{approvalCount}</strong> pending approvals.{" "}
                      <Link to="/resident/approvals/my_approvals">View Approvals</Link>
                    </p>
                  ) : (
                    <p className="text-muted mt-3">No new notifications.</p>
                  )}
                </div>
              </div>
            </div>


            {/* Communication Tools Section */}
            {/* <div className="col-md-12 mt-4">
              <div className="card">
                <div className="card-body">
                  <h4 className="text-info">Communication Tools</h4>
                  <div className="row g-4 mt-3">
                    <div className="col-md-6">
                      <button className="btn btn-outline-primary w-100 py-3">Messaging System</button>
                    </div>
                    <div className="col-md-6">
                      <button className="btn btn-outline-secondary w-100 py-3">Document Sharing</button>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

          </>
        )}
      </div>
    </Layout>
  );
}

export default Dashboard;

import React, { useState, useEffect } from 'react'
import Layout from '../component/layout'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Link } from 'react-router-dom'
import { toastify } from '../../../utils/toast'
import { makeRequest2 } from '../../../utils/makeRequest'
import { getCustomerLeasesURL } from '../../../utils/urls'
import { getItem } from '../../../utils/localStorage'

const Leases = () => {
    const [leases, setLeases] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedLease, setSelectedLease] = useState(null);
    const [displayDialog, setDisplayDialog] = useState(false);
    const [originalLeases, setOriginalLeases] = useState([]);

    const fetchLeases = async () => {
        try {
            setLoading(true);
            const facilityId = await getItem('selectedFacilityId');

            const residentUserStr = await getItem('RESIDENTUSER');
            if (!residentUserStr) {
                toastify('User session not found', 'error');
                return;
            }

            const residentUser = JSON.parse(residentUserStr);

            // Get the customerId from the customerData array for the current facility
            const customerData = residentUser.user.customerData?.find(
                data => data.facilityId === facilityId
            );

            const customerId = customerData?.customerId;

            if (!facilityId || !customerId) {
                console.log('Debug IDs:', {
                    facilityId,
                    customerId,
                    customerData: residentUser.user.customerData
                });
                toastify('Required information missing', 'error');
                return;
            }

            const response = await makeRequest2(
                `${getCustomerLeasesURL}/${facilityId}/${customerId}`,
                'GET'
            );

            if (response.success) {
                const leaseData = Array.isArray(response.data) ? response.data :
                    Array.isArray(response.data?.data) ? response.data.data :
                        [];

                // Debug the entire response and first lease
                console.log('Full API Response:', response);
                if (leaseData.length > 0) {
                    console.log('First lease data:', JSON.stringify(leaseData[0], null, 2));
                }

                setLeases(leaseData);
                setOriginalLeases(leaseData);
            } else {
                setLeases([]);
                setOriginalLeases([]);
                toastify(response.message || 'Failed to fetch lease agreements', 'error');
            }
        } catch (err) {
            console.error('Fetch leases error:', err);
            setLeases([]);
            setOriginalLeases([]);
            toastify(err.message || 'Error fetching leases', 'error');
        } finally {
            setLoading(false);
        }
    };
    const statusTemplate = (rowData) => {
        const statusStyles = {
            Active: { color: '#28a745', backgroundColor: '#e9fbe9' },
            Pending: { color: '#ffc107', backgroundColor: '#fff8e1' },
            Expired: { color: '#dc3545', backgroundColor: '#fde9e9' },
            Terminated: { color: '#6c757d', backgroundColor: '#f1f1f1' }
        };

        const style = statusStyles[rowData.status] || {
            color: '#6c757d',
            backgroundColor: '#f1f1f1',
        };

        return (
            <span
                style={{
                    ...style,
                    padding: '0.5em 1em',
                    borderRadius: '12px',
                    fontWeight: 'bold',
                    fontSize: '0.9rem',
                    display: 'inline-block',
                }}
            >
                {rowData.status}
            </span>
        );
    };

    const actionTemplate = (rowData) => {
        return (
            <Button
                icon="ti ti-eye"
                className="p-button-rounded p-button-info p-button-outlined"
                onClick={() => {
                    setSelectedLease(rowData);
                    setDisplayDialog(true);
                }}
            />
        );
    };

    const dialogContent = () => {
        if (!selectedLease) return null;

        return (
            <div className="p-fluid">
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <h6>Unit Information</h6>
                        <p><strong>Unit Number:</strong> {selectedLease.unitInfo?.unitName}</p>
                    </div>
                    <div className="col-md-6 mb-3">
                        <h6>Landlord Information</h6>
                        <p><strong>Name:</strong> {
                            selectedLease.landlord?.firstName && selectedLease.landlord?.lastName
                                ? `${selectedLease.landlord.firstName} ${selectedLease.landlord.lastName}`
                                : selectedLease.landlordInfo?.firstName && selectedLease.landlordInfo?.lastName
                                    ? `${selectedLease.landlordInfo.firstName} ${selectedLease.landlordInfo.lastName}`
                                    : selectedLease.landlordInfo?.fullName || 'N/A'
                        }</p>
                        {selectedLease.landlordInfo?.phoneNumber && (
                            <p><strong>Phone:</strong> {selectedLease.landlordInfo.phoneNumber}</p>
                        )}
                        {selectedLease.landlordInfo?.email && (
                            <p><strong>Email:</strong> {selectedLease.landlordInfo.email}</p>
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <h6>Lease Terms</h6>
                        <p><strong>Start Date:</strong> {new Date(selectedLease.leaseTerms.startDate).toLocaleDateString()}</p>
                        <p><strong>End Date:</strong> {new Date(selectedLease.leaseTerms.endDate).toLocaleDateString()}</p>
                        <p><strong>Duration:</strong> {selectedLease.leaseTerms.duration} months</p>
                        <p><strong>Auto Renewal:</strong> {selectedLease.leaseTerms.autoRenewal ? 'Yes' : 'No'}</p>
                    </div>
                    <div className="col-md-6 mb-3">
                        <h6>Financial Terms</h6>
                        <p><strong>Monthly Rent:</strong> {selectedLease.financialTerms.monthlyRent.toLocaleString()} KES</p>
                        <p><strong>Payment Due Date:</strong> {selectedLease.financialTerms.paymentDueDate}</p>
                        <p><strong>Payment Method:</strong> {selectedLease.financialTerms.paymentMethod}</p>
                        <p><strong>Security Deposit:</strong> {selectedLease.financialTerms.securityDeposit.toLocaleString()} KES</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <h6>Billing Information</h6>
                        <p><strong>Frequency:</strong> {selectedLease.billingCycle.frequency}</p>
                        <p><strong>Next Invoice Date:</strong> {new Date(selectedLease.billingCycle.nextInvoiceDate).toLocaleDateString()}</p>
                        <p><strong>Auto Send:</strong> {selectedLease.billingCycle.autoSend ? 'Yes' : 'No'}</p>
                    </div>
                    <div className="col-md-6 mb-3">
                        <h6>Status Information</h6>
                        <p><strong>Status:</strong> {statusTemplate(selectedLease)}</p>
                        <p><strong>Created At:</strong> {new Date(selectedLease.createdAt).toLocaleString()}</p>
                        <p><strong>Updated At:</strong> {new Date(selectedLease.updatedAt).toLocaleString()}</p>
                    </div>
                </div>
            </div>
        );
    };

    const handleSearch = (searchTerm) => {
        if (!searchTerm.trim()) {
            setLeases(originalLeases);
            return;
        }

        const term = searchTerm.toLowerCase();
        const filtered = originalLeases.filter(lease =>
            lease.unitInfo?.unitName?.toLowerCase().includes(term) ||
            lease.landlordInfo?.fullName?.toLowerCase().includes(term) ||
            lease.status?.toLowerCase().includes(term)
        );
        setLeases(filtered);
    };

    useEffect(() => {
        const checkStorage = async () => {
            const residentUserStr = await getItem('RESIDENTUSER');
            const facilityId = await getItem('selectedFacilityId');

            const residentUser = residentUserStr ? JSON.parse(residentUserStr) : null;
            const customerData = residentUser?.user?.customerData?.find(
                data => data.facilityId === facilityId
            );

            console.log('Storage check:', {
                facilityId,
                customerData,
                customerId: customerData?.customerId
            });
        };

        checkStorage();
        fetchLeases();
    }, []);

    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/resident/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Lease Agreements</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h5>Lease Agreements</h5>
                        </div>
                        <div className="card-body">
                            <div class="card">
                                <div class="card-body">
                                    <div className="row">
                                        <div className="col-md-3 mb-3">
                                            <input
                                                className="form-control"
                                                placeholder="Search here"
                                                onChange={(e) => handleSearch(e.target.value)}
                                            />
                                        </div>
                                        <div className="table-responsive">
                                            <DataTable
                                                value={leases || []} // Ensure we always have an array
                                                loading={loading}
                                                emptyMessage="No lease agreements found."
                                                sortMode="multiple"
                                                paginator
                                                rows={5}
                                                stripedRows
                                                tableStyle={{ minWidth: '50rem' }}
                                            >
                                                <Column field="unitInfo.unitName" header="Unit Number"></Column>
                                                <Column
                                                    field="landlordInfo.fullName"
                                                    header="Landlord"
                                                    body={(rowData) => {
                                                        // Debug log
                                                        console.log('Full row data:', rowData);

                                                        // Try different ways to get landlord info
                                                        const landlordName = rowData.landlord?.firstName && rowData.landlord?.lastName
                                                            ? `${rowData.landlord.firstName} ${rowData.landlord.lastName}`
                                                            : rowData.landlordInfo?.firstName && rowData.landlordInfo?.lastName
                                                                ? `${rowData.landlordInfo.firstName} ${rowData.landlordInfo.lastName}`
                                                                : rowData.landlordInfo?.fullName || 'N/A';

                                                        return (
                                                            <span title={landlordName}>
                                                                {landlordName}
                                                            </span>
                                                        );
                                                    }}
                                                />
                                                <Column
                                                    field="leaseTerms.startDate"
                                                    header="Start Date"
                                                    body={(rowData) => rowData.leaseTerms?.startDate ?
                                                        new Date(rowData.leaseTerms.startDate).toLocaleDateString() : 'N/A'}
                                                ></Column>
                                                <Column
                                                    field="leaseTerms.endDate"
                                                    header="End Date"
                                                    body={(rowData) => rowData.leaseTerms?.endDate ?
                                                        new Date(rowData.leaseTerms.endDate).toLocaleDateString() : 'N/A'}
                                                ></Column>
                                                <Column
                                                    field="financialTerms.monthlyRent"
                                                    header="Monthly Rent"
                                                    body={(rowData) => rowData.financialTerms?.monthlyRent ?
                                                        `${rowData.financialTerms.monthlyRent.toLocaleString()} KES` : 'N/A'}
                                                ></Column>
                                                <Column field="billingCycle.frequency" header="Billing Cycle"></Column>
                                                <Column field="status" header="Status" body={statusTemplate}></Column>
                                                <Column body={actionTemplate} header="Actions" style={{ width: '8rem' }}></Column>
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog
                header="Lease Agreement Details"
                visible={displayDialog}
                style={{ width: '80vw' }}
                onHide={() => setDisplayDialog(false)}
                maximizable
            >
                {dialogContent()}
            </Dialog>
        </Layout >
    );
}

export default Leases;
import React from 'react';

const ServiceRequestForm = ({
    selectedService,
    services = [],
    serviceData = [],
    date,
    time,
    units = [],
    selectedUnit,
    onServiceChange,
    onDateChange,
    onTimeChange,
    onUnitChange,
    onSubmit
}) => {
    const selectedServiceLabel = selectedService &&
        services.find((service) => service.value === selectedService)?.label;

    const isSpecialService = (label) => {
        const specialServices = ['commission', 'unit management'];
        return label?.toLowerCase() && specialServices.includes(label.toLowerCase());
    };

    const renderDateTimeFields = () => (
        <>
            <div className="form-group mb-2">
                <label className="py-2" htmlFor="date">Date</label>
                <input
                    type="date"
                    id="date"
                    value={date}
                    onChange={(e) => onDateChange(e.target.value)}
                    className="form-control"
                    min={new Date().toISOString().split('T')[0]}
                    required
                />
            </div>
            <div className="form-group mb-2">
                <label className="py-2" htmlFor="time">Time</label>
                <input
                    type="time"
                    id="time"
                    value={time}
                    onChange={(e) => onTimeChange(e.target.value)}
                    className="form-control"
                    required
                />
            </div>
        </>
    );

    const renderUnitSelect = () => {
        return (
            <div className="form-group mb-2">
                <label className="py-2" htmlFor="unit">Unit</label>
                <select
                    id="unit"
                    value={selectedUnit || ''}
                    onChange={(e) => onUnitChange(e.target.value)}
                    className="form-control"
                    required
                >
                    <option value="">Select Unit</option>
                    {units.map(unit => (
                        <option key={unit._id} value={unit.name || ''}>
                            {unit.name || ''}
                        </option>
                    ))}
                </select>
            </div>
        );
    };

    const renderServiceSelect = () => {
        const isPendingRequest = (serviceId) => {
            const pendingStatuses = ['pending', 'awaiting'];
            return serviceData.some(request =>
                request.serviceId === serviceId &&
                pendingStatuses.includes(request.status.toLowerCase())
            );
        };

        return (
            <div className="form-group mb-2">
                <label className="py-2" htmlFor="type">Service</label>
                <select
                    id="type"
                    value={selectedService || ''}
                    onChange={(e) => onServiceChange(e.target.value)}
                    className="form-control"
                    required
                >
                    <option value="">Select Service</option>
                    {services.map(service => (
                        <option
                            key={service.value}
                            value={service.value}
                            disabled={isPendingRequest(service.value)}
                        >
                            {service.label}
                            {isPendingRequest(service.value) ? ' (Pending Request Exists)' : ''}
                        </option>
                    ))}
                </select>
            </div>
        );
    };

    return (
        <div className="col-lg-6 mb-4">
            <h6 className="mb-4">Request Service</h6>
            <form onSubmit={(e) => {
                e.preventDefault();
                onSubmit(e);
            }}>
                {renderUnitSelect()}
                {renderServiceSelect()}
                {selectedService && !isSpecialService(selectedServiceLabel) && renderDateTimeFields()}

                <div className="d-flex justify-content-end mt-4">
                    <button
                        type="submit"
                        className="btn btn-primary"
                        style={{ marginLeft: 'auto' }}
                    >
                        Submit Request
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ServiceRequestForm;
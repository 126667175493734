import { Link } from "react-router-dom";
function CheckEmail() {
  return (
    <>
      <div className="auth-main">
        <div className="auth-wrapper v2">
          <div className="auth-form">
            <div className="card my-5">
              <div className="card-body">
                <Link to={"/"}>
                  <img
                    src="/assets/images/PayServeLogoFinal.png"
                    alt="img"
                    style={{ width: 300 }}
                  />
                </Link>
                <div className="mb-4">
                  <h3 className="mb-2">
                    <b>Hi, Check Your Mail</b>
                  </h3>
                  <p className="text-muted">
                    We have sent a password recover instructions to your email.
                  </p>
                  <p>
                    <Link to={"/"} className="link-primary">
                      Back to Login
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default CheckEmail;

import React, { useState, useEffect } from "react";
import Layout from "../component/layout";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link } from "react-router-dom";
import { toastify } from "../../../utils/toast";
import { makeRequest2 } from "../../../utils/makeRequest";
import { getCustomerInvoicesURL } from "../../../utils/urls";
import { getItem } from "../../../utils/localStorage";
import InvoiceManagement from "./invoice_management/InvoiceManagement";

const Invoices = () => {
  const selectedFacilityId = getItem("selectedFacilityId");
  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/facility/dashboard"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/facility/Invoices"}>Invoices </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Levy Invoices</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="tab-content">
                        <div className="row">
                          <div className="col-lg-12 col-xxl-12">
                            <InvoiceManagement
                              facilityId={selectedFacilityId}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Invoices;

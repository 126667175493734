import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { makeRequest2 } from "../../../../utils/makeRequest";
import Layout from "../../component/layout";
import {
  getInvoice,
  getCompanyInformationURL,
} from "../../../../utils/urls";
import { getItem } from "../../../../utils/localStorage";
import { toastify } from "../../../../utils/toast";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import PaymentModal from "../../../../utils/paymentModal";

// CSS Module import
import styles from "./invoice.module.css";

const ViewInvoice = () => {
  // State management
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [paymentSettings, setPaymentSettings] = useState(null);
  const [showReceiptsDialog, setShowReceiptsDialog] = useState(false);
  const [companyDetails, setCompanyDetails] = useState(null);
  const [facilityLogo, setFacilityLogo] = useState("");
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  // Hooks
  const { invoiceId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useRef(null);
  const isMounted = useRef(true);

  // Fetch facility logo
  const getFacilityLogo = async () => {
    try {
      const facilityFromState = location.state?.facility;

      if (facilityFromState?.logo) {
        const fullLogoUrl = `${process.env.REACT_APP_BASE_URL}/${facilityFromState.logo}`;
        localStorage.setItem("selectedFacilityLogo", fullLogoUrl);
        setFacilityLogo(fullLogoUrl);
      } else {
        const storedLogo = localStorage.getItem("selectedFacilityLogo");
        if (storedLogo) {
          setFacilityLogo(storedLogo);
        } else {
          setFacilityLogo(""); // Set empty string if no logo exists
        }
      }
    } catch (error) {
      console.error("Error fetching facility logo:", error);
      setFacilityLogo(""); // Set empty string on error
    }
  };

  // Fetch company details
  const fetchCompanyDetails = useCallback(async (currentFacilityId) => {
    if (!currentFacilityId) return;

    try {
      const response = await makeRequest2(
        `${getCompanyInformationURL}/${currentFacilityId}`,
        "GET"
      );

      if (response.success && isMounted.current) {
        setCompanyDetails(response.data);
      } else {
        toastify("Failed to fetch company details", 'error');
      }
    } catch (error) {
      toastify(error.message, 'error');
    }
  }, []);

  // Fetch invoice data
  const fetchInvoiceData = useCallback(async (currentFacilityId) => {
    if (!currentFacilityId || !invoiceId) return;

    try {
      const response = await makeRequest2(
        `${getInvoice}/${currentFacilityId}/${invoiceId}`,
        "GET"
      );

      if (response.success && response.data.success) {
        const invoiceData = response.data.data;
        if (!invoiceData) {
          toastify("Invalid invoice data received", 'error');
          return;
        }

        if (isMounted.current) {
          setInvoice(invoiceData);
        }
      } else {
        toastify(response.data?.message || "Failed to fetch invoice details", 'error');
      }
    } catch (err) {
      toastify(err.message, 'error');
    }
  }, [invoiceId]);

  useEffect(() => {
    const initializeData = async () => {
      try {
        const currentFacilityId = await getItem('selectedFacilityId');
        if (!currentFacilityId) {
          toastify("Facility ID not found", 'error');
          return;
        }

        setLoading(true);
        try {
          await Promise.all([
            fetchInvoiceData(currentFacilityId),
            fetchCompanyDetails(currentFacilityId),
            getFacilityLogo()
          ]);
        } catch (error) {
          toastify("Error fetching data", 'error');
        } finally {
          if (isMounted.current) {
            setLoading(false);
          }
        }
      } catch (err) {
        toastify("Error retrieving data", 'error');
        setLoading(false);
      }
    };

    isMounted.current = true;
    initializeData();

    return () => {
      isMounted.current = false;
    };
  }, [fetchInvoiceData, fetchCompanyDetails]);

  // Handle PDF download
  const handleDownloadPDF = async () => {
    const element = document.getElementById("printable-invoice");
    const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
    const filename = `Invoice_${invoice?.invoiceNumber}_${timestamp}.pdf`;

    try {
      setLoading(true);
      toast.current.show({
        severity: "info",
        summary: "Processing",
        detail: "Generating PDF...",
      });

      const canvas = await html2canvas(element, {
        scale: 2,
        useCORS: true,
        logging: false,
        backgroundColor: "#ffffff",
      });

      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      const imgWidth = 210;
      const pageHeight = 297;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let position = 0;

      // Add first page
      pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight, "", "FAST");

      // Add additional pages if needed
      let heightLeft = imgHeight - pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight, "", "FAST");
        heightLeft -= pageHeight;
      }

      pdf.save(filename);

      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "PDF downloaded successfully!",
      });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to generate PDF. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  // Handle payment success
  const handlePaymentSuccess = async () => {
    try {
      const currentFacilityId = await getItem('selectedFacilityId');
      await fetchInvoiceData(currentFacilityId);
      setShowPaymentModal(false);
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Payment processed successfully!'
      });
    } catch (error) {
      console.error('Error refreshing invoice data:', error);
    }
  };

  // Handle payment error
  const handlePaymentError = (error) => {
    console.error('Payment Error Details:', error);
    toast.current.show({
      severity: 'error',
      summary: 'Error',
      detail: error.response?.data?.message || 'Payment processing failed. Please try again.'
    });
    setShowPaymentModal(false);
  };

  // Helper functions
  const formatDate = (date) => {
    if (!date) return '';
    return new Date(date).toLocaleDateString();
  };

  const formatAmount = (amount) => {
    if (amount === undefined || amount === null) return '0.00';
    const currencyCode = invoice?.currency?.code || 'KES';
    return `${currencyCode} ${parseFloat(amount).toFixed(2)}`;
  };

  // Balance calculation methods
  const hasBalanceBroughtForward = () => {
    return invoice?.balanceBroughtForward !== 0 &&
      invoice?.balanceBroughtForward !== null &&
      invoice?.balanceBroughtForward !== undefined;
  };

  // Determine if balanceBroughtForward is a credit or debit
  const isCredit = invoice?.balanceBroughtForward < 0;

  // Calculate total balance (invoice amount + balance brought forward)
  const calculateTotalBalance = () => {
    if (!invoice) return 0;

    const totalAmount = invoice.totalAmount || 0;
    const balanceBroughtForward = invoice.balanceBroughtForward || 0;

    // Total balance is invoice amount + balance brought forward
    return totalAmount + balanceBroughtForward;
  };

  // Calculate total due (total balance - amount paid)
  const calculateTotalDue = () => {
    if (!invoice) return 0;

    const totalBalance = calculateTotalBalance();
    const amountPaid = invoice.amountPaid || 0;

    // Total due is total balance minus payments
    return totalBalance - amountPaid;
  };

  // Check if final amount is credit or debit
  const isFinalCredit = calculateTotalDue() < 0;

  // Format the balance brought forward
  const formatBalanceBroughtForward = () => {
    if (!invoice || !invoice.balanceBroughtForward || invoice.balanceBroughtForward === 0) {
      return formatAmount(0);
    }

    // Always show the absolute value, let the display logic handle presentation
    return formatAmount(Math.abs(invoice.balanceBroughtForward));
  };

  // Check if payment is available
  const isPaymentAvailable = () => {
    return calculateTotalDue() > 0 && (invoice?.currency?.code === 'KES');
  };

  // Get payment method details
  const getPaymentMethodInfo = () => {
    // Check if invoice has payment details with format "Module - ShortCode"
    if (invoice?.paymentDetails?.paymentMethod && invoice.paymentDetails.paymentMethod.includes(" - ")) {
      const [module, shortCode] = invoice.paymentDetails.paymentMethod.split(" - ");
      return {
        module,
        shortCode
      };
    }

    return {
      module: paymentSettings?.bankName || 'Mpesa',
      shortCode: paymentSettings?.accountNumber || '---'
    };
  };

  // View receipt function for the receipts dialog
  const viewReceipt = (paymentData) => {
    navigate(`/resident/statements/receipts/invoice_receipt/${paymentData._id}`, {
      state: {
        receipt: paymentData,
        invoice: invoice,
        companyDetails: companyDetails
      }
    });
  };

  // Action template for the data table in the receipts dialog
  const actionBodyTemplate = (rowData) => {
    const isMpesa = rowData.type && (rowData.type.toUpperCase() === 'MPESA' || rowData.type.toLowerCase() === 'payment');
    return (
      <Button
        icon="fas fa-eye"
        className="p-button-sm p-button-text"
        onClick={() => viewReceipt(rowData)}
        //disabled={!isMpesa}
        tooltip={isMpesa ? "View Receipt" : "Receipt not available"}
        tooltipOptions={{ position: 'left' }}
      />
    );
  };

  // Get payment info
  const paymentInfo = getPaymentMethodInfo();

  // Render loading state
  if (loading && !invoice) {
    return (
      <Layout>
        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <span className="ms-2">Loading invoice details...</span>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/resident/levy_invoices">Levy Invoices</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="#">View Invoice</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.pageContainer}>
        <Toast ref={toast} />

        {/* Action Buttons */}
        <div className={styles.actionCard}>
          <div className={styles.actionCardLeft}>
            <Link to="#" onClick={() => navigate(-1)} className={styles.backLink}>
              <i className="ti ti-arrow-narrow-left"></i>
              <span>Back</span>
            </Link>
          </div>
          <div className={styles.actionCardRight}>
            <Button
              label="Receipts"
              onClick={() => setShowReceiptsDialog(true)}
              className="mr-2 btn btn-primary"
              style={{ marginRight: '10px' }}
            />
            <Button
              label="Download Invoice"
              onClick={handleDownloadPDF}
              className="mr-2 btn btn-primary"
              style={{ marginRight: '10px' }}
              disabled={loading}
            />
            {isPaymentAvailable() && (
              <Button
                label="Pay Now"
                onClick={() => setShowPaymentModal(true)}
                className="mr-2 btn btn-success"
                disabled={loading}
              />
            )}
          </div>
        </div>

        {/* Payment Modal */}
        <PaymentModal
          visible={showPaymentModal}
          onHide={() => setShowPaymentModal(false)}
          accountNumber={invoice?.accountNumber}
          facilityId={invoice?.facility?.id}
          customerId={invoice?.client?.clientId}
          amount={calculateTotalDue()}
          onSuccess={handlePaymentSuccess}
          onError={handlePaymentError}
        />

        {/* Receipts Dialog */}
        <Dialog
          header="Payment Receipts"
          visible={showReceiptsDialog}
          style={{ width: '50vw' }}
          onHide={() => setShowReceiptsDialog(false)}
          resizable={false}
        >
          <DataTable
            value={invoice?.reconciliationHistory || []}
            responsiveLayout="scroll"
            emptyMessage="No payment records found"
            className="p-datatable-sm"
            paginator
            rows={5}
            rowsPerPageOptions={[5, 10, 20]}
          >
            <Column
              field="date"
              header="Date"
              body={(rowData) => formatDate(rowData.date)}
              sortable
            />
            <Column
              field="amount"
              header="Amount"
              body={(rowData) => formatAmount(rowData.amount)}
              sortable
            />
            <Column
              field="type"
              header="Type"
              sortable
            />
            <Column
              field="paymentReference"
              header="Reference"
              sortable
            />
            <Column
              field="paymentCompletion"
              header="Status"
              sortable
              body={(rowData) => (
                <span className={`badge ${rowData.paymentCompletion === 'Completed' ? 'bg-success' :
                  rowData.paymentCompletion === 'Partial' ? 'bg-warning' :
                    rowData.paymentCompletion === 'Overpaid' ? 'bg-info' : 'bg-secondary'
                  }`}>
                  {rowData.paymentCompletion}
                </span>
              )}
            />
            <Column
              body={actionBodyTemplate}
              exportable={false}
              style={{ width: '4rem' }}
            />
          </DataTable>
        </Dialog>

        {/* Invoice Content */}
        <div className={styles.invoiceContainer}>
          <div id="printable-invoice" className={styles.invoiceContent}>
            <div
              className={styles.statusBanner}
              data-status={invoice?.status?.toLowerCase()}
            >
              <span>{invoice?.status}</span>
            </div>

            <div className={styles.invoiceHeader}>
              <div className={styles.invoiceHeaderContent}>
                {/* Invoice title now above the logo */}
                <div className={styles.headerLeft}>
                  <h1 className={styles.invoiceTitleInHeader} style={{ marginTop: "-20px", marginLeft: "60%", color: "white", fontSize: "28px", fontWeight: "600" }}>INVOICE</h1>
                  {facilityLogo && (
                    <div className={styles.logoContainer} style={{ marginTop: "0", marginLeft: "60%" }}>
                      <img
                        src={facilityLogo}
                        alt="Company Logo"
                        className={styles.companyLogo}
                      />
                    </div>
                  )}
                </div>
                <div className={styles.headerDetails}>
                  <div className={styles.detailRow}>
                    <span className={styles.label}>INVOICE NO.</span>
                    <span className={styles.value}>{invoice?.invoiceNumber}</span>
                  </div>
                  <div className={styles.detailRow}>
                    <span className={styles.label}>ACCOUNT NO.</span>
                    <span className={styles.value}>{invoice?.accountNumber}</span>
                  </div>
                  <div className={styles.detailRow}>
                    <span className={styles.label}>INVOICE DATE</span>
                    <span className={styles.value}>
                      {formatDate(invoice?.issueDate)}
                    </span>
                  </div>
                  <div className={styles.detailRow}>
                    <span className={styles.label}>DUE DATE</span>
                    <span className={styles.value}>
                      {formatDate(invoice?.dueDate)}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.invoiceBody}>
              <div className={styles.addressSection}>
                <div className={styles.addressLayout}>
                  <div className={styles.fromSection}>
                    <h2 className={styles.sectionTitle}>FROM</h2>
                    <div className={styles.addressContent}>
                      <div>{companyDetails?.name || 'Company Name'}</div>
                      <div>{companyDetails?.address || 'Company Address'}</div>
                      <div>{companyDetails?.country || 'Country'}</div>
                    </div>
                  </div>
                  <div className={styles.billToSection}>
                    <h2 className={styles.sectionTitle}>BILL TO</h2>
                    <div className={styles.addressContent}>
                      <div>{invoice?.customerInfo?.fullName ||
                        `${invoice?.client?.firstName || ''} ${invoice?.client?.lastName || ''}`}</div>
                      <div>Unit: {invoice?.unit?.name || 'N/A'}</div>
                    </div>
                  </div>
                  <div className={styles.totalSection}>
                    <h2 className={styles.sectionTitle}>TOTAL DUE</h2>
                    <div className={`${styles.totalAmount} ${isFinalCredit ? styles.creditAmount : ''}`}>
                      {isFinalCredit
                        ? formatAmount(- Math.abs(calculateTotalDue()))
                        : formatAmount(calculateTotalDue())}
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.itemsGrid}>
                <div className={styles.gridHeader}>
                  <span className={styles.columnHeader}>DESCRIPTION</span>
                  <span className={`${styles.columnHeader} ${styles.right}`}>UNIT PRICE</span>
                  <span className={`${styles.columnHeader} ${styles.right}`}>QTY</span>
                  <span className={`${styles.columnHeader} ${styles.right}`}>AMOUNT</span>
                </div>

                {invoice?.items?.map((item, index) => (
                  <div key={index} className={styles.gridRow}>
                    <span className={styles.value}>{item.description}</span>
                    <span className={`${styles.value} ${styles.right}`}>
                      {formatAmount(item.unitPrice)}
                    </span>
                    <span className={`${styles.value} ${styles.right}`}>
                      {item.quantity}
                    </span>
                    <span className={`${styles.value} ${styles.right}`}>
                      {formatAmount(item.quantity * item.unitPrice)}
                    </span>
                  </div>
                ))}

                <div className={styles.calculations}>
                  <div className={styles.calculationsGrid}>
                    {/* Invoice Calculation Section */}
                    <div className={styles.calculationsRow}>
                      <span className={styles.calcLabel}>SUBTOTAL</span>
                      <span className={styles.calcValue}>
                        {formatAmount(invoice?.subTotal)}
                      </span>
                    </div>
                    <div className={styles.calculationsRow}>
                      <span className={styles.calcLabel}>
                        TAX ({((invoice?.tax / invoice?.subTotal) * 100).toFixed(0) || 0}%)
                      </span>
                      <span className={styles.calcValue}>
                        {formatAmount(invoice?.tax)}
                      </span>
                    </div>
                    <div className={styles.calculationsRow}>
                      <span className={styles.calcLabel}>INVOICE TOTAL</span>
                      <span className={styles.calcValue}>
                        {formatAmount(invoice?.totalAmount)}
                      </span>
                    </div>

                    {/* Divider */}
                    <div className={styles.divider}></div>

                    {/* Balance Brought Forward - modified to make values (not labels) have the colors */}
                    {hasBalanceBroughtForward() && (
                      <div className={styles.calculationsRow}>
                        <span className={styles.calcLabel}>BALANCE B/FORWARD</span>
                        <span className={`${styles.calcValue} ${isCredit ? styles.creditAmount : styles.debitAmount}`}>
                          {isCredit
                            ? '(' + formatBalanceBroughtForward() + ')'
                            : formatBalanceBroughtForward()}
                        </span>
                      </div>
                    )}

                    {/* Total Balance */}
                    <div className={styles.calculationsRow}>
                      <span className={styles.calcLabel}>TOTAL BALANCE</span>
                      <span className={styles.calcValue}>
                        {formatAmount(calculateTotalBalance())}
                      </span>
                    </div>

                    {/* Divider */}
                    <div className={styles.divider}></div>

                    {/* Amount Paid */}
                    <div className={styles.calculationsRow}>
                      <span className={styles.calcLabel}>PAYMENT</span>
                      <span className={styles.calcValue}>
                        {formatAmount(invoice?.amountPaid)}
                      </span>
                    </div>

                    {/* Total Due - show in green with minus sign if credit, red if debit */}
                    <div className={`${styles.calculationsRow} ${styles.balance}`}>
                      <span className={styles.calcLabel}>TOTAL DUE</span>
                      <span className={`${styles.calcValue} ${isFinalCredit ? styles.creditAmount : styles.debitAmount}`}>
                        {isFinalCredit
                          ? formatAmount(- Math.abs(calculateTotalDue()))
                          : formatAmount(calculateTotalDue())}
                      </span>
                    </div>
                  </div>

                  <div className={styles.totalRow}>
                    <span className={styles.totalLabel}>TOTAL DUE</span>
                    <span className={`${styles.totalValue} ${isFinalCredit ? styles.creditAmount : styles.debitAmount}`}>
                      {isFinalCredit
                        ? '(' + formatAmount(Math.abs(calculateTotalDue())) + ')'
                        : formatAmount(calculateTotalDue())}
                    </span>
                  </div>
                </div>
              </div>

              <div className={styles.footer} style={{ backgroundColor: '#f0f0f0' }}>
                <div className="row">
                  <div className="col-sm-6 mt-2 px-5">
                    {/* Payment Details on Left Margin */}
                    <div className={styles.paymentDetails}>
                      <h6 className={styles.detailsTitle}>Payment Details:</h6>
                      <h6 className={styles.detailsText}>Bank: {paymentInfo.module}</h6>
                      <h6 className={styles.detailsText}>
                        Paybill: {paymentInfo.shortCode}
                      </h6>
                      <h6 className={styles.detailsText}>
                        Account Number : {invoice?.accountNumber}
                      </h6>
                    </div>
                  </div>

                  <div className="col-sm-6">
                    {/* Signature Placeholder on Right Margin */}
                    <div className={styles.signatureSection}>
                      <div className={styles.signatureBox}>
                        <p style={{ textAlign: 'center', marginTop: '20px' }}>___________________________</p>
                        <p style={{ textAlign: 'center' }}>Authorized Signature</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 pt-2">
                    <div className={styles.centeredText}>
                      <p style={{ textAlign: 'center' }}>
                        To complete your payment, kindly use the paybill number
                        <strong> {paymentInfo.shortCode} </strong>,
                        followed by the account number <strong> {invoice?.accountNumber || 'N/A'} </strong>.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ViewInvoice;
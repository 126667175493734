
import React, { useState, useEffect } from 'react'
import { DataTable } from 'primereact/datatable';
import { Link, useNavigate } from 'react-router-dom'
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import 'primereact/resources/themes/lara-light-blue/theme.css';

import { Dialog } from 'primereact/dialog';
import { deleteVisitorURL, getVisitorsURL, residentUnitsURL, inviteVisitorURL, updateVisitorURL } from '../../../utils/urls'
import Layout from '../component/layout'
import { filterObjectsByAllProperties } from '../../../utils/filterSearch';
import { makeRequest2 } from '../../../utils/makeRequest';
import { toastify } from '../../../utils/toast';
import { getItem } from '../../../utils/localStorage';
import { addDaysToDate } from '../../../utils/addDaysToDate';

function Visitors() {
  const navigate = useNavigate()
  const [visitors, setVisitors] = useState([]);
  const [inviteDialogVisible, setInviteDialogVisible] = useState(false)

  const [selectedVisitor, setSelectedVisitor] = useState(null);
  const [visitingDate, setVisitingDate] = useState('');
  const [visitingDays, setVisitingDays] = useState('');
  const [visitingEndDate, setVisitingEndDate] = useState('');
  const [unitId, setUnitId] = useState('');
  const [visibleEdit, setVisibleEdit] = useState(false)
  const [searchTerm, setSearchTerm] = useState('');
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')


  const fetchVisitors = async () => {
    try {
      const CustomerId = await getItem('selectedCustomerId');
      const value = await getItem('RESIDENTUSER');
      const userData = JSON.parse(value)
      let userType
      let id;
      if (userData.user.role === 'family') {
        id = userData.user.userId
      }
      if (userData.user.role === 'admin') {
        id = CustomerId
      }
      const response = await makeRequest2(getVisitorsURL + '/' + id, 'GET', {});

      if (response.success) {
        setVisitors(response.data.data);
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      toastify(err.message, 'error')
    }
  };
  const fetchResidentUnits = async () => {
    try {
      const CustomerId = await getItem('selectedCustomerId');
      const FacilityId = await getItem('selectedFacilityId');

      const response = await makeRequest2(residentUnitsURL + '/' + CustomerId + '/' + FacilityId, 'GET', {})
      if (response.success) {

        setUnitId(response.data[0]._id)

      }
      else {
        throw new Error(response.error)
      }
    }
    catch (err) {
      toastify(err.message, 'error')
    }
  }
  const handleDeleteVisitor = async (visitorId) => {
    try {
      const response = await makeRequest2(`${deleteVisitorURL}/${visitorId}`, 'DELETE', {});
      if (response.success) {
        toastify('Visitor deleted successfully', 'success');
        fetchVisitors()
      }
      else {
        throw new Error(response.error)
      }
    }
    catch (err) {
      toastify(err.message, 'error')
    }
  }
  const handleInputSearch = async (value) => {
    try {
      setSearchTerm(value);
      if (value === '') {
        fetchVisitors();
      } else {
        const results = await filterObjectsByAllProperties(visitors, value);
        setVisitors(results);
      }
    } catch (error) {
      toastify(error.message, 'error');
    }
  };
  const handleSubmitClick = async () => {
    try {

      if (visitingDate === '') {
        throw new Error('Visiting Date is required')
      }
      else if (visitingDays < 0) {
        throw new Error('Visiting Days should be greater or equal to 0.')
      }
      else if (unitId === '') {
        throw new Error('Unit is required')
      }
      else {
        const CustomerId = await getItem('selectedCustomerId');
        const FacilityId = await getItem('selectedFacilityId');
        const value = await getItem('RESIDENTUSER');
        const userData = JSON.parse(value)
        let id
        let userType
        if (userData.user.role === 'family') {
          id = userData.user.userId
          userType = 'family'
        }
        if (userData.user.role === 'admin') {
          id = CustomerId
          userType = 'admin'
        }
        const response = await makeRequest2(inviteVisitorURL + '/' + id + '/' + FacilityId, 'POST', {
          visitingDate, visitingDays, visitingEndDate, unit: unitId, selectedVisitor, userType
        })
        if (response.success) {
          fetchVisitors();
          toastify('Invite sent successfully', 'success')
          setInviteDialogVisible(false);
          setVisitingDate('')
          setVisitingDays(0)
          setVisitingEndDate('')

          return
        }
        else {
          throw new Error(response.error)
        }
      }


    }
    catch (err) {
      toastify(err.message, 'error')
      return
    }
  }

  const updateVisitor = async () => {
    try {
      if (firstName === '') {
        throw new Error('First Name is required')
      }
      else if (lastName === '') {
        throw new Error('Last Name is required')
      }
      else if (phoneNumber === '') {
        throw new Error('Phone Number is required')
      }
      else if (!/^(07\d{8}|254\d{9})$/.test(phoneNumber)) {
        throw new Error('Phone number must be in the format "0712345678" or "254712345678".');
      }
      else {
        const response = await makeRequest2(`${updateVisitorURL}/${selectedVisitor._id}`, 'POST', {
          firstName, lastName, phoneNumber: phoneNumber, visitingDate
        })
        console.log("response", visitingDate)
        if (response.success) {
          toastify(response.data, 'success');
          fetchVisitors();
          setVisibleEdit(false);

        }
        else {
          toastify(response.error, 'error')
        }
      }

    }
    catch (err) {
      toastify(err.message, 'error')
    }
  }
  const handleActionClick = (rowData) => {
    setSelectedVisitor(rowData);
    setInviteDialogVisible(true);
    setVisitingDate('')
    setVisitingDays(0)
    setVisitingEndDate('')

  };
  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">

        <Button
          icon="ti ti-row-insert-top"
          className="p-button-rounded p-button-info mr-2"
          onClick={() => { handleActionClick(rowData) }}
          tooltip="Invite"
        />
        <Button
          icon={`ti ti-edit`}  // Fixed template literal for dynamic icon
          className="p-button-rounded p-button-success"
          onClick={() => {
            setSelectedVisitor(rowData)
            setVisibleEdit(true)
            setFirstName(rowData.firstName)
            setLastName(rowData.lastName)
            setPhoneNumber(rowData.phoneNumber)
            setVisitingDate('')


          }}
          tooltip={'Edit'}  // Fixed dynamic tooltip
        />
        <Button
          icon="ti ti-trash"
          className="p-button-rounded p-button-danger mr-2"
          onClick={() => {
            handleDeleteVisitor(rowData._id)

          }}
          tooltip="Delete"

        />
      </div>
      // <React.Fragment>
      //     <Button icon="pi pi-ellipsis-v" className="p-button-rounded p-button-text" onClick={() => handleActionClick(rowData)} />
      // </React.Fragment>
    );
  };
  const phoneNumberTemplate = (rowData) => {
    const phoneNumber = rowData.phoneNumber;
    return '+254' + phoneNumber
  }
  const invitedTemplate = (rowData) => {
    const invited = rowData.invited;

    return (
      <span
        style={{
          fontWeight: 'bold',
          color: !invited ? '#ff4d4d' : '#4caf50',  // Red for Disabled, Green for Enabled
          padding: '5px 10px',
          borderRadius: '15px',
          backgroundColor: !invited ? '#ffebeb' : '#e8f5e9'  // Light red or green background
        }}
      >
        {!invited ? 'Not Invited' : 'Invited'}
      </span>
    );
  }
  const invitedDate = (rowData) => {
    if (!rowData.invitationDate || isNaN(new Date(rowData.invitationDate).getTime())) {
      return <span>......</span>;
    } else {
      return <span>{formatDateToMonthDayYear(rowData.invitationDate)}</span>;
    }
  };



  const getTodaysDate = () => {
    const date = new Date();

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const today = `${year}-${month}-${day}`;
    setVisitingDate(today)
  }

  useEffect(() => {
    fetchVisitors();
    fetchResidentUnits()
    getTodaysDate()

  }, []);
  const header = (
    <div className="flex align-items-center justify-content-end gap-2">
      <Button type="button" label="CSV" rounded onClick={() => { }} data-pr-tooltip="CSV" />
      <Button type="button" label="XLS" severity="success" rounded onClick={() => { }} data-pr-tooltip="XLS" />
      <Button type="button" label="PDF" severity="warning" rounded onClick={() => { }} data-pr-tooltip="PDF" />
    </div>
  );
  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/resident/"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"#"}>Visitor Access</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/resident/visitor_access/visitors"}>Visitors</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">


          <div className="row">

            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">


                    <div className="col-md-3 col-xs-12 mt-3">
                      <br />
                      <input
                        className="form-control"
                        placeholder="Search here"
                        value={searchTerm}
                        onChange={(e) => { handleInputSearch(e.target.value) }}
                      />
                    </div>
                    <div className="col-md-9 col-xs-12 mt-3" >
                      <div style={{ float: 'right' }}>
                        <br />
                        <button className="btn btn-primary" onClick={() => { navigate('/resident/visitor_access/add_visitor') }}><i className="ti ti-plus pt-2"></i> &nbsp;Add Visitor</button>
                      </div>

                    </div>


                  </div>

                </div>
                <div className="card-body">
                  <div className="dt-responsive table-responsive">
                    <DataTable value={visitors} emptyMessage="No visitors found." sortMode="multiple" paginator rows={10} stripedRows tableStyle={{ minWidth: '50rem' }}>


                      <Column header="Visitor Name" body={(rowData) => `${rowData.firstName} ${rowData.lastName}`}></Column>

                      <Column body={phoneNumberTemplate} header="Phone Number"></Column>
                      <Column body={invitedTemplate} header="Status"></Column>
                      <Column body={invitedDate} header="Start Invite Date"></Column>
                      <Column body={actionBodyTemplate} header="Action"></Column>

                    </DataTable>


                  </div>
                </div>
              </div>
            </div>


          </div>

        </div>
      </div>
      <Dialog
        header={'Edit'}
        visible={visibleEdit}
        style={{ width: '50vw' }}
        onHide={() => {
          if (visibleEdit) setVisibleEdit(false);
        }}
      >
        <div className="row">
          <div className="col-md-12">
            {
              selectedVisitor && <>
                <div className="mt-2">
                  <label>First Name:</label>
                  <input className="form-control mt-2" placeholder={selectedVisitor.firstName} value={firstName} onChange={(e) => {
                    setFirstName(e.target.value)
                  }} ></input>
                </div>
                <div className="mt-2">
                  <label>Last Name:</label>
                  <input className="form-control mt-2" placeholder={selectedVisitor.lastName} value={lastName} onChange={(e) => {
                    setLastName(e.target.value)
                  }}></input>
                </div>
                <div className="mt-2">
                  <label>Phone Number:</label>
                  <input className="form-control mt-1" placeholder={"254" + selectedVisitor.phoneNumber} value={phoneNumber} onChange={(e) => {
                    setPhoneNumber(e.target.value)
                  }}></input>
                </div>
                <div className="mt-2">
                  <label>Visiting Date:</label>
                  <input className="form-control mt-1" type='date' value={visitingDate} onChange={(e) => {
                    setVisitingDate(e.target.value)
                  }}></input>
                </div>
                <div className="mt-2" style={{ float: 'right' }}>
                  <button className="btn btn-primary" onClick={updateVisitor}>Update</button>
                </div>

              </>
            }
          </div>

        </div>


      </Dialog>
      <Dialog
        header={'Invite'}
        visible={inviteDialogVisible}

        style={{ width: '50vw' }}
        onHide={() => {
          if (inviteDialogVisible) setInviteDialogVisible(false);
        }}
      >

        <div className="row">
          <div className="col-md-12 mt-3">


            <div className="row">
              <div className="col-sm-12">
                <div className="card-body">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                        {
                          selectedVisitor &&
                          <div className="card">
                            <div className="card-body">
                              <p><strong>Full Name:</strong> {selectedVisitor.firstName} {selectedVisitor.lastName}</p>
                              <p><strong>Phone Number:</strong> +254{selectedVisitor.phoneNumber}</p>
                            </div>

                          </div>
                        }


                      </div>

                    </div>


                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label className="form-label">Visiting Start Date</label>
                          <input
                            type="date"
                            className="form-control"
                            value={visitingDate}
                            onChange={async (e) => {
                              setVisitingDate(e.target.value);


                              setVisitingDays(0)
                              const endDate = await addDaysToDate(0, e.target.value)
                              setVisitingEndDate(endDate)

                            }}
                            min={new Date().toISOString().split("T")[0]} // Set min to today's date
                          />
                        </div>
                      </div>

                      {
                        visitingDate !== '' && <>
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label">Visiting Days</label>
                              <input type="number" className="form-control" value={visitingDays} onChange={async (e) => {
                                if (parseInt(e.target.value) < 0) {
                                  toastify('Number should be greater than 0', 'error')
                                }
                                else {
                                  setVisitingDays(e.target.value)
                                  const endDate = await addDaysToDate(parseInt(e.target.value), visitingDate)
                                  setVisitingEndDate(endDate)
                                }

                              }} />
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label">Visit End Date: <strong style={{ color: 'red' }}>{visitingEndDate !== '' && visitingEndDate}</strong></label>
                            </div>
                          </div>
                        </>
                      }


                      {/* <div className="col-md-12">
                        <div className="mb-3">
                          <label className="form-label">Unit</label>
                          <select className="form-control" value={unit} onChange={(e) => {
                            setUnit(e.target.value)
                          }}>
                            <option value="">-- Select --</option>
                            {
                              units.map((unit) => {
                                return <option value={unit.name}>{unit.name}</option>
                              })
                            }
                          </select>
                        </div>
                      </div> */}
                    </div>




                  </div>
                </div>
              </div>
            </div>





            <div className='mt-4' style={{ float: 'right' }}>
              <button className="btn btn-primary"
                onClick={handleSubmitClick}
              >
                Submit
              </button>
            </div>

          </div>

        </div>



      </Dialog>

    </Layout>
  )
}
function formatDateToMonthDayYear(dateInput) {
  const date = new Date(dateInput);

  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return date.toLocaleDateString('en-US', options);
}

export default Visitors
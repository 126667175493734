//ServiceDetailsModal.js
import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

const StatusTemplate = ({ status }) => {
    const getStatusConfig = (status) => {
        switch (status.toLowerCase()) {
            case 'pending':
                return {
                    color: '#ff9800',
                    bgColor: '#fff3e0'
                };
            case 'awaiting':
                return {
                    color: '#2196f3',
                    bgColor: '#e3f2fd'
                };
            case 'in progress':
                return {
                    color: '#2196f3',
                    bgColor: '#e3f2fd'
                };
            case 'completed':
                return {
                    color: '#4caf50',
                    bgColor: '#e8f5e9'
                };
            case 'cancelled':
                return {
                    color: '#ff4d4d',
                    bgColor: '#ffebeb'
                };
            default:
                return {
                    color: '#2196f3',
                    bgColor: '#e3f2fd'
                };
        }
    };

    const config = getStatusConfig(status);

    return (
        <span
            style={{
                fontWeight: "bold",
                color: config.color,
                padding: "5px 10px",
                borderRadius: "15px",
                backgroundColor: config.bgColor,
                display: "inline-block",
                textAlign: "center",
                minWidth: "100px"
            }}
        >
            {status}
        </span>
    );
};

const ServiceDetailsModal = ({
    visible,
    onHide,
    selectedServiceDetails,
    getServiceName,
    onApprove,
    onReject,
    generatingPDF = false
}) => {
    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };

    const formatTime = (timeString) => {
        if (!timeString) return 'N/A';
        const [hours, minutes] = timeString.split(':');
        const hour = parseInt(hours, 10);
        const ampm = hour >= 12 ? 'PM' : 'AM';
        const hour12 = hour % 12 || 12;
        return `${hour12}:${minutes} ${ampm}`;
    };

    const formatCurrency = (amount) => {
        if (!amount && amount !== 0) return 'N/A';
        return `KSH ${amount.toLocaleString()}`;
    };

    const renderActionButtons = () => {
        if (selectedServiceDetails?.status.toLowerCase() === 'awaiting') {
            return (
                <div className="mt-2 pt-2">
                    <div className="row mb-3">
                        <div className="col-12">
                            <label className="text-muted mb-2">Service Amount</label>
                            <h6 className="m-0">{formatCurrency(selectedServiceDetails.amount)}</h6>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end gap-2">
                        <Button
                            label="Reject"
                            className="btn btn-outline-secondary me-2"
                            onClick={() => onReject(selectedServiceDetails._id)}
                            disabled={generatingPDF}
                        />
                        <Button
                            label={generatingPDF ? "Processing..." : "Approve"}
                            className="btn btn-primary"
                            onClick={() => onApprove(selectedServiceDetails)}
                            disabled={generatingPDF}
                            icon={generatingPDF ? "pi pi-spin pi-spinner" : null}
                        />
                    </div>
                </div>
            );
        }
        return null;
    };

    return (
        <Dialog
            header={
                <div className="dialog-header p-3">
                    <h5 className="m-0">Service Details</h5>
                </div>
            }
            visible={visible}
            onHide={onHide}
            style={{ width: '40vw', maxWidth: '500px' }}
            className="service-details-dialog"
            contentClassName="p-0"
        >
            {selectedServiceDetails && (
                <div className="p-4">
                    <div className="service-info-grid">
                        <div className="info-section mb-4">
                            <div className="row mb-3">
                                <div className="col-6">
                                    <label className="text-muted mb-2">Service Name</label>
                                    <h6 className="m-0">{getServiceName(selectedServiceDetails.serviceId)}</h6>
                                </div>
                                <div className="col-6">
                                    <label className="text-muted mb-2">Status</label>
                                    <div>
                                        <StatusTemplate status={selectedServiceDetails.status} />
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-6">
                                    <label className="text-muted mb-2">Request Date</label>
                                    <h6 className="m-0">{formatDate(selectedServiceDetails.date)}</h6>
                                </div>
                                <div className="col-6">
                                    <label className="text-muted mb-2">Scheduled Time</label>
                                    <h6 className="m-0">{formatTime(selectedServiceDetails.time)}</h6>
                                </div>
                            </div>

                            {selectedServiceDetails.isCommissionSelected && (
                                <div className="row mb-3">
                                    <div className="col-6">
                                        {selectedServiceDetails.percentage && (
                                            <div>
                                                <label className="text-muted mb-2">Commission</label>
                                                <h6 className="m-0">{selectedServiceDetails.percentage}%</h6>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                            {generatingPDF && (
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <div style={{
                                            backgroundColor: '#e3f2fd',
                                            color: '#0d47a1',
                                            padding: '10px',
                                            borderRadius: '5px',
                                            marginTop: '10px'
                                        }}>
                                            <i className="pi pi-spin pi-spinner me-2"></i>
                                            Generating service request PDF...
                                        </div>
                                    </div>
                                </div>
                            )}

                            {renderActionButtons()}
                        </div>
                    </div>
                </div>
            )}
        </Dialog>
    );
};

export default ServiceDetailsModal;
// Services.js (Parent Component)
import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../component/layout';
import ServiceRequestForm from './components/service_request_form';
import ServiceHistoryList from './components/service_history_list';
import ServiceDetailsModal from './components/service_details_modal';
import { useServices } from './hooks/use_services';

const styles = `
  .service-details-dialog .p-dialog-header {
    padding: 0.5rem 1.0rem !important;
  }
  
  .service-details-dialog .p-dialog-content {
    padding: 0.5rem 1.0rem!important;
  }
  
  .service-details-dialog .p-dialog-footer {
    padding: 0;
  }
  
  .service-details-dialog label {
    font-size: 0.875rem;
    font-weight: 500;
    color: #6c757d;
  }
  
  .service-details-dialog h6 {
    font-size: 1rem;
    font-weight: 500;
    color: #344767;
  }
  
  .info-section {
    background-color: #fff;
    border-radius: 8px;
  }
`;

function Services() {
    const {
        selectedService,
        services,
        serviceData,
        date,
        time,
        visible,
        selectedServiceDetails,
        searchTerm,
        units,
        selectedUnit,
        generatingPDF,
        setSelectedService,
        setDate,
        setTime,
        setSearchTerm,
        setSelectedUnit,
        handleSubmit,
        getServiceName,
        openModal,
        closeModal,
        handleApprove,
        handleReject
    } = useServices();

    React.useEffect(() => {
        const styleSheet = document.createElement('style');
        styleSheet.textContent = styles;
        document.head.appendChild(styleSheet);

        return () => {
            document.head.removeChild(styleSheet);
        };
    }, []);

    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/app">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">VAS Management</li>
                                <li className="breadcrumb-item active">Service Request</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h4>Service Request</h4>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <ServiceRequestForm
                                    selectedService={selectedService}
                                    services={services}
                                    serviceData={serviceData}
                                    date={date}
                                    time={time}
                                    units={units}
                                    selectedUnit={selectedUnit}
                                    onServiceChange={setSelectedService}
                                    onDateChange={setDate}
                                    onTimeChange={setTime}
                                    onUnitChange={setSelectedUnit}
                                    onSubmit={handleSubmit}
                                />
                                <ServiceHistoryList
                                    serviceData={serviceData}
                                    searchTerm={searchTerm}
                                    onSearchChange={setSearchTerm}
                                    getServiceName={getServiceName}
                                    onViewDetails={openModal}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ServiceDetailsModal
                visible={visible}
                onHide={closeModal}
                selectedServiceDetails={selectedServiceDetails}
                getServiceName={getServiceName}
                onApprove={handleApprove}
                onReject={handleReject}
                generatingPDF={generatingPDF}
            />
        </Layout>
    );
}

export default Services;
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export const useFacilityStore = create(
  persist(
    (set) => ({
      facilityId: null,
      customerId: null,
      selectedFacility: null,
      facilities: [],
      setFacilityId: (id) => set({ facilityId: id }),
      setCustomerId: (id) => set({ customerId: id }),
      setSelectedFacility: (facility) => set({ selectedFacility: facility }),
      setFacilities: (facilities) => set({ facilities }),
      clearAll: () =>
        set({
          facilityId: null,
          customerId: null,
          selectedFacility: null,
          facilities: [],
        }),
    }),
    {
      name: "facility-storage",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

import React, { useState, useEffect, useRef } from 'react'
import { DataTable } from 'primereact/datatable';
import { Link, useNavigate } from 'react-router-dom'
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { toastify } from '../../../utils/toast'
import QRCode from "react-qr-code";
import { toPng } from "html-to-image";
import download from "downloadjs";
import { makeRequest2 } from '../../../utils/makeRequest'
import 'primereact/resources/themes/lara-light-blue/theme.css';
import { filterObjectsByAllProperties } from '../../../utils/filterSearch';
import { getVehiclesURL, deleteVehicleURL, handleVehicleStatusURL, updatevehicleURL } from '../../../utils/urls'
import { getItem } from '../../../utils/localStorage';
import ConfirmDialog from '../component/confirmDialog';
import Layout from '../component/layout'
function Vehicles() {
  const qrCodeRef = useRef();
  const navigate = useNavigate()
  const [vehicles, setVehicles] = useState([])
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedQRCode, setSelectedQRCode] = useState({})
  const [visibleQRCode, setVisibleQRCode] = useState(false)
  const [customerId, setCustomerId] = useState('')
  const [customerNo, setCustomerNo] = useState("")
  const [selectedNo, setSelectedNo] = useState(null);
  const [confirmAction, setConfirmAction] = useState('');  // Track the current action ('delete', 'enable', 'disable')
  const [selectedDisableStatus, setSelectedDisableStatus] = useState(false);  // Track the current disable status
  const [isConfirmDialogVisible, setConfirmDialogVisible] = useState(false);
  const [selectedVehicleData, setSelectedVehicleData] = useState(null);
  const [actionType, setActionType] = useState('')
  const [visibleEdit, setVisibleEdit] = useState(false)

  const [name, setName] = useState('');
  const [model, setModel] = useState('')
  const [color, setColor] = useState('')
  const [plateNumber, setPlateNumber] = useState('');

  const qrCodeTemplate = (rowData) => {
    rowData.type = 'Vehicle'
    rowData.customerId = customerId

    if (rowData.qrCode === undefined) {
      return `....`
    }
    else {
      if (rowData.qrCode) {
        return <button className="btn btn-dark btn-sm" onClick={
          () => {
            setSelectedQRCode(rowData)
            setSelectedNo(rowData.no);
            setVisibleQRCode(true)
          }
        }>QR Code</button>
      }
      else {
        return `....`
      }
    }
  }
  const getCustomerNo = async () => {
    try {
      const customerNo = await getItem('selectedCustomerNo')
      setCustomerNo(customerNo)
    }
    catch (err) {
      console.log(err.message)
    }
  }
  const handleDownloadQRCode = () => {
    if (qrCodeRef.current) {
      console.log(qrCodeRef.current)
      toPng(qrCodeRef.current)
        .then((dataUrl) => {

          download(dataUrl, "qrcode.png");
        })
        .catch((err) => {
          console.error("Oops, something went wrong!", err);
        });
    }
  };
  const fetchVehicles = async () => {
    try {
      const CustomerId = await getItem('selectedCustomerId');
      const response = await makeRequest2(getVehiclesURL + '/' + CustomerId, 'GET', {})
      if (response.success) {
        setVehicles(response.data.vehicles)
      }
      else {
        throw new Error(response.error)
      }
    }
    catch (err) {
      throw new Error(err.message)
    }
  }
  const handleInputSearch = async (value) => {
    try {
      setSearchTerm(value);
      if (value === '') {
        fetchVehicles()
      } else {
        const results = await filterObjectsByAllProperties(vehicles, value);
        setVehicles(results);
      }
    } catch (error) {
      toastify(error.message, 'error');
    }
  };
  const statusTemplate = (rowData) => {
    const isDisabled = rowData.disabled;

    return (
      <span
        style={{
          fontWeight: 'bold',
          color: isDisabled ? '#ff4d4d' : '#4caf50',  // Red for Disabled, Green for Enabled
          padding: '5px 10px',
          borderRadius: '15px',
          backgroundColor: isDisabled ? '#ffebeb' : '#e8f5e9'  // Light red or green background
        }}
      >
        {isDisabled ? 'Disabled' : 'Enabled'}
      </span>
    );
  };

  const actionVehicleTemplate = (rowData) => {
    const isDisabled = rowData.disabled;


    return (
      <div className="actions">
        <Button
          icon={`ti ti-edit`}  // Fixed template literal for dynamic icon
          className="p-button-rounded p-button-success"
          onClick={() => {
            setSelectedVehicleData(rowData)
            setName(rowData.name)
            setModel(rowData.model)
            setColor(rowData.color)
            setPlateNumber(rowData.plateNumber)
            setVisibleEdit(true)

          }}
          tooltip={'Edit'}  // Fixed dynamic tooltip
        />
        <Button
          icon="ti ti-trash"
          className="p-button-rounded p-button-danger mr-2"
          onClick={() => {
            setActionType('Vehicle')
            setConfirmAction('delete');
            setConfirmDialogVisible(true);
            setSelectedVehicleData(rowData)
          }}
          tooltip="Delete"
        />
        <Button
          icon={`ti ${isDisabled ? 'ti-lock-open' : 'ti-lock'}`}  // Fixed template literal for dynamic icon
          className="p-button-rounded p-button-warning"
          onClick={() => {
            setActionType('Vehicle')
            setConfirmAction(isDisabled ? 'enable' : 'disable');  // Set action to enable/disable
            setSelectedDisableStatus(isDisabled);
            setConfirmDialogVisible(true);
            setSelectedVehicleData(rowData)

          }}

        />
      </div>



    );
  };

  const updateVehicle = async () => {
    try {
      const vehicleId = selectedVehicleData._id;
      const customerId = await getItem('selectedCustomerId');
      const response = await makeRequest2(updatevehicleURL + '/' + customerId + '/' + vehicleId, 'POST', {
        name,
        model,
        color,
        plateNumber,
      })
      if (response.success) {
        toastify(response.data, 'success');
        setVisibleEdit(false);
        fetchVehicles()
      }
      else {
        throw new Error(response.error)
      }
    }
    catch (err) {
      toastify(err.message, 'error')
    }
  }

  const handleVehicleStatus = async (Id) => {
    try {
      const response = await makeRequest2(handleVehicleStatusURL + '/' + customerId + '/' + Id, 'POST', {
        disabled: !selectedVehicleData.disabled
      });
      if (response.success) {
        setConfirmDialogVisible(false);
        setConfirmAction('');
        fetchVehicles()
        toastify('Successfully submitted', 'success')
      }
      else {
        throw new Error(response.error)
      }
    }
    catch (err) {
      toastify(err.message, 'error')
    }
  };
  const handleVehicleDelete = async (Id) => {
    try {
      const response = await makeRequest2(deleteVehicleURL + '/' + customerId + '/' + Id, 'DELETE', '');
      if (response.success) {
        setConfirmDialogVisible(false);
        setConfirmAction('');
        fetchVehicles()
        toastify('Vehicle Successfully deleted', 'success')
      }
      else {
        throw new Error(response.error)
      }
    }
    catch (err) {
      toastify(err.message, 'error')
    }
  };
  const handleConfirmAction = async () => {

    setConfirmDialogVisible(false);


    if (confirmAction === 'delete') {
      // Handle delete logic here
      await handleVehicleDelete(selectedVehicleData._id);
    } else if (confirmAction === 'enable' || confirmAction === 'disable') {
      // Handle enable/disable logic here
      await handleVehicleStatus(selectedVehicleData._id);
    }



  };

  const getCustomerId = async () => {
    const cID = await getItem('selectedCustomerId');
    setCustomerId(cID)
  }
  useEffect(() => {
    fetchVehicles()
    getCustomerId()
    getCustomerNo()
  }, [])
  const header = (
    <div className="flex align-items-center justify-content-end gap-2">
      <Button type="button" label="CSV" rounded onClick={() => { }} data-pr-tooltip="CSV" />
      <Button type="button" label="XLS" severity="success" rounded onClick={() => { }} data-pr-tooltip="XLS" />
      <Button type="button" label="PDF" severity="warning" rounded onClick={() => { }} data-pr-tooltip="PDF" />
    </div>
  );
  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/resident/"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"#"}>Visitor Access</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/resident/visitor_access/vehicles"}>Vehicles</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">


          <div className="row">

            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">

                    <div className="col-md-3 col-xs-12 mb-3">
                      <input
                        className="form-control"
                        placeholder="Search here"
                        value={searchTerm}
                        onChange={(e) => { handleInputSearch(e.target.value) }}
                      />
                    </div>
                    <div className="col-md-9 col-xs-12 mt-3" >
                      <div style={{ float: 'right' }}>
                        <br />
                        <button className="btn btn-primary" onClick={() => { navigate('/resident/visitor_access/add_vehicle') }}><i className="ti ti-plus pt-2"></i> &nbsp;Add Vehicle</button>
                      </div>

                    </div>


                  </div>

                </div>
                <div className="card-body">
                  <div className="dt-responsive table-responsive">
                    <DataTable value={vehicles} emptyMessage="No vehicles found." sortMode="multiple" paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>
                      <Column field="no" header="Number"></Column>
                      <Column field="name" header="Brand"></Column>
                      <Column field="model" header="Model"></Column>
                      <Column field="color" header="Color"></Column>
                      <Column field="plateNumber" header="Plate Number"></Column>
                      <Column body={qrCodeTemplate} header="QRCode" />
                      <Column body={statusTemplate} header="Status" />

                      <Column body={actionVehicleTemplate} header="Actions"></Column>

                    </DataTable>


                  </div>
                </div>
              </div>
            </div>


          </div>

        </div>
      </div>

      <Dialog
        header={'Edit'}
        visible={visibleEdit}
        style={{ width: '50vw' }}
        onHide={() => {
          if (visibleEdit) setVisibleEdit(false);
        }}
      >
        <div className="row">
          <div className="col-md-12">
            {
              selectedVehicleData && <>
                <div className="mt-2">
                  <label>Brand:</label>
                  <input className="form-control mt-2" placeholder={selectedVehicleData.brand} value={name} onChange={(e) => {
                    setName(e.target.value)
                  }}></input>
                </div>

                <div className="mt-2">
                  <label>Model:</label>
                  <input className="form-control mt-1" placeholder={selectedVehicleData.model} value={model} onChange={(e) => {
                    setModel(e.target.value)
                  }}></input>
                </div>

                <div className="mt-2">
                  <label>Color:</label>
                  <input className="form-control mt-1" placeholder={selectedVehicleData.color} value={color} onChange={(e) => {
                    setColor(e.target.value)
                  }}></input>
                </div>

                <div className="mt-2">
                  <label>Plate Number:</label>
                  <input className="form-control mt-1" placeholder={selectedVehicleData.plateNumber} value={plateNumber} onChange={(e) => {
                    setPlateNumber(e.target.value)
                  }}></input>
                </div>

                <div className="mt-2" style={{ float: 'right' }}>
                  <button className="btn btn-primary" onClick={updateVehicle}>Update</button>
                </div>

              </>
            }
          </div>
        </div>


      </Dialog>

      <Dialog
        header={'QR Code'}
        visible={visibleQRCode}
        onHide={() => {
          if (visibleQRCode) setVisibleQRCode(false);
        }}
      >
        <div className="text-center mt-4">
          <div ref={qrCodeRef}>
            <QRCode value={JSON.stringify(selectedQRCode)} />
            <br />
            <br />
            <p style={{ color: 'white', backgroundColor: 'black' }}>{selectedNo}</p>
          </div>
          <br />
          <button className="btn btn-primary mt-3" onClick={handleDownloadQRCode}>
            Download QR Code
          </button>
        </div>


      </Dialog>
      <ConfirmDialog
        visible={isConfirmDialogVisible}
        onHide={() => setConfirmDialogVisible(false)}
        message={`Are you sure you want to ${confirmAction}`}
        onConfirm={handleConfirmAction}
      />

    </Layout>
  )
}


export default Vehicles
import React, { useState, useEffect } from 'react'
import Layout from '../component/layout'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Link, useNavigate } from 'react-router-dom'
import { toastify } from '../../../utils/toast'
import { makeRequest2 } from '../../../utils/makeRequest'
import { getLeaseInvoicesURL } from '../../../utils/urls'
import { getItem } from '../../../utils/localStorage'

const LeaseInvoices = () => {
    const navigate = useNavigate()
    const [invoices, setInvoices] = useState([])
    const [filteredInvoices, setFilteredInvoices] = useState([])
    const [summary, setSummary] = useState(null)
    const [loading, setLoading] = useState(false)
    const [filters, setFilters] = useState({
        recipient: '',
        startDate: '',
        endDate: ''
    })

    const fetchInvoices = async () => {
        setLoading(true)
        try {
            const facilityId = await getItem('selectedFacilityId');
            const residentUserStr = await getItem('RESIDENTUSER');
            const token = await getItem('token'); // Get authentication token

            if (!residentUserStr) {
                toastify('User session not found', 'error');
                return;
            }

            const residentUser = JSON.parse(residentUserStr);
            const customerData = residentUser.user.customerData.find(
                data => data.facilityId === facilityId
            );

            const customerId = customerData?.customerId;

            if (!facilityId || !customerId) {
                toastify('Required information missing', 'error');
                return;
            }

            const response = await makeRequest2(`${getLeaseInvoicesURL}/${facilityId}/${customerId}`, 'GET', {})
            console.log('Invoice response:', response);

            if (response.success) {
                // Check if the response has the updated structure with data.data format
                if (response.data?.data) {
                    const fetchedInvoices = response.data.data.invoices || [];

                    // Filter invoices to include only those of type 'Lease'
                    const leaseInvoices = fetchedInvoices.filter(invoice => invoice.whatFor?.invoiceType === 'Lease');

                    setInvoices(leaseInvoices);
                    setFilteredInvoices(leaseInvoices);
                    setSummary(response.data.data.summary || null);
                } else {
                    // Handle legacy response format
                    const fetchedInvoices = response.data.invoices || [];

                    // Filter invoices to include only those of type 'Lease'
                    const leaseInvoices = fetchedInvoices.filter(invoice => invoice.whatFor?.invoiceType === 'Lease');

                    setInvoices(leaseInvoices);
                    setFilteredInvoices(leaseInvoices);

                    // Create basic summary from invoices if not provided
                    if (leaseInvoices.length > 0) {
                        const basicSummary = calculateBasicSummary(leaseInvoices);
                        setSummary(basicSummary);
                    }
                }
            } else {
                toastify('No invoices found', 'warn');
                setInvoices([]);
            }
        } catch (err) {
            toastify(err.message, 'error')
        } finally {
            setLoading(false)
        }
    }


    // Helper function to calculate basic summary if not provided by API
    const calculateBasicSummary = (invoicesData) => {
        const totalInvoices = invoicesData.length;
        let totalAmount = 0;
        let totalPaid = 0;

        invoicesData.forEach(invoice => {
            totalAmount += invoice.totalAmount || 0;
            totalPaid += invoice.amountPaid || 0;
        });

        return {
            totalInvoices,
            totalAmount,
            totalPaid
        };
    };

    const statusTemplate = (rowData) => {
        const statusStyles = {
            Paid: { color: '#28a745', backgroundColor: '#e9fbe9' },
            Pending: { color: '#ffc107', backgroundColor: '#fff8e1' },
            Overdue: { color: '#dc3545', backgroundColor: '#fde9e9' },
            'Partially Paid': { color: '#ffc107', backgroundColor: '#fff3cd' },
            Unpaid: { color: '#dc3545', backgroundColor: '#fde9e9' }
        }

        const style = statusStyles[rowData.status] || {
            color: '#6c757d',
            backgroundColor: '#f1f1f1'
        }

        return (
            <span
                style={{
                    ...style,
                    padding: '0.5em 1em',
                    borderRadius: '12px',
                    fontWeight: 'bold',
                    fontSize: '0.9rem',
                    display: 'inline-block'
                }}
            >
                {rowData.status}
            </span>
        )
    }

    const actionTemplate = (rowData) => (
        <Link
            to={`/resident/statement/view_invoice/${rowData._id}`}
            style={{
                color: '#007bff',
                textDecoration: 'none',
                fontSize: '18px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
            title="View Invoice"
        >
            <i className="ti ti-eye"></i>
        </Link>
    )

    // Get currency code for an invoice
    const getInvoiceCurrency = (invoice) => {
        // Try to get currency from the invoice object if it exists
        if (invoice.currency && invoice.currency.code) {
            return invoice.currency.code;
        }

        // Default to KES if no currency indicator is found
        return 'KES';
    };

    const amountTemplate = (rowData) => {
        const currencyCode = getInvoiceCurrency(rowData);
        const amount = rowData.totalAmount || 0;
        return `${currencyCode} ${amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
    }

    const dateTemplate = (date) => {
        if (!date) return 'N/A';
        return new Date(date).toLocaleDateString();
    }

    // Format currency with the provided currencyCode
    const formatCurrency = (amount, currencyCode = 'KES') => {
        if (typeof amount !== 'number') return `${currencyCode} 0.00`;
        return `${currencyCode} ${amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
    };

    // Handle search functionality
    const handleSearch = () => {
        let results = [...invoices];

        // Filter by recipient (tenant name)
        if (filters.recipient) {
            const searchTerm = filters.recipient.toLowerCase();
            results = results.filter(invoice => {
                // Check in client name
                const clientName = `${invoice.client?.firstName || ''} ${invoice.client?.lastName || ''}`.toLowerCase();
                // Check in tenant info if available
                const tenantName = invoice.tenantInfo?.fullName?.toLowerCase() || '';

                return clientName.includes(searchTerm) || tenantName.includes(searchTerm);
            });
        }

        // Filter by start date
        if (filters.startDate) {
            const startDate = new Date(filters.startDate);
            startDate.setHours(0, 0, 0, 0);
            results = results.filter(invoice => {
                const issueDate = new Date(invoice.issueDate);
                return issueDate >= startDate;
            });
        }

        // Filter by end date
        if (filters.endDate) {
            const endDate = new Date(filters.endDate);
            endDate.setHours(23, 59, 59, 999);
            results = results.filter(invoice => {
                const issueDate = new Date(invoice.issueDate);
                return issueDate <= endDate;
            });
        }

        setFilteredInvoices(results);

        // Update summary based on filtered results
        if (results.length > 0) {
            const filteredSummary = calculateBasicSummary(results);
            setSummary(filteredSummary);
        }
    };

    // Reset filters
    const resetFilters = () => {
        setFilters({
            recipient: '',
            startDate: '',
            endDate: ''
        });
        setFilteredInvoices(invoices);

        // Reset summary to original
        if (invoices.length > 0) {
            const originalSummary = calculateBasicSummary(invoices);
            setSummary(originalSummary);
        }
    };

    useEffect(() => {
        fetchInvoices();
    }, []);

    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/resident/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Lease Invoices</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        {/* {summary && (
                            <div className="card-header bg-light">
                                <div className="row text-center">
                                    <div className="col-md-3">
                                        <h6>Total Invoices</h6>
                                        <strong>{summary.totalInvoices}</strong>
                                    </div>
                                    <div className="col-md-3">
                                        <h6>Total Amount</h6>
                                        <strong>{summary.currencyCode || 'KES'} {summary.totalAmount?.toFixed(2) || '0.00'}</strong>
                                    </div>
                                    <div className="col-md-3">
                                        <h6>Total Paid</h6>
                                        <strong>{summary.currencyCode || 'KES'} {summary.totalPaid?.toFixed(2) || '0.00'}</strong>
                                    </div>
                                    <div className="col-md-3">
                                        <h6>Outstanding</h6>
                                        <strong>{summary.currencyCode || 'KES'} {((summary.totalAmount || 0) - (summary.totalPaid || 0)).toFixed(2)}</strong>
                                    </div>
                                </div>
                            </div>
                        )} */}
                        <div className="card-header">
                            <h5>Lease Invoices</h5>
                        </div>
                        <div className="card-body">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-3">
                                            <label>Enter Recipient:</label>
                                            <input
                                                type="text"
                                                className="form-control mt-2"
                                                placeholder="Enter Recipient"
                                                value={filters.recipient}
                                                onChange={(e) => setFilters({ ...filters, recipient: e.target.value })}
                                            />
                                        </div>
                                        <div className="col-3">
                                            <label>Start Date:</label>
                                            <input
                                                type="date"
                                                className="form-control mt-2"
                                                value={filters.startDate}
                                                onChange={(e) => setFilters({ ...filters, startDate: e.target.value })}
                                            />
                                        </div>
                                        <div className="col-3">
                                            <label>End Date:</label>
                                            <input
                                                type="date"
                                                className="form-control mt-2"
                                                value={filters.endDate}
                                                onChange={(e) => setFilters({ ...filters, endDate: e.target.value })}
                                            />
                                        </div>
                                        <div className="col-3">
                                            <button
                                                className="btn btn-primary mt-4"
                                                onClick={handleSearch}
                                                style={{ marginRight: '10px' }}
                                            >
                                                Search
                                            </button>
                                            <button
                                                className="btn btn-secondary mt-4"
                                                onClick={resetFilters}
                                            >
                                                Reset
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <DataTable
                                            value={filteredInvoices}
                                            emptyMessage="No invoices found."
                                            sortMode="multiple"
                                            paginator
                                            rows={10}
                                            stripedRows
                                            loading={loading}
                                            tableStyle={{ minWidth: '50rem' }}
                                        >
                                            <Column field="invoiceNumber" header="Invoice Number" sortable />
                                            <Column field="issueDate" header="Issue Date" sortable body={(rowData) => dateTemplate(rowData.issueDate)} />
                                            <Column field="dueDate" header="Due Date" sortable body={(rowData) => dateTemplate(rowData.dueDate)} />
                                            <Column field="totalAmount" header="Amount" sortable body={amountTemplate} />
                                            <Column field="status" header="Status" sortable body={statusTemplate} />
                                            <Column body={actionTemplate} header="Actions" style={{ width: '8rem' }} />
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout >
    )
}

export default LeaseInvoices
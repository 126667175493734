import {
    createBrowserRouter
} from "react-router-dom";
// // import { BrowserRouter, Routes, Route } from "react-router-dom";

// PAGES    

import Login from '../components/authentication/login'
import ForgotPassword from '../components/authentication/forgotPassword'
import CheckEmail from '../components/authentication/checkEmail';
import CodeVerification from '../components/authentication/codeVerification';
import ResetPassword from '../components/authentication/resetPassword';
import Error404Page from '../components/error/Error404Page'


// CORES
import Dashboard from '../components/resident/dashboard/dashboard';
import Invoices from '../components/resident/statements/invoices'
import ViewLeaseInvoice from '../components/resident/leases/invoice_details/invoice_details'
import Receipts from '../components/resident/statements/receipts'
import PaymentHistory from '../components/resident/statements/paymentHistory'
import StatementofAccount from '../components/resident/statements/statementofAccount'
// import SelectWaterMeter from '../components/resident/utilities/water/select_meter'
import WaterMeterInfo from '../components/resident/utilities/water/view_water_meter_info'
import SelectPowerMeter from '../components/resident/utilities/power/select_meter'
import PowerMeterInfo from '../components/resident/utilities/power/meter_info'
import ViewTickets from '../components/resident/tickets/view_tickets';
import RaiseTickets from '../components/resident/tickets/raise_ticket';
import Feedback from '../components/resident/feedback/feedback';
import ResidentWall from '../components/resident/resident_wall/resident_wall'
import Family from '../components/resident/visitor_access_management/family';
import AddFamily from '../components/resident/visitor_access_management/add_family'
import Vehicle from '../components/resident/visitor_access_management/vehicles';
import AddVehicle from '../components/resident/visitor_access_management/add_vehicle'
import Staff from '../components/resident/visitor_access_management/staff';
import AddStaff from '../components/resident/visitor_access_management/add_staff'
import Visitors from '../components/resident/visitor_access_management/visitors';
import AddVisitor from '../components/resident/visitor_access_management/add_visitor'
import VisitationHistory from '../components/resident/visitor_access_management/digital_logs'
import VisitDigitalLog from '../components/resident/visitor_access_management/view_digital_log'
import Setting from '../components/resident/settings/setting'
import Contracts from "../components/resident/contract_management/contracts";
import Services from "../components/resident/value_added_services/services";
import VasInvoices from "../components/resident/value_added_services/invoices";
import ViewInvoice from "../components/resident/statements/invoice_management/view_invoice";
import Approvals from "../components/resident/approvals/approvals";
import ViewApprovalTicket from "../components/resident/approvals/view_approval_ticket";
import QRCodeScreen from "../components/resident/visitor_access_management/qr_code";
import ViewLeases from '../components/resident/leases/view_leases';
import ViewLeaseInvoices from '../components/resident/leases/view_lease_invoices';
import ViewVasInvoices from '../components/resident/value_added_services/invoice_details/invoice_details'
import MinimalApprovalPage from "../components/resident/approvals/minimal_approval";
import ViewWaterMeters from "../components/resident/utilities/water/my_water_meters";
import ViewWaterMeter from "../components/resident/utilities/water/view_water_meter";
import ViewWaterInvoices from "../components/resident/utilities/water/my_water_invocies";
import ViewWaterInvoicePage from "../components/resident/utilities/invoice_management/invoice_page";
import PaymentReceipt from "../components/resident/statements/receipts/invoice_receipt";




export const router = createBrowserRouter([
    {
        path: "/",
        element: <Login />,
    },
    {
        path: "/forgot_password",
        element: <ForgotPassword />,
    },
    {
        path: "/check_email",
        element: <CheckEmail />,
    },
    {
        path: "/code_verification",
        element: <CodeVerification />,
    },
    {
        path: "/reset_password/:userId",
        element: <ResetPassword />,
    },
    {
        path: "/resident/",
        element: <Dashboard />,
    },
    {
        path: "/resident/invoices",
        element: <Invoices />,
    },
    {
        path: "/resident/lease_management/invoices/:invoiceId",
        element: <ViewLeaseInvoice />
    },
    {
        path: "/resident/statement/view_invoice/:invoiceId",
        element: <ViewInvoice />,
    },
    {
        path: "/resident/receipts",
        element: <Receipts />,
    },
    {
        path: "/resident/payment_history",
        element: <PaymentHistory />,
    },
    {
        path: "/resident/statement_of_account",
        element: <StatementofAccount />,
    },
    {
        path: "/resident/water_utility/meter_info",
        element: <WaterMeterInfo />,
    },
    {
        path: "/resident/power_utility",
        element: <SelectPowerMeter />,
    },
    {
        path: "/resident/power_utility/meter_info",
        element: <PowerMeterInfo />,
    },
    {
        path: "/resident/view_tickets",
        element: <ViewTickets />,
    },
    {
        path: "/resident/raise_ticket",
        element: <RaiseTickets />,
    },
    {
        path: "/resident/feedback",
        element: <Feedback />,
    },
    {
        path: "/resident/resident_wall",
        element: <ResidentWall />,
    },
    {
        path: "/resident/visitor_access/family",
        element: <Family />,
    },
    {
        path: "/resident/visitor_access/add_family",
        element: <AddFamily />,
    },
    {
        path: "/resident/visitor_access/vehicles",
        element: <Vehicle />,
    },
    {
        path: "/resident/visitor_access/add_vehicle",
        element: <AddVehicle />,
    },
    {
        path: "/resident/visitor_access/staff",
        element: <Staff />,
    },
    {
        path: "/resident/visitor_access/add_staff",
        element: <AddStaff />,
    },
    {
        path: "/resident/visitor_access/visitors",
        element: <Visitors />,
    },
    {
        path: "/resident/visitor_access/add_visitor",
        element: <AddVisitor />,
    },
    {
        path: "/resident/visitor_access/qr_code",
        element: <QRCodeScreen />,
    },
    {
        path: "/resident/visitor_access/digital_logs",
        element: <VisitationHistory />,
    },
    {
        path: "/resident/visitor_access/view_digital_log/:visitLogId",
        element: <VisitDigitalLog />

    },
    {
        path: "/resident/settings",
        element: <Setting />,
    },

    {
        path: "/resident/contract_management/contracts",
        element: <Contracts />

    },
    {
        path: "/resident/value_added_services/services",
        element: <Services />,
    },
    {
        path: "/resident/value_added_services/invoices",
        element: <VasInvoices />,
    },
    {
        path: "/resident/approvals/my_approvals",
        element: <Approvals />,
    },
    {
        path: "/resident/approvals/view_approval_ticket/:ticketId",
        element: <ViewApprovalTicket />,
    },
    {
        path: "/resident/approvals/minimal_approval/:ticketId",
        element: <MinimalApprovalPage />,
    },
    {
        path: "*",
        element: <Error404Page />,
    },
    {
        path: "/resident/leases/my_leases",
        element: <ViewLeases />,
    },
    {
        path: "/resident/water/my_lease_invoices",
        element: <ViewLeaseInvoices />,
    },
    {
        path: "/resident/water_meters/my_meters",
        element: <ViewWaterMeters />,
    },
    {
        path: "/resident/water_meter_invoices/my_water_invoices",
        element: <ViewWaterInvoices />,
    },
    {
        path: "/resident/water_meters/invoice_page",
        element: <ViewWaterInvoicePage />,
    },
    {
        path: "/resident/water_meters/my_meter",
        element: <ViewWaterMeter />,
    },
    {
        path: "/resident/value_added_services/invoice_details/invoice_details/:invoiceId",
        element: <ViewVasInvoices />,
    },
    {
        path: "/resident/statements/receipts/invoice_receipt/:receiptId",
        element: <PaymentReceipt />,
    },
]);
// PDFGenerator.js
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const generateServiceRequestPDF = async (serviceRequest, serviceName) => {
    try {
        // Create a temporary div element to render the content
        const element = document.createElement('div');
        element.style.width = '595px'; // A4 width in pixels at 72 dpi
        element.style.fontFamily = 'Arial, sans-serif';
        element.style.padding = '40px';
        element.style.position = 'absolute';
        element.style.left = '-9999px';

        // Format date
        const formatDate = (dateString) => {
            if (!dateString) return 'N/A';
            const date = new Date(dateString);
            return date.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            });
        };

        // Format time
        const formatTime = (timeString) => {
            if (!timeString) return 'N/A';
            const [hours, minutes] = timeString.split(':');
            const hour = parseInt(hours, 10);
            const ampm = hour >= 12 ? 'PM' : 'AM';
            const hour12 = hour % 12 || 12;
            return `${hour12}:${minutes} ${ampm}`;
        };

        // Format currency
        const formatCurrency = (amount) => {
            if (!amount && amount !== 0) return 'N/A';
            return `KSH ${amount.toLocaleString()}`;
        };

        // Add content to the temporary div
        element.innerHTML = `
      <div style="text-align: center; margin-bottom: 20px;">
        <h2 style="color: #344767;">Service Request Confirmation</h2>
        <p style="color: #6c757d;">Request ID: ${serviceRequest._id}</p>
      </div>
      
      <div style="margin-bottom: 30px; border-bottom: 1px solid #eee; padding-bottom: 20px;">
        <h3 style="color: #344767; margin-bottom: 15px;">Service Details</h3>
        <table style="width: 100%; border-collapse: collapse;">
          <tr>
            <td style="padding: 8px; color: #6c757d; width: 40%;">Service Name:</td>
            <td style="padding: 8px; font-weight: 500;">${serviceName || 'Service not found'}</td>
          </tr>
          <tr>
            <td style="padding: 8px; color: #6c757d;">Status:</td>
            <td style="padding: 8px; font-weight: 500;">
              <span style="color: #2196f3; background-color: #e3f2fd; padding: 4px 8px; border-radius: 15px; display: inline-block; text-align: center; min-width: 100px; font-weight: bold;">
                In Progress
              </span>
            </td>
          </tr>
          <tr>
            <td style="padding: 8px; color: #6c757d;">Requested On:</td>
            <td style="padding: 8px; font-weight: 500;">${formatDate(serviceRequest.createdAt)}</td>
          </tr>
          <tr>
            <td style="padding: 8px; color: #6c757d;">Scheduled Date:</td>
            <td style="padding: 8px; font-weight: 500;">${serviceRequest.date ? formatDate(serviceRequest.date) : 'N/A'}</td>
          </tr>
          <tr>
            <td style="padding: 8px; color: #6c757d;">Scheduled Time:</td>
            <td style="padding: 8px; font-weight: 500;">${serviceRequest.time ? formatTime(serviceRequest.time) : 'N/A'}</td>
          </tr>
          ${serviceRequest.unit ? `
          <tr>
            <td style="padding: 8px; color: #6c757d;">Unit:</td>
            <td style="padding: 8px; font-weight: 500;">${serviceRequest.unit}</td>
          </tr>
          ` : ''}
          ${serviceRequest.isCommissionSelected && serviceRequest.percentage ? `
          <tr>
            <td style="padding: 8px; color: #6c757d;">Commission:</td>
            <td style="padding: 8px; font-weight: 500;">${serviceRequest.percentage}%</td>
          </tr>
          ` : ''}
          <tr>
            <td style="padding: 8px; color: #6c757d;">Amount:</td>
            <td style="padding: 8px; font-weight: 500;">${formatCurrency(serviceRequest.amount)}</td>
          </tr>
        </table>
      </div>
      
      <div style="margin-bottom: 30px;">
        <p style="color: #6c757d; font-size: 14px;">
          This service request has been approved and is now in progress. A work order has been assigned and an invoice has been created.
          For any questions or concerns, please contact our support team.
        </p>
      </div>
      
      <div style="text-align: center; font-size: 12px; color: #6c757d; margin-top: 40px;">
        <p>Generated on ${new Date().toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        })}</p>
      </div>
    `;

        // Append the element to the body
        document.body.appendChild(element);

        // Generate canvas from the div
        const canvas = await html2canvas(element, {
            scale: 2, // Higher scale for better quality
            useCORS: true,
            logging: false
        });

        // Remove the temporary element
        document.body.removeChild(element);

        // Create PDF
        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: 'a4'
        });

        // Add canvas as image to PDF
        const imgData = canvas.toDataURL('image/png');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

        // Save the PDF
        pdf.save(`Service_Request_${serviceRequest._id}.pdf`);

        return true;
    } catch (error) {
        console.error('Error generating PDF:', error);
        return false;
    }
};
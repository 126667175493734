
import React, { useState, useEffect } from 'react'
import { Button } from 'primereact/button';
import { Link, useNavigate } from "react-router-dom";
import 'primereact/resources/themes/lara-light-blue/theme.css';
import { toastify } from '../../../utils/toast'
import { Dialog } from 'primereact/dialog';
import Layout from '../component/layout'
import { addVisitorURL, residentUnitsURL } from '../../../utils/urls'
import { filterObjectsByAllProperties } from '../../../utils/filterSearch';
import { getItem } from '../../../utils/localStorage';
import { makeRequest2 } from '../../../utils/makeRequest'
function AddVisitor() {

    const navigate = useNavigate()
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [idNumber, setIdNumber] = useState('');
    const [visitingDate, setVisitingDate] = useState('');
    // const [visitingTime, setVisitingTime] = useState('');
    const [unit, setUnit] = useState('');
    const [email, setEmail] = useState('');
    const [visitorType, setVisitorType] = useState('')
    const [invitationCode, setInvitationCode] = useState(false);
    const [units, setUnits] = useState([])

    const HandleAddVisitor = async () => {
        try {
            if (firstName === '') {
                throw new Error('First Name is required')
            }
            else if (lastName === '') {
                throw new Error('Last Name is required')
            }
            else if (phoneNumber === '') {
                throw new Error('Phone Number is required')
            }
            else if (!/^(07\d{8}|254\d{9})$/.test(phoneNumber)) {
                throw new Error('Phone number must be in the format "0712345678" or "254712345678".');
              }

            else {
                const CustomerId = await getItem('selectedCustomerId');
                const FacilityId = await getItem('selectedFacilityId');
                const value = await getItem('RESIDENTUSER');
                const userData = JSON.parse(value)
                let id;
                let userType
                if (userData.user.role === 'family') {
                    id = userData.user.userId
                    userType = 'family'
                }
                if (userData.user.role === 'admin') {
                    id = CustomerId
                    userType = 'admin'
                }
                
                const response = await makeRequest2(addVisitorURL + '/' + id + "/" + FacilityId, 'POST', {
                    firstName, lastName, phoneNumber, visitingDate, unit, invitationCode, userType
                })
                if (response.success) {
                    toastify('Visitor added successfully', 'success')
                    navigate('/resident/visitor_access/visitors')

                }
                else {
                    throw new Error(response.error)
                }
            }
        }
        catch (err) {
            toastify(err.message, 'error')
        }

    }
    // const fetchResidentUnits = async () => {
    //     try {
    //         const CustomerId = await getItem('selectedCustomerId');
    //         const FacilityId = await getItem('selectedFacilityId');

    //         const response = await makeRequest2(residentUnitsURL + '/' + CustomerId + '/' + FacilityId, 'GET', {})
    //         if (response.success) {
    //             setUnits(response.data)
    //             setUnit(response.data[0]._id)
    //         }
    //         else {
    //             throw new Error(response.error)
    //         }
    //     }
    //     catch (err) {
    //         toastify(err.message, 'error')
    //     }
    // }
    const fetchResidentUnits = async () => {
        try {
            const CustomerId = await getItem("selectedCustomerId");
            const FacilityId = await getItem("selectedFacilityId");
    
            const response = await makeRequest2(`${residentUnitsURL}/${CustomerId}/${FacilityId}`, "GET", {});
            if (response.success) {
                setUnits(response.data);
                if (response.data.length > 0) {
                    setUnit(response.data[0]._id); // Set the first unit as default
                } else {
                    throw new Error("No units found for the resident.");
                }
            } else {
                throw new Error(response.error);
            }
        } catch (err) {
            toastify(err.message, "error");
        }
    };
    

    useEffect(() => {

        fetchResidentUnits()


    }, [])


    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" label="CSV" rounded onClick={() => { }} data-pr-tooltip="CSV" />
            <Button type="button" label="XLS" severity="success" rounded onClick={() => { }} data-pr-tooltip="XLS" />
            <Button type="button" label="PDF" severity="warning" rounded onClick={() => { }} data-pr-tooltip="PDF" />
        </div>
    );
    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/resident/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Visitor Access</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/resident/visitor_access/visitors"}>Visitors</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Add Visitor</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <Link to="/resident/visitor_access/visitors">
                                <i className="ti ti-arrow-left"></i>
                                &nbsp;
                                Back
                            </Link>

                        </div>

                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label className="form-label">First Name<span style={{ color: 'red' }}>*</span></label>
                                        <input type="text" className="form-control" placeholder='First Name' value={firstName} onChange={(e) => {
                                            setFirstName(e.target.value)
                                        }} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label className="form-label">Last Name<span style={{ color: 'red' }}>*</span></label>
                                        <input type="text" className="form-control" placeholder='Last Name' value={lastName} onChange={(e) => {
                                            setLastName(e.target.value)
                                        }} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label className="form-label">Phone Number<span style={{ color: 'red' }}>*</span></label>
                                        <input type="number" className="form-control" placeholder='e.g 254711223344' value={phoneNumber} onChange={(e) => {
                                            setPhoneNumber(e.target.value)
                                        }} />
                                    </div>
                                </div>


                            </div>
                            <div className="row">
                                {/* <div className="col-md-4">
                                    <div className="mb-3">
                                        <label className="form-label">ID Number / Passport Number<span style={{ color: 'red' }}>*</span></label>
                                        <input type="text" className="form-control" value={idNumber} onChange={(e) => {
                                            setIdNumber(e.target.value)
                                        }} />
                                    </div>
                                </div> */}
                                {/* <div className="col-md-4">
                                    <div className="mb-3">
                                        <label>Visitor Type<span style={{ color: 'red' }}>*</span></label>
                                        <select
                                            className="form-control mt-2"
                                            value={visitorType}
                                            onChange={(e) => setVisitorType(e.target.value)}
                                        >
                                            <option value="">-- Select --</option>
                                            <option value="Staff">Staff</option>
                                            <option value="Service Providers">Delivery Personnel / Cab Drivers / Garbage Collector</option>
                                            <option value="Government Officials/Agents">Government Officials / Agents</option>
                                            <option value="Student">Student</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                </div> */}


                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label className="form-label">Visiting Date</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={visitingDate}
                                            onChange={(e) => setVisitingDate(e.target.value)}
                                            min={new Date().toISOString().split("T")[0]} // Set min to today's date
                                        />
                                    </div>
                                </div>

                                {/* <div className="col-md-4">
                                    <div className="mb-3">
                                        <label className="form-label">Visiting Time</label>
                                        <input type="time" className="form-control" value={visitingTime} onChange={(e) => {
                                            setVisitingTime(e.target.value)
                                        }} />
                                    </div>
                                </div> */}


                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <input type="checkbox" value={invitationCode} checked={invitationCode} onChange={(e) => {
                                            setInvitationCode(!invitationCode)
                                        }} /> &nbsp;
                                        <label className="form-label">Send Invitation Code</label>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="card-footer text-end btn-page">

                            <div className="btn btn-primary" onClick={HandleAddVisitor}>Submit</div>
                        </div>
                    </div>
                </div>
            </div>

        </Layout>
    )
}


export default AddVisitor
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { io } from 'socket.io-client';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const PaymentModal = ({
    visible,
    onHide,
    accountNumber,
    facilityId,
    customerId,
    amount,
    onSuccess,
    onError
}) => {
    const API_BASE_URL = "https://sandbox.payments.payserve.co.ke";
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(null);
    const [socket, setSocket] = useState(null);
    const [paymentFormData, setPaymentFormData] = useState({
        phone: '',
        amount: amount || ''
    });

    const uniqueIdentifier = useRef(uuidv4());

    const setupSocketListeners = useCallback((socket) => {
        socket.on('connect', () => {
            socket.emit('register', {
                uniqueIdentifier: uniqueIdentifier.current
            });
        });

        socket.on('stkPushInitiated', (data) => {
            setLoading(false);
            setStatus({
                status: 'pending',
                data: {
                    message: data.message,
                    phone: data.phone,
                    amount: data.amount
                }
            });
            toast.current.show({
                severity: 'info',
                summary: 'Payment Initiated',
                detail: data.message
            });
        });

        socket.on('transactionStatus', (data) => {
            setLoading(false);
            setStatus(data);

            if (data.status === 'success') {
                toast.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: data.data.message
                });
                if (onSuccess) {
                    onSuccess(data);
                }
                setTimeout(() => {
                    onHide();
                }, 3000);
            } else if (data.status === 'cancelled' || data.status === 'failed') {
                toast.current.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: data.data.message
                });
                if (onError) {
                    onError(data);
                }
                setLoading(false);
            }
        });
    }, [onHide, onSuccess, onError]);

    useEffect(() => {
        let currentSocket = null;

        if (visible) {
            currentSocket = io(API_BASE_URL, {
                transports: ['websocket'],
                upgrade: false,
                withCredentials: true,
                reconnectionAttempts: 3,
                reconnectionDelay: 1000,
                timeout: 10000
            });

            setupSocketListeners(currentSocket);
            setSocket(currentSocket);
        }

        return () => {
            if (currentSocket) {
                currentSocket.disconnect();
            }
        };
    }, [visible, setupSocketListeners]);

    useEffect(() => {
        setPaymentFormData(prev => ({
            ...prev,
            amount: amount
        }));
    }, [amount]);

    const handlePaymentInputChange = (e) => {
        const { name, value } = e.target;
        setPaymentFormData(prev => ({
            ...prev,
            [name]: value
        }));
        if (status?.status === 'cancelled' || status?.status === 'failed') {
            setStatus(null);
        }
    };

    const formatPhoneNumber = (phone) => {
        // Remove all non-digit characters
        let cleaned = phone.replace(/\D/g, '');

        // Ensure the phone number starts with '254' for Kenya or '0' and replace it
        if (cleaned.startsWith('0')) {
            cleaned = '254' + cleaned.substring(1);
        } else if (!cleaned.startsWith('254')) {
            cleaned = '254' + cleaned; // Add country code if missing
        }

        return cleaned;
    };


    const validatePhoneNumber = (phone) => {
        return /^254\d{9}$/.test(phone);
    };

    const handlePaymentSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            if (!facilityId || !customerId || !accountNumber) {
                throw new Error('Missing required payment details');
            }

            const formattedPhone = formatPhoneNumber(paymentFormData.phone);
            console.log(formattedPhone);

            if (!validatePhoneNumber(formattedPhone)) {
                throw new Error('Please enter a valid Safaricom number (e.g., 0712345678 or 254712345678)');
            }

            const payload = {
                phone: formattedPhone,
                amount: parseInt(paymentFormData.amount),
                accountNumber,
                facilityId,
                customerId,
                uniqueIdentifier: uniqueIdentifier.current
            };

            console.log(payload);

            const response = await axios({
                method: 'post',
                url: `${API_BASE_URL}/v1/stkpush`,
                data: payload,
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            });

            if (!response.data.success) {
                throw new Error(response.data.error || 'Failed to initiate payment');
            }
        } catch (error) {
            const errorMessage = error.response?.data?.error || error.message || 'Payment request failed. Please try again.';
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: errorMessage
            });
            if (onError) {
                onError(error);
            }
            setLoading(false);
        }
    };

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={visible}
                onHide={onHide}
                header="Make Payment"
                modal
                className="p-fluid"
            >
                {status && (
                    <div className={`mb-4 p-3 rounded ${status.status === 'success' ? 'bg-green-100 text-green-700' :
                        status.status === 'pending' ? 'bg-yellow-100 text-yellow-700' :
                            status.status === 'cancelled' ? 'bg-orange-100 text-orange-700' :
                                'bg-red-100 text-red-700'
                        }`}>
                        <p className="font-medium">{status.data.message}</p>
                        {(status.status === 'pending' || status.status === 'success') && (
                            <p className="text-sm mt-2">
                                Amount: KES {status.data.amount}<br />
                                Phone: {status.data.phone}
                                {status.data.receiptNumber && (
                                    <><br />Receipt: {status.data.receiptNumber}</>
                                )}
                            </p>
                        )}
                    </div>
                )}

                <form onSubmit={handlePaymentSubmit}>
                    <div className="form-group mb-4">
                        <label htmlFor="phone">Mobile Number</label>
                        <input
                            type="tel"
                            className="form-control"
                            id="phone"
                            name="phone"
                            value={paymentFormData.phone}
                            onChange={handlePaymentInputChange}
                            placeholder="e.g., 0712345678"
                            disabled={loading || status?.status === 'pending'}
                            required
                        />
                        <small className="text-gray-600">
                            Enter your Safaricom M-PESA number starting with 0 or 254 (e.g., 0712345678)
                        </small>
                    </div>

                    <div className="form-group mb-4">
                        <label htmlFor="amount">Amount (KSH)</label>
                        <input
                            type="number"
                            className="form-control"
                            id="amount"
                            name="amount"
                            value={paymentFormData.amount}
                            onChange={handlePaymentInputChange}
                            disabled={loading || status?.status === 'pending'}
                            required
                        />
                    </div>

                    <div className="text-end">
                        <Button
                            label={loading ? 'Processing...' :
                                status?.status === 'pending' ? 'Waiting for payment...' :
                                    status?.status === 'cancelled' || status?.status === 'failed' ? 'Try again' :
                                        'Pay Now'}
                            icon="pi pi-check"
                            type="submit"
                            disabled={loading || status?.status === 'pending'}
                            className="btn btn-primary"
                        />
                    </div>
                </form>
            </Dialog>
        </>
    );
};

export default PaymentModal;
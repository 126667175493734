import React, { useState, useEffect, useRef } from 'react';
import Layout from '../../component/layout';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useNavigate, Link } from 'react-router-dom';
import { toastify } from '../../../../utils/toast';
import { makeRequest2 } from '../../../../utils/makeRequest';
import { getResidentWaterInvoicesURL } from '../../../../utils/urls';
import { getItem } from '../../../../utils/localStorage';

const ViewWaterInvoices = () => {
    const navigate = useNavigate();
    const [invoices, setInvoices] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 5,
        page: 0,
    });
    const dt = useRef(null);

    const fetchInvoices = async () => {
        try {
            setLoading(true);
            const facilityId = await getItem('selectedFacilityId');
            const customerId = await getItem('selectedCustomerId');
            const skip = lazyParams.first;
            const limit = lazyParams.rows;
            const queryParams = `limit=${limit}&skip=${skip}`;

            const response = await makeRequest2(
                `${getResidentWaterInvoicesURL}/${facilityId}/${customerId}?${queryParams}`,
                'GET'
            );
            if (response.success) {
                // Get invoices from response.data.invoices
                const invoicesData = response.data?.invoices || [];
                setInvoices(invoicesData);
                // Use summary.totalInvoices for the total record count
                setTotalRecords(response.data?.summary?.totalInvoices || 0);
            } else {
                toastify('Failed to fetch water invoices', 'error');
            }
        } catch (error) {
            console.error('Error fetching invoices:', error);
            toastify('Error loading water invoices', 'error');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchInvoices();
    }, [lazyParams]);

    const onPage = (event) => {
        setLazyParams(event);
    };

    const statusBodyTemplate = (rowData) => {
        const statusColors = {
            'Pending': 'bg-info',
            'Paid': 'bg-success',
            'Partially Paid': 'bg-warning',
            'Cancelled': 'bg-secondary',
            'Overdue': 'bg-danger',
            'Unpaid': 'bg-danger'
        };
        return (
            <span className={`badge ${statusColors[rowData.status] || 'bg-secondary'}`}>
                {rowData.status || 'unknown'}
            </span>
        );
    };

    const formatNumber = (value) => {
        return new Intl.NumberFormat('en-KE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value);
    };

    const invoiceAmountBodyTemplate = (rowData) => {
        return formatNumber(rowData.charges?.totalMonthlyBill || 0);
    };

    const amountPaidBodyTemplate = (rowData) => {
        return formatNumber(rowData.amountPaid || 0);
    };

    const balanceBodyTemplate = (rowData) => {
        const balance = (rowData.charges?.totalMonthlyBill || 0) - (rowData.amountPaid || 0);
        return formatNumber(balance);
    };

    const dateBodyTemplate = (rowData, field) => {
        return new Date(rowData[field]).toLocaleDateString();
    };

    const customerUnitBodyTemplate = (rowData) => {
        const customerName = rowData.CustomerInfo ? rowData.CustomerInfo.fullName : 'N/A';
        const unitName = rowData.unitName || 'N/A';
        return (
            <div>
                <div className="fw-bold">{customerName}</div>
                <div className="small text-muted">{unitName}</div>
            </div>
        );
    };

    const handleView = (invoice) => {
        // Log the details
        console.log("Navigating to InvoicePage with details:", {
            invoiceId: invoice._id,
            invoiceNumber: invoice.invoiceNumber,
        });
    
        navigate("/resident/water_meters/invoice_page", {
            state: {
                invoiceId: invoice._id,
                invoiceNumber: invoice.invoiceNumber,
            }
        });
    };
    
    // Filter invoices based on the search term
    const filteredInvoices = searchTerm
        ? invoices.filter((invoice) =>
            Object.values(invoice).some((value) =>
                String(value).toLowerCase().includes(searchTerm.toLowerCase())
            ) ||
            (invoice.CustomerInfo &&
                invoice.CustomerInfo.fullName?.toLowerCase().includes(searchTerm.toLowerCase()))
        )
        : invoices;

    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/resident/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/resident/"}>Utility</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Utility Invoices</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h5>Invoices</h5>
                        </div>
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search invoices..."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="table-responsive">
                                <DataTable
                                    ref={dt}
                                    value={filteredInvoices}
                                    emptyMessage="No water invoices found."
                                    sortMode="multiple"
                                    paginator
                                    lazy
                                    first={lazyParams.first}
                                    rows={lazyParams.rows}
                                    totalRecords={totalRecords}
                                    onPage={onPage}
                                    loading={loading}
                                    stripedRows
                                    tableStyle={{ minWidth: '50rem' }}
                                >
                                    <Column field="invoiceNumber" header="Inv No" sortable />
                                    <Column
                                        field="dateIssued"
                                        header="Invoice Date"
                                        body={(rowData) => dateBodyTemplate(rowData, 'dateIssued')}
                                        sortable
                                    />
                                    <Column
                                        field="CustomerInfo.fullName"
                                        header="Customer / Unit"
                                        body={customerUnitBodyTemplate}
                                        sortable
                                    />
                                    <Column
                                        header="Invoice (KES)"
                                        body={invoiceAmountBodyTemplate}
                                        sortable
                                    />
                                    <Column
                                        field="amountPaid"
                                        header="Paid Amount (KES)"
                                        body={amountPaidBodyTemplate}
                                        sortable
                                    />
                                    <Column
                                        header="Balance (KES)"
                                        body={balanceBodyTemplate}
                                        sortable
                                    />
                                    <Column field="status" header="Status" body={statusBodyTemplate} sortable />
                                    <Column
                                        field="dueDate"
                                        header="Due Date"
                                        body={(rowData) => dateBodyTemplate(rowData, 'dueDate')}
                                        sortable
                                    />
                                    <Column field="yearMonth" header="Month" sortable />
                                    <Column
                                        header="Actions"
                                        body={(rowData) => (
                                            <Button
                                                icon="ti ti-eye"
                                                className="p-button-rounded p-button-text"
                                                onClick={() => handleView(rowData)}
                                                tooltip="View Invoice"
                                            />
                                        )}
                                    />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default ViewWaterInvoices;

import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import styles from './invoice.module.css';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { makeRequest2 } from '../../../../utils/makeRequest';
import {
  getVasInvoice,
  getCompanyInformationURL,
  fetchFacilityPaymentDetails
} from '../../../../utils/urls';
import { getItem } from '../../../../utils/localStorage';
import Layout from '../../../resident/component/layout';
import PaymentModal from '../../../../utils/paymentModal';
import { toastify } from '../../../../utils/toast';

const VasInvoicePage = () => {
  // State management
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [companyDetails, setCompanyDetails] = useState(null);
  const [paymentSettings, setPaymentSettings] = useState(null);
  const [facilityLogo, setFacilityLogo] = useState("");
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showReceiptsDialog, setShowReceiptsDialog] = useState(false);

  // Hooks
  const { invoiceId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useRef(null);
  const isMounted = useRef(true);

  // Fetch facility logo
  const getFacilityLogo = async () => {
    try {
      const facilityFromState = location.state?.facility;

      if (facilityFromState?.logo) {
        const fullLogoUrl = `${process.env.REACT_APP_BASE_URL}/${facilityFromState.logo}`;
        localStorage.setItem("selectedFacilityLogo", fullLogoUrl);
        setFacilityLogo(fullLogoUrl);
      } else {
        const storedLogo = localStorage.getItem("selectedFacilityLogo");
        if (storedLogo) {
          setFacilityLogo(storedLogo);
        } else {
          setFacilityLogo(""); // Set empty string if no logo exists
        }
      }
    } catch (error) {
      console.error("Error fetching facility logo:", error);
      setFacilityLogo(""); // Set empty string on error
    }
  };

  // Map API response to a consistent invoice object structure
  const mapInvoiceData = useCallback((data, facilityId) => {
    if (!data) return null;

    // Extract customer name from different possible locations
    const customerName =
      data.customerInfo?.fullName ||
      data.fullName ||
      (data.client?.firstName && `${data.client?.firstName} ${data.client?.lastName}`) ||
      'Customer';

    // Get currency information
    const currencySymbol = data.currency?.symbol || 'KSh';
    const currencyCode = data.currency?.code || 'KES';

    // Get amounts, handling different formats
    const amount = parseFloat(data.amount || data.totalAmount || 0);
    const subTotal = parseFloat(data.subTotal || 0);
    const tax = parseFloat(data.tax || 0);
    const amountPaid = parseFloat(data.amountPaid || 0);
    const penalty = parseFloat(data.penalty || 0);

    // Fix unit display issues
    const unitName = typeof data.unit === 'string'
      ? data.unit
      : data.unit?.name || 'N/A';

    return {
      _id: data._id,
      invoiceNumber: data.invoiceNumber || 'N/A',
      accountNumber: data.accountNumber || 'N/A',
      issueDate: data.issueDate || data.createdAt || new Date(),
      dueDate: data.dueDate || new Date(),
      status: data.status || 'Pending',
      unit: { name: unitName },
      totalAmount: amount,
      subTotal: subTotal,
      tax: tax,
      penalty: penalty,
      amountPaid: amountPaid,
      balance: amount - amountPaid,
      serviceName: data.serviceName || 'Service Fee',
      invoiceNote: data.invoiceNote || "Payment is due within 30 days",
      currency: {
        symbol: currencySymbol,
        code: currencyCode
      },
      items: data.items || [
        {
          description: data.serviceName || 'Service Fee',
          quantity: 1,
          unitPrice: subTotal
        }
      ],
      customerInfo: {
        fullName: customerName,
        id: data.customerInfo?.id || data.customerId
      },
      paymentDetails: data.paymentDetails || {
        paymentStatus: data.status || 'Pending',
        paymentMethod: '',
        mobilePayment: false
      },
      facility: data.facility || { id: facilityId, name: companyDetails?.name },
      client: data.client || null,
      reconciliationHistory: data.reconciliationHistory || [],
      whatFor: data.whatFor || { invoiceType: 'Value Added Service' }
    };
  }, [companyDetails]);

  // Fetch invoice data
  const fetchInvoiceData = useCallback(async (currentFacilityId) => {
    if (!currentFacilityId || !invoiceId) return;

    try {
      const response = await makeRequest2(
        `${getVasInvoice}/${currentFacilityId}/${invoiceId}`,
        "GET"
      );

      if (response.success && isMounted.current) {
        const invoiceData = response.data.invoice || response.data;
        setInvoice(mapInvoiceData(invoiceData, currentFacilityId));
      } else {
        toastify(response.message || "Failed to fetch invoice details", 'error');
      }
    } catch (err) {
      toastify(err.message, 'error');
    }
  }, [invoiceId, mapInvoiceData]);

  // Fetch company details
  const fetchCompanyDetails = useCallback(async (currentFacilityId) => {
    if (!currentFacilityId) return;

    try {
      const response = await makeRequest2(
        `${getCompanyInformationURL}/${currentFacilityId}`,
        "GET"
      );

      if (response.success && isMounted.current) {
        setCompanyDetails(response.data);
      } else {
        toastify("Failed to fetch company details", 'error');
      }
    } catch (err) {
      toastify(err.message, 'error');
    }
  }, []);

  // Fetch payment settings
  const fetchPaymentSettings = useCallback(async (currentFacilityId) => {
    if (!currentFacilityId) return;

    try {
      const response = await makeRequest2(
        `${fetchFacilityPaymentDetails}/${currentFacilityId}`,
        "GET"
      );

      if (response.success && isMounted.current) {
        setPaymentSettings(response.data.settings);
      }
    } catch (error) {
      console.error("Error fetching payment settings:", error);
    }
  }, []);

  useEffect(() => {
    const initializeData = async () => {
      try {
        const currentFacilityId = await getItem('selectedFacilityId');
        if (!currentFacilityId) {
          toastify("Facility ID not found", 'error');
          return;
        }

        setLoading(true);
        try {
          await Promise.all([
            fetchInvoiceData(currentFacilityId),
            fetchCompanyDetails(currentFacilityId),
            fetchPaymentSettings(currentFacilityId),
            getFacilityLogo()
          ]);
        } catch (error) {
          toastify("Error fetching data", 'error');
        } finally {
          if (isMounted.current) {
            setLoading(false);
          }
        }
      } catch (err) {
        toastify("Error retrieving data", 'error');
        setLoading(false);
      }
    };

    isMounted.current = true;
    initializeData();

    return () => {
      isMounted.current = false;
    };
  }, [fetchInvoiceData, fetchCompanyDetails, fetchPaymentSettings]);

  // Handle PDF download
  const handleDownloadPDF = async () => {
    const element = document.getElementById("printable-invoice");
    const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
    const filename = `Invoice_${invoice?.invoiceNumber}_${timestamp}.pdf`;

    try {
      setLoading(true);
      toast.current.show({
        severity: "info",
        summary: "Processing",
        detail: "Generating PDF...",
      });

      const canvas = await html2canvas(element, {
        scale: 2,
        useCORS: true,
        logging: false,
        backgroundColor: "#ffffff",
      });

      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      const imgWidth = 210;
      const pageHeight = 297;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let position = 0;

      // Add first page
      pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight, "", "FAST");

      // Add additional pages if needed
      let heightLeft = imgHeight - pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight, "", "FAST");
        heightLeft -= pageHeight;
      }

      pdf.save(filename);

      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "PDF downloaded successfully!",
      });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to generate PDF. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  // Handle payment success
  const handlePaymentSuccess = async () => {
    try {
      const currentFacilityId = await getItem('selectedFacilityId');
      await fetchInvoiceData(currentFacilityId);
      setShowPaymentModal(false);
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Payment processed successfully!'
      });
    } catch (error) {
      console.error('Error refreshing invoice data:', error);
    }
  };

  // Handle payment error
  const handlePaymentError = (error) => {
    console.error('Payment Error Details:', error);
    toast.current.show({
      severity: 'error',
      summary: 'Error',
      detail: error.response?.data?.message || 'Payment processing failed. Please try again.'
    });
    setShowPaymentModal(false);
  };

  // Helper functions
  const formatDate = (date) => {
    if (!date) return '';
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const formatAmount = (amount) => {
    if (amount === undefined || amount === null) return '0.00';
    const currencyCode = invoice?.currency?.code || 'KES';
    return `${currencyCode} ${parseFloat(amount).toFixed(2)}`;
  };

  // Calculate balance
  const calculateBalance = () => {
    if (!invoice) return 0;
    return (invoice.totalAmount || 0) - (invoice.amountPaid || 0);
  };

  // Check if payment is available
  const isPaymentAvailable = () => {
    return calculateBalance() > 0 && (invoice?.currency?.code === 'KES');
  };

  // Get payment method details
  const getPaymentMethodInfo = () => {
    // Check if invoice has payment details with format "Module - ShortCode"
    if (invoice?.paymentDetails?.paymentMethod && invoice.paymentDetails.paymentMethod.includes(" - ")) {
      const [module, shortCode] = invoice.paymentDetails.paymentMethod.split(" - ");
      return {
        module,
        shortCode
      };
    }

    return {
      module: paymentSettings?.bankName || 'Mpesa',
      shortCode: paymentSettings?.accountNumber || '---'
    };
  };

  // View receipt function for the receipts dialog
  const viewReceipt = (paymentData) => {
    navigate(`/vas/statements/receipts/invoice_receipt/${paymentData._id}`, {
      state: {
        receipt: paymentData,
        invoice: invoice,
        companyDetails: companyDetails
      }
    });
  };

  // Action template for the data table in the receipts dialog
  const actionBodyTemplate = (rowData) => {
    const isMpesa = rowData.type && rowData.type.toUpperCase() === 'MPESA';
    return (
      <Button
        icon="fas fa-eye"
        className="p-button-sm p-button-text"
        onClick={() => viewReceipt(rowData)}
        disabled={!isMpesa}
        tooltip={isMpesa ? "View Receipt" : "Receipt not available"}
        tooltipOptions={{ position: 'left' }}
      />
    );
  };

  // Get payment info
  const paymentInfo = getPaymentMethodInfo();

  // Render loading state
  if (loading && !invoice) {
    return (
      <Layout>
        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <span className="ms-2">Loading invoice details...</span>
        </div>
      </Layout>
    );
  }

  if (!invoice) {
    return (
      <Layout>
        <div className={styles.pageContainer}>
          <div className="text-center">
            <h3>Error loading invoice</h3>
            <p>Invoice data could not be found</p>
            <Button
              label="Go Back"
              onClick={() => navigate(-1)}
              className="mt-3 p-button-primary"
            />
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
              <li className="breadcrumb-item">
                  <Link to="/dashboard/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/vas/invoices">Value Added Services</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="#">View Invoice</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.pageContainer}>
        <Toast ref={toast} />

        {/* Action Buttons */}
        <div className={styles.actionCard}>
          <div className={styles.actionCardLeft}>
            <Link to="#" onClick={() => navigate(-1)} className={styles.backLink}>
              <i className="ti ti-arrow-narrow-left"></i>
              <span>Back</span>
            </Link>
          </div>
          <div className={styles.actionCardRight}>
            <Button
              label="Receipts"
              onClick={() => setShowReceiptsDialog(true)}
              className="mr-2 btn btn-primary"
              style={{ marginRight: '10px' }}
              disabled={!invoice.reconciliationHistory || invoice.reconciliationHistory.length === 0}
            />
            <Button
              label="Download Invoice"
              onClick={handleDownloadPDF}
              className="mr-2 btn btn-primary"
              style={{ marginRight: '10px' }}
              disabled={loading}
            />
            {isPaymentAvailable() && (
              <Button
                label="Pay Now"
                onClick={() => setShowPaymentModal(true)}
                className="mr-2 btn btn-success"
                disabled={loading}
              />
            )}
          </div>
        </div>

        {/* Payment Modal */}
        <PaymentModal
          visible={showPaymentModal}
          onHide={() => setShowPaymentModal(false)}
          accountNumber={invoice?.accountNumber}
          facilityId={invoice?.facility?.id}
          customerId={invoice?.customerInfo?.id}
          amount={calculateBalance()}
          onSuccess={handlePaymentSuccess}
          onError={handlePaymentError}
        />

        {/* Receipts Dialog */}
        <Dialog
          header="Payment Receipts"
          visible={showReceiptsDialog}
          style={{ width: '50vw' }}
          onHide={() => setShowReceiptsDialog(false)}
          resizable={false}
        >
          <DataTable
            value={invoice?.reconciliationHistory || []}
            responsiveLayout="scroll"
            emptyMessage="No payment records found"
            className="p-datatable-sm"
            paginator
            rows={5}
            rowsPerPageOptions={[5, 10, 20]}
          >
            <Column
              field="date"
              header="Date"
              body={(rowData) => formatDate(rowData.date)}
              sortable
            />
            <Column
              field="amount"
              header="Amount"
              body={(rowData) => formatAmount(rowData.amount)}
              sortable
            />
            <Column
              field="type"
              header="Type"
              sortable
            />
            <Column
              field="paymentReference"
              header="Reference"
              sortable
            />
            <Column
              field="paymentCompletion"
              header="Status"
              sortable
              body={(rowData) => (
                <span className={`badge ${rowData.paymentCompletion === 'Completed' ? 'bg-success' :
                  rowData.paymentCompletion === 'Partial' ? 'bg-warning' :
                    rowData.paymentCompletion === 'Overpaid' ? 'bg-info' : 'bg-secondary'
                  }`}>
                  {rowData.paymentCompletion}
                </span>
              )}
            />
            <Column
              body={actionBodyTemplate}
              exportable={false}
              style={{ width: '4rem' }}
            />
          </DataTable>
        </Dialog>

        {/* Invoice Content */}
        <div className={styles.invoiceContainer}>
          <div id="printable-invoice" className={styles.invoiceContent}>
            <div
              className={styles.statusBanner}
              data-status={invoice?.status?.toLowerCase()}
            >
              <span>{invoice?.status}</span>
            </div>

            <div className={styles.invoiceHeader}>
              <div className={styles.invoiceHeaderContent}>
                {/* Invoice title now above the logo */}
                <div className={styles.headerLeft}>
                  <h1 className={styles.invoiceTitleInHeader} style={{ marginTop: "-20px", marginLeft: "60%", color: "white", fontSize: "28px", fontWeight: "600" }}>INVOICE</h1>
                  {facilityLogo && (
                    <div className={styles.logoContainer} style={{ marginTop: "0", marginLeft: "60%" }}>
                      <img
                        src={facilityLogo}
                        alt="Company Logo"
                        className={styles.companyLogo}
                      />
                    </div>
                  )}
                </div>
                <div className={styles.headerDetails}>
                  <div className={styles.detailRow}>
                    <span className={styles.label}>INVOICE NO.</span>
                    <span className={styles.value}>{invoice?.invoiceNumber}</span>
                  </div>
                  <div className={styles.detailRow}>
                    <span className={styles.label}>ACCOUNT NO.</span>
                    <span className={styles.value}>{invoice?.accountNumber}</span>
                  </div>
                  <div className={styles.detailRow}>
                    <span className={styles.label}>INVOICE DATE</span>
                    <span className={styles.value}>
                      {formatDate(invoice?.issueDate)}
                    </span>
                  </div>
                  <div className={styles.detailRow}>
                    <span className={styles.label}>DUE DATE</span>
                    <span className={styles.value}>
                      {formatDate(invoice?.dueDate)}
                    </span>
                  </div>
                  {invoice?.whatFor?.invoiceType && (
                    <div className={styles.detailRow}>
                      <span className={styles.label}>TYPE</span>
                      <span className={styles.value}>{invoice?.whatFor?.invoiceType}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className={styles.invoiceBody}>
              <div className={styles.addressSection}>
                <div className={styles.addressLayout}>
                  <div className={styles.fromSection}>
                    <h2 className={styles.sectionTitle}>FROM</h2>
                    <div className={styles.addressContent}>
                      <div>{invoice?.facility?.name || companyDetails?.name || 'Company Name'}</div>
                      <div>{companyDetails?.address || 'Company Address'}</div>
                      <div>{companyDetails?.country || 'Country'}</div>
                    </div>
                  </div>
                  <div className={styles.billToSection}>
                    <h2 className={styles.sectionTitle}>BILL TO</h2>
                    <div className={styles.addressContent}>
                      <div>{invoice.customerInfo.fullName}</div>
                      <div>Unit: {invoice.unit.name}</div>
                    </div>
                  </div>
                  <div className={styles.totalSection}>
                    <h2 className={styles.sectionTitle}>TOTAL DUE</h2>
                    <div className={`${styles.totalAmount} ${calculateBalance() < 0 ? styles.creditAmount : ''}`}>
                      {calculateBalance() < 0
                        ? formatAmount(Math.abs(calculateBalance()))
                        : formatAmount(calculateBalance())}
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.itemsGrid}>
                <div className={styles.gridHeader}>
                  <span className={styles.columnHeader}>DESCRIPTION</span>
                  <span className={`${styles.columnHeader} ${styles.right}`}>UNIT PRICE</span>
                  <span className={`${styles.columnHeader} ${styles.right}`}>QTY</span>
                  <span className={`${styles.columnHeader} ${styles.right}`}>AMOUNT</span>
                </div>

                {invoice.items && invoice.items.map((item, index) => (
                  <div key={index} className={styles.gridRow}>
                    <span className={styles.value}>{item.description}</span>
                    <span className={`${styles.value} ${styles.right}`}>
                      {formatAmount(item.unitPrice)}
                    </span>
                    <span className={`${styles.value} ${styles.right}`}>
                      {item.quantity}
                    </span>
                    <span className={`${styles.value} ${styles.right}`}>
                      {formatAmount(item.unitPrice * item.quantity)}
                    </span>
                  </div>
                ))}

                <div className={styles.calculations}>
                  <div className={styles.calculationsGrid}>
                    {/* Invoice Calculation Section */}
                    <div className={styles.calculationsRow}>
                      <span className={styles.calcLabel}>SUBTOTAL</span>
                      <span className={styles.calcValue}>
                        {formatAmount(invoice?.subTotal)}
                      </span>
                    </div>
                    <div className={styles.calculationsRow}>
                      <span className={styles.calcLabel}>
                        TAX {invoice.tax > 0 ? `(${((invoice.tax / invoice.subTotal) * 100).toFixed(0)}%)` : '(0%)'}
                      </span>
                      <span className={styles.calcValue}>
                        {formatAmount(invoice?.tax)}
                      </span>
                    </div>
                    {(invoice?.penalty || 0) > 0 && (
                      <div className={styles.calculationsRow}>
                        <span className={styles.calcLabel}>PENALTY</span>
                        <span className={styles.calcValue}>
                          {formatAmount(invoice?.penalty)}
                        </span>
                      </div>
                    )}
                    <div className={styles.calculationsRow}>
                      <span className={styles.calcLabel}>INVOICE TOTAL</span>
                      <span className={styles.calcValue}>
                        {formatAmount(invoice?.totalAmount)}
                      </span>
                    </div>

                    {/* Divider */}
                    <div className={styles.divider}></div>

                    {/* Check if invoice has balance brought forward */}
                    {invoice?.balanceBroughtForward !== 0 && 
                     invoice?.balanceBroughtForward !== null && 
                     invoice?.balanceBroughtForward !== undefined && (
                      <div className={styles.calculationsRow}>
                        <span className={styles.calcLabel}>BALANCE B/FORWARD</span>
                        <span className={`${styles.calcValue} ${invoice?.balanceBroughtForward < 0 ? styles.creditAmount : styles.debitAmount}`}>
                          {invoice?.balanceBroughtForward < 0
                            ? '(' + formatAmount(Math.abs(invoice?.balanceBroughtForward)) + ')'
                            : formatAmount(invoice?.balanceBroughtForward)}
                        </span>
                      </div>
                    )}

                    {/* Total Balance - highlighted as an important row */}
                    <div className={`${styles.calculationsRow} ${styles.balance}`}>
                      <span className={styles.calcLabel}>TOTAL BALANCE</span>
                      <span className={styles.calcValue} style={{ fontWeight: "600" }}>
                        {formatAmount(invoice?.totalAmount + (invoice?.balanceBroughtForward || 0))}
                      </span>
                    </div>

                    {/* Divider */}
                    <div className={styles.divider}></div>

                    {/* Amount Paid */}
                    <div className={styles.calculationsRow}>
                      <span className={styles.calcLabel}>PAYMENT</span>
                      <span className={styles.calcValue}>
                        {formatAmount(invoice?.amountPaid)}
                      </span>
                    </div>

                    {/* Total Due - show in green if credit, red if debit */}
                    <div className={`${styles.calculationsRow} ${styles.balance}`}>
                      <span className={styles.calcLabel}>TOTAL DUE</span>
                      <span className={`${styles.calcValue} ${calculateBalance() < 0 ? styles.creditAmount : styles.debitAmount}`}>
                        {calculateBalance() < 0
                          ? '(' + formatAmount(Math.abs(calculateBalance())) + ')'
                          : formatAmount(calculateBalance())}
                      </span>
                    </div>
                  </div>

                  <div className={styles.totalRow}>
                    <span className={styles.totalLabel}>TOTAL DUE</span>
                    <span className={`${styles.totalValue} ${calculateBalance() < 0 ? styles.creditAmount : styles.debitAmount}`}>
                      {calculateBalance() < 0
                        ? '(' + formatAmount(Math.abs(calculateBalance())) + ')'
                        : formatAmount(calculateBalance())}
                    </span>
                  </div>
                </div>
              </div>

              <div className={styles.footer} style={{ backgroundColor: '#f0f0f0' }}>
                <div className="row">
                  <div className="col-sm-6 mt-2 px-5">
                    {/* Payment Details on Left Margin */}
                    <div className={styles.paymentDetails}>
                      <h6 className={styles.detailsTitle}>Payment Details:</h6>
                      <h6 className={styles.detailsText}>Bank: {paymentInfo.module}</h6>
                      <h6 className={styles.detailsText}>
                        Paybill: {paymentInfo.shortCode}
                      </h6>
                    </div>
                  </div>

                  <div className="col-sm-6">
                    {/* Signature Placeholder on Right Margin */}
                    <div className={styles.signatureSection}>
                      <div className={styles.signatureBox}>
                        <p style={{ textAlign: 'center', marginTop: '20px' }}>___________________________</p>
                        <p style={{ textAlign: 'center' }}>Authorized Signature</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 pt-2">
                    <div className={styles.centeredText}>
                      <p style={{ textAlign: 'center' }}>
                        {paymentInfo.shortCode ? 
                          `To complete your payment, kindly use the paybill number ${paymentInfo.shortCode}, followed by the account number ${invoice?.accountNumber}.` :
                          invoice.invoiceNote || "Payment is due within 30 days of invoice date."}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default VasInvoicePage;
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { clearStorage, getItem } from "../../../utils/localStorage";
import { makeRequest } from "../../../utils/makeRequest";
import { toastify } from "../../../utils/toast";
import { checkTokenExpirationURL } from "../../../utils/urls";
import Footer from "./footer";
import Header from "./header";
import Nav from "./nav";

function Layout({ children }) {
  const navigate = useNavigate();
  const checkUserExistInLocalStorage = async () => {
    const userExist = await getItem("RESIDENTUSER");

    if (userExist === null || userExist === undefined) {
      toastify("You have been logged out, Kindly login", "error");
      navigate("/");
    }
  };
  const checkJwtExpiration = async () => {
    try {
      const userDetailsValue = await getItem("RESIDENTUSER");
      const userDetails = JSON.parse(userDetailsValue);
      const token = userDetails.authToken;
      const response = await makeRequest(checkTokenExpirationURL, "POST", {
        token: token,
      });

      if (response.success) {
        if (response.success.data) {
          await clearStorage();
          navigate("/");
        }
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  useEffect(() => {
    checkUserExistInLocalStorage();
    checkJwtExpiration();
  });
  return (
    <>
      <Nav />
      <Header />
      <div className="pc-container">
        <div className="pc-content">{children}</div>
      </div>

      <Footer />
    </>
  );
}
export default Layout;

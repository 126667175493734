import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { makeRequest2 } from '../../../../utils/makeRequest';
import { analog_base_url } from "../../../../utils/urls";
import { toastify } from '../../../../utils/toast';
import Layout from '../../component/layout';
import { useFacilityStore } from '../../../../app/z-store/store';
import { getItem } from '../../../../utils/localStorage';

function ViewWaterMeter() {
    const selectedFacilityId = useFacilityStore((state) => state.facilityId);
    const navigate = useNavigate();
    const location = useLocation();
    const { meterId: paramMeterId } = useParams();
    
    const [meterId, setMeterId] = useState(null);
    const [meterNumber, setMeterNumber] = useState(null);

    const [meterDetails, setMeterDetails] = useState(null);
    const [readings, setReadings] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedDate, setSelectedDate] = useState('');
    const [activeSection, setActiveSection] = useState('meter-info');

    

    const fetchMeterDetails = async () => {
        try {
            setLoading(true);
            const meterId = await getItem('selectedMeterId')
            console.log("METERID",meterId)
            const response = await makeRequest2(
                `${analog_base_url}/details/${selectedFacilityId}/${meterId}`,
                'GET'
            );
            console.log("responSE", response)

            if (response.success) {
                const meterData = response.data.meter;
                setMeterDetails(meterData);

                if (meterData.readingHistory && meterData.readingHistory.length > 0) {
                    const formattedReadings = meterData.readingHistory.map((reading, index) => ({
                        id: index + 1,
                        date: new Date(reading.readingDate).toISOString().split('T')[0],
                        reading: reading.currentReading.toFixed(2),
                        previous: reading.previousReading.toFixed(2),
                        recordedBy: reading.readBy || 'Property Manager'
                    }));
                    setReadings(formattedReadings.reverse());
                }
            } else {
                toastify('Failed to fetch meter details', 'error');
            }
        } catch (error) {
            console.error('Error fetching meter details:', error);
            toastify('Error fetching meter details', 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleDateChange = (value) => {
        setSelectedDate(value);
    };

    const handleFilter = () => {
        const filteredReadings = meterDetails?.readingHistory?.filter(reading => {
            const readingDate = new Date(reading.readingDate).toISOString().split('T')[0];
            return readingDate <= selectedDate;
        }) || [];

        const formattedReadings = filteredReadings.map((reading, index) => ({
            id: index + 1,
            date: new Date(reading.readingDate).toISOString().split('T')[0],
            reading: reading.currentReading.toFixed(2),
            previous: reading.previousReading.toFixed(2),
            recordedBy: reading.readBy || 'System'
        }));

        setReadings(formattedReadings.reverse());
    };

    useEffect(() => {
        const initializeMeterDetails = async () => {
            try {
                // Priority order for getting meter ID
                const stateMeterId = location.state?.meterId;
                const urlMeterId = paramMeterId;
    
                let finalMeterId = null;
                let finalMeterNumber = null;
    
                if (stateMeterId) {
                    // First priority: state passed from navigation
                    finalMeterId = stateMeterId;
                    finalMeterNumber = location.state?.meterNumber;
                } else if (urlMeterId) {
                    // Second priority: URL parameter
                    finalMeterId = urlMeterId;
                } else {
                    // Last resort: check localStorage
                    const storedMeterId = await getItem('selectedMeterId');
                    const storedMeterNumber = await getItem('selectedMeterNumber');
                    
                    finalMeterId = storedMeterId;
                    finalMeterNumber = storedMeterNumber;
                }
    
                // Log for debugging
                console.log('Final Meter ID:', finalMeterId);
                console.log('Final Meter Number:', finalMeterNumber);
    
                if (!finalMeterId) {
                    toastify('No meter ID provided', 'error');
                    navigate('/resident/water_meters/my_meters');
                    return;
                }
    
                // Set states
                setMeterId(finalMeterId);
                setMeterNumber(finalMeterNumber);
    
                const today = new Date().toISOString().split('T')[0];
                setSelectedDate(today);
    
                fetchMeterDetails();
            } catch (error) {
                console.error('Error initializing meter details:', error);
                toastify('Error initializing meter details', 'error');
                navigate('/resident/water_meters/my_meters');
            }
        };
    
        initializeMeterDetails();
    }, [location.state, paramMeterId, navigate]);

    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb mb-3">
                                <li className="breadcrumb-item">
                                    <Link to="/facility">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to="/facility/utility_management/meter_management/water_meters">Analog Meters Management</Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    <span>Water Meters</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <div>
                                <Link
                                    to="/resident/water_meters/my_meters"
                                    className="btn btn-sm btn-primary"
                                    state={{ activeTab: "analog-meters" }}
                                >
                                    <i className="ti ti-arrow-narrow-left"></i> Back
                                </Link>
                                <span className="ms-3 h5 mb-0">Analog Water Meter Details</span>
                            </div>
                        </div>
                    </div>

                    {/* Horizontal Tabs */}
                    <div className="card">
                        <div className="card-body py-0">
                            <ul className="nav nav-tabs profile-tabs">
                                <li className="nav-item">
                                    <button
                                        className={`nav-link ${activeSection === 'meter-info' ? 'active' : ''}`}
                                        onClick={() => setActiveSection('meter-info')}
                                        type="button"
                                    >
                                        <i className="ti ti-dashboard me-2"></i>Meter Info
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button
                                        className={`nav-link ${activeSection === 'readings' ? 'active' : ''}`}
                                        onClick={() => setActiveSection('readings')}
                                        type="button"
                                    >
                                        <i className="ti ti-list-numbers me-2"></i>Reading History
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button
                                        className={`nav-link ${activeSection === 'transactions' ? 'active' : ''}`}
                                        onClick={() => setActiveSection('transactions')}
                                        type="button"
                                    >
                                        <i className="ti ti-cash me-2"></i>Transactions
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button
                                        className={`nav-link ${activeSection === 'consumption' ? 'active' : ''}`}
                                        onClick={() => setActiveSection('consumption')}
                                        type="button"
                                    >
                                        <i className="ti ti-list-numbers me-2"></i>Consumption History
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>

                    {loading ? (
                        <div className="card">
                            <div className="card-body text-center py-5">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                <p className="mt-3">Loading meter information...</p>
                            </div>
                        </div>
                    ) : (
                        <div className="tab-content mt-4">
                            {/* Meter Information Section */}
                            {activeSection === 'meter-info' && (
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <td className="fw-bold">Serial Number:</td>
                                                            <td>{meterDetails?.meterNumber || 'N/A'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-bold">Account Number:</td>
                                                            <td>{meterDetails?.accountNumber || 'N/A'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-bold">Installation Date:</td>
                                                            <td>{meterDetails?.createdAt
                                                                ? new Date(meterDetails.createdAt).toLocaleDateString()
                                                                : 'N/A'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-bold">Status:</td>
                                                            <td>
                                                                <span className={`badge ${meterDetails?.status === 'open' ? 'bg-success' :
                                                                    meterDetails?.status === 'maintenance' ? 'bg-warning' :
                                                                        'bg-danger'}`}>
                                                                    {meterDetails?.status || 'N/A'}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="col-md-6">
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <td className="fw-bold">Unit:</td>
                                                            <td>{meterDetails?.UnitInfo
                                                                ? (meterDetails.UnitInfo.name || meterDetails.UnitInfo.unitNumber)
                                                                : 'N/A'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-bold">Initial Reading:</td>
                                                            <td>{meterDetails?.initialReading?.toFixed(2) || '0.00'} m³</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-bold">Previous Reading:</td>
                                                            <td>{meterDetails?.previousReading?.toFixed(2) || '0.00'} m³</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-bold">Current Reading:</td>
                                                            <td>{meterDetails?.currentReading?.toFixed(2) || '0.00'} m³ (
                                                                {meterDetails?.lastReadingDate
                                                                    ? new Date(meterDetails.lastReadingDate).toLocaleDateString()
                                                                    : 'N/A'})
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* Reading History Section */}
                            {activeSection === 'readings' && (
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row mb-3">
                                            <div className="col-md-4">
                                                <div className="input-group">
                                                    <span className="input-group-text">Date</span>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={selectedDate}
                                                        onChange={(e) => handleDateChange(e.target.value)}
                                                    />
                                                    <button
                                                        className="btn btn-primary"
                                                        type="button"
                                                        onClick={handleFilter}
                                                    >
                                                        Filter
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Date</th>
                                                        <th>Reading (m³)</th>
                                                        <th>Recorded By</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {readings.length > 0 ? (
                                                        readings.map((reading, index) => (
                                                            <tr key={reading.id || index}>
                                                                <td>{index + 1}</td>
                                                                <td>{reading.date}</td>
                                                                <td>{reading.reading}</td>
                                                                <td>{reading.recordedBy}</td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="5" className="text-center">No reading history found</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* Transactions Section */}
                            {activeSection === 'transactions' && (
                                <div className="card">
                                    <div className="card-body">
                                        {transactions.length > 0 ? (
                                            <div className="table-responsive">
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>Transaction ID</th>
                                                            <th>Date</th>
                                                            <th>Amount (KES)</th>
                                                            <th>Type</th>
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {transactions.map((transaction, index) => (
                                                            <tr key={transaction.id || index}>
                                                                <td>{transaction.id}</td>
                                                                <td>{transaction.date}</td>
                                                                <td>{transaction.amount}</td>
                                                                <td>{transaction.type}</td>
                                                                <td>
                                                                    <span className={`badge ${transaction.status === 'Completed' ? 'bg-success' : 'bg-warning'}`}>
                                                                        {transaction.status}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : (
                                            <div className="text-center py-4">
                                                <p>No transaction records found for this meter.</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                            {/* Consumption History Section */}
                            {activeSection === 'consumption' && (
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row mb-3">
                                            <div className="col-md-4">
                                                <div className="input-group">
                                                    <span className="input-group-text">Date</span>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={selectedDate}
                                                        onChange={(e) => handleDateChange(e.target.value)}
                                                    />
                                                    <button
                                                        className="btn btn-primary"
                                                        type="button"
                                                        onClick={handleFilter}
                                                    >
                                                        Filter
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Date</th>
                                                        <th>Consumption (m³)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {meterDetails?.readingHistory && meterDetails.readingHistory.length > 0 ? (
                                                        meterDetails.readingHistory
                                                            .sort((a, b) => new Date(b.readingDate) - new Date(a.readingDate))
                                                            .map((reading, index) => (
                                                                <tr key={reading._id || index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{new Date(reading.readingDate).toLocaleDateString()}</td>
                                                                    <td>{reading.consumption.toFixed(2)}</td>
                                                                </tr>
                                                            ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="6" className="text-center">No consumption history found</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default ViewWaterMeter;
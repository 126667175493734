
import React, { useState, useEffect } from 'react'
import { Button } from 'primereact/button';
import { Link, useNavigate } from "react-router-dom";
import 'primereact/resources/themes/lara-light-blue/theme.css';
import { toastify } from '../../../utils/toast'
import { Dialog } from 'primereact/dialog';
import Layout from '../component/layout'

import { makeRequest2 } from '../../../utils/makeRequest';
import { getItem } from '../../../utils/localStorage';
import { addStaffURL, residentUnitsURL } from '../../../utils/urls'
function AddStaff() {

    const navigate = useNavigate()
    
    const [unit, setUnit] = useState([])
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [jobRole, setJobRole] = useState('')
    const [visitorQRCode, setVisitorQRCode] = useState(false)

    const generateUniqueCode = () => {
        return Math.floor(10000 + Math.random() * 90000).toString(); // Generates a 5-digit unique number
    };

    const fetchResidentUnits = async () => {
        try {
            const CustomerId = await getItem('selectedCustomerId');
            const FacilityId = await getItem('selectedFacilityId');

            const response = await makeRequest2(residentUnitsURL + '/' + CustomerId + '/' + FacilityId, 'GET', {})
            if (response.success) {

                setUnit(response.data[0]._id)
            }
            else {
                throw new Error(response.error)
            }
        }
        catch (err) {
            
        }
    }


    const HandleSubmit = async () => {
        try {

            if (name === '') {
                throw new Error('Name is required.')

            }
            if (jobRole === '') {
                throw new Error('Job Role is required.')
            }
            else if (phoneNumber === '') {
                throw new Error('Phone number is required.');
            }
            else if (!/^(07\d{8}|254\d{9})$/.test(phoneNumber)) {
                throw new Error('Please enter a valid phone number with 10 digits, such as "0712345678" or "254712345678"');
            }
            else if (!/^(07\d{8}|254\d{9})$/.test(phoneNumber)) {
                throw new Error('Phone number must be in the format "0712345678" or "254712345678".');
              }
            const CustomerId = await getItem('selectedCustomerId');
            const uniqueCode = generateUniqueCode();


            const response = await makeRequest2(addStaffURL + '/' + CustomerId, 'POST', {
                name, phoneNumber, jobRole,visitorQRCode, uniqueCode, unit
            })
           
            if (response.success) {

                toastify('Staff member added successfully', 'success')
                navigate('/resident/visitor_access/staff')
            }
            else {
                throw new Error(response.error)
            }

        }
        catch (err) {
            console.log(err)
            toastify(err.message, 'error')
        }
    }

    useEffect(()=>{
        fetchResidentUnits()
    },[])



    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" label="CSV" rounded onClick={() => { }} data-pr-tooltip="CSV" />
            <Button type="button" label="XLS" severity="success" rounded onClick={() => { }} data-pr-tooltip="XLS" />
            <Button type="button" label="PDF" severity="warning" rounded onClick={() => { }} data-pr-tooltip="PDF" />
        </div>
    );
    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/resident/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                  <Link to={"#"}>Visitor Access</Link>
                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/resident/visitor_access/staff"}>Staff</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Add Staff</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <Link to="/resident/visitor_access/staff">
                                <i className="ti ti-arrow-left"></i>
                                &nbsp;
                                Back
                            </Link>

                        </div>

                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label className="form-label">Full Name</label>
                                        <input type="text" className="form-control" value={name} placeholder='Full Name' onChange={(e)=>{
                                            setName(e.target.value)
                                        }}/>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label className="form-label">Job Role</label>
                                        <input type="text" className="form-control" value={jobRole} placeholder='Job Role' onChange={(e)=>{
                                            setJobRole(e.target.value)
                                        }} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label className="form-label">Phone Number</label>
                                        <input type="text" placeholder='e.g 254711223344' className="form-control"  value={phoneNumber} onChange={(e)=>{
                                            setPhoneNumber(e.target.value)
                                        }}/>
                                    </div>
                                </div>
                                
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <input type="checkbox" placeholder="name"
                                            value={visitorQRCode}
                                            checked={visitorQRCode}
                                            onChange={(e) => {

                                                setVisitorQRCode(!visitorQRCode)

                                            }}
                                        ></input>
                                        &nbsp;&nbsp;
                                        <label>Generate Access QR Code</label>
                                    </div>

                                </div>


                            </div>
                        </div>
                        <div className="card-footer text-end btn-page">

                            <div className="btn btn-primary" onClick={() => {
                                HandleSubmit()
                            }}>Submit</div>
                        </div>
                    </div>
                </div>
            </div>

        </Layout>
    )
}


export default AddStaff
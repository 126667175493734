export function addDaysToDate(days, date) {
    // Convert the input date to a Date object
    const givenDate = new Date(date);
    
    // Add the specified number of days to the date
    givenDate.setDate(givenDate.getDate() + days);
    
    // Format the date as YYYY-MM-DD
    const year = givenDate.getFullYear();
    const month = String(givenDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(givenDate.getDate()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
}

import React, { useState, useEffect } from 'react';
import Layout from '../../component/layout';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useNavigate, Link } from 'react-router-dom';
import { toastify } from '../../../../utils/toast';
import { makeRequest2 } from '../../../../utils/makeRequest';
import { getResidentWaterMetersURL } from '../../../../utils/urls';
import { getItem, setItem } from '../../../../utils/localStorage';

const ViewWaterMeters = () => {
    const navigate = useNavigate();
    const [meters, setMeters] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);

    // Fetch meters for the selected facility and customer
    const fetchMeters = async () => {
        try {
            setLoading(true);
            const facilityId = await getItem('selectedFacilityId');
            const customerId = await getItem('selectedCustomerId');
            const response = await makeRequest2(
                `${getResidentWaterMetersURL}/${facilityId}/${customerId}`,
                'GET',
                {}
            );
            if (response.success) {
                setMeters(response.data.meters);
            } else {
                toastify('Failed to fetch water meters', 'error');
            }
        } catch (err) {
            toastify(err.message, 'error');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchMeters();
    }, []);

    // Helper function to format the customer name from various possible fields
    const formatCustomerName = (customer) => {
        if (customer.firstName && customer.lastName) {
            return `${customer.firstName} ${customer.lastName}`;
        } else if (customer.fullName) {
            return customer.fullName;
        } else if (customer.name) {
            return customer.name;
        }
        return 'Unnamed Customer';
    };

    // Helper function to format meter reading values
    const formatReading = (value) => {
        return value ? `${Number(value).toFixed(2)} m³` : '0.00 m³';
    };

    // Template to display status with color-coded styling
    const statusTemplate = (rowData) => {
        const statusStyles = {
            maintenance: { color: '#ffc107', backgroundColor: '#fff8e1' },
            open: { color: '#28a745', backgroundColor: '#e9fbe9' },
            faulty: { color: '#dc3545', backgroundColor: '#fde9e9' },
            closed: { color: '#6c757d', backgroundColor: '#f1f1f1' }
        };

        const style = statusStyles[rowData.status] || { color: '#6c757d', backgroundColor: '#f1f1f1' };

        return (
            <span
                style={{
                    ...style,
                    padding: '0.5em 1em',
                    borderRadius: '12px',
                    fontWeight: 'bold',
                    fontSize: '0.9rem',
                    display: 'inline-block',
                }}
            >
                {rowData.status || 'unknown'}
            </span>
        );
    };

    // Action template: Clicking the eye button navigates to the meter details page with meter number
    const handleView = (meter) => {
        // Save the meter ID to local storage
        setItem('selectedMeterId', meter._id);

        navigate("/resident/water_meters/my_meter", { 
            state: { 
                meterNumber: meter.meterNumber,
                meterId: meter._id 
            } 
        });
    };

    // Filter the meters based on the search term
    const filteredMeters = meters.filter((meter) => {
        const searchLower = searchTerm.toLowerCase();
        return (
            meter.meterNumber.toLowerCase().includes(searchLower) ||
            (meter.UnitInfo?.name || '').toLowerCase().includes(searchLower) ||
            (meter.UnitInfo?.unitNumber || '').toLowerCase().includes(searchLower) ||
            formatCustomerName(meter.CustomerInfo).toLowerCase().includes(searchLower)
        );
    });

    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/resident/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/resident/"}>Utility</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Meters</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h5>Water Meters</h5>
                        </div>
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search meters..."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="table-responsive">
                                <DataTable
                                    value={filteredMeters}
                                    emptyMessage="No water meters found."
                                    sortMode="multiple"
                                    paginator
                                    rows={5}
                                    stripedRows
                                    loading={loading}
                                    tableStyle={{ minWidth: '50rem' }}
                                >
                                    <Column field="meterNumber" header="Meter S/N" sortable />
                                    <Column
                                        field="UnitInfo"
                                        header="Unit"
                                        sortable
                                        body={(rowData) =>
                                            rowData.UnitInfo
                                                ? rowData.UnitInfo.name || rowData.UnitInfo.unitNumber
                                                : 'N/A'
                                        }
                                    />
                                    <Column
                                        field="CustomerInfo"
                                        header="Customer"
                                        sortable
                                        body={(rowData) =>
                                            rowData.CustomerInfo
                                                ? formatCustomerName(rowData.CustomerInfo)
                                                : 'N/A'
                                        }
                                    />
                                    <Column
                                        field="initialReading"
                                        header="Initial Reading"
                                        sortable
                                        body={(rowData) => formatReading(rowData.initialReading)}
                                    />
                                    <Column
                                        field="previousReading"
                                        header="Previous Reading"
                                        sortable
                                        body={(rowData) => formatReading(rowData.previousReading)}
                                    />
                                    <Column
                                        field="currentReading"
                                        header="Current Reading"
                                        sortable
                                        body={(rowData) => formatReading(rowData.currentReading)}
                                    />
                                    <Column field="status" header="Status" body={statusTemplate} sortable />
                                    <Column
                                        header="Actions"
                                        body={(rowData) => (
                                            <Button
                                                icon="ti ti-eye"
                                                className="p-button-rounded p-button-text"
                                                onClick={() => handleView(rowData)}
                                                tooltip="View Meter"
                                            />
                                        )}
                                    />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default ViewWaterMeters;
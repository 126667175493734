import { useState, useEffect, useCallback } from 'react';
import { makeRequest2 } from '../../../../utils/makeRequest';
import { getItem } from '../../../../utils/localStorage';
import {
    getVasServices,
    addServiceRequest,
    getServiceRequests,
    getCustomerType,
    updateServiceRequest,
    assignWorkOrder,
    addServiceInvoice,
    residentUnitsURL
} from '../../../../utils/urls';
import { toastify } from '../../../../utils/toast';
import { generateServiceRequestPDF } from '../components/pdf_generator';

export const useServices = () => {
    const [selectedService, setSelectedService] = useState(null);
    const [services, setServices] = useState([]);
    const [serviceData, setServiceData] = useState([]);
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [customerId, setCustomerId] = useState('');
    const [facilityId, setFacilityId] = useState('');
    const [visible, setVisible] = useState(false);
    const [selectedServiceDetails, setSelectedServiceDetails] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [units, setUnits] = useState([]);
    const [selectedUnit, setSelectedUnit] = useState('');
    const [generatingPDF, setGeneratingPDF] = useState(false);

    const fetchServices = useCallback(async (facilityId, customerId) => {
        try {
            const url1 = `${getCustomerType}/${customerId}`;
            const customerTypeResponse = await makeRequest2(url1, 'GET', {});

            if (!customerTypeResponse.success) {
                toastify('Failed to fetch customer type', 'error');
                return;
            }

            const customerType = customerTypeResponse.data.data.customerType;
            const url2 = `${getVasServices}/${facilityId}`;
            const response = await makeRequest2(url2, 'GET', {});

            if (response.success) {
                const filteredServices = response.data.services.filter(service =>
                    service.appliesTo.includes(customerType)
                );

                const serviceOptions = filteredServices.map(service => ({
                    label: service.serviceName,
                    value: service._id
                }));

                setServices(serviceOptions);
            } else {
                toastify(response.error, 'error');
            }
        } catch (err) {
            toastify(err.message, 'error');
        }
    }, []);

    const fetchResidentUnits = async () => {
        try {
            const customerId = await getItem('selectedCustomerId');
            const facilityId = await getItem('selectedFacilityId');

            if (!customerId || !facilityId) {
                console.error('Missing customer or facility ID');
                return;
            }

            const response = await makeRequest2(
                `${residentUnitsURL}/${customerId}/${facilityId}`,
                'GET',
                {}
            );

            if (response.success && response.data) {
                setUnits(response.data);
            }
        } catch (err) {
            console.error('Error fetching units:', err);
            toastify(err.message, 'error');
        }
    };

    const fetchServiceRequests = useCallback(async (facilityId, customerId) => {
        try {
            const url = `${getServiceRequests}/${facilityId}/${customerId}`;
            const response = await makeRequest2(url, 'GET', {});

            if (response.success && response.data.success) {
                const serviceRequestsData = response.data.data.data || [];
                const processedData = serviceRequestsData.map(request => ({
                    ...request,
                    serviceName: request.serviceName === "Service not found"
                        ? getServiceName(request.serviceId)
                        : request.serviceName
                }));

                setServiceData(processedData);
            } else if (!response.success) {
                toastify('Failed to fetch service requests', 'error');
            }
        } catch (err) {
            toastify(err.message, 'error');
        }
    }, []);

    const handleApprove = async (serviceRequest) => {
        try {
            validateServiceRequest(serviceRequest);
            const { _id: serviceRequestId, serviceId, customerId, assigneeId, amount } = serviceRequest;

            await makeRequest2(
                `${updateServiceRequest}/${facilityId}`,
                'POST',
                { serviceRequestId, status: 'In Progress' }
            );

            const workOrderData = {
                facilityId,
                requesterId: customerId,
                description: `Work order for ${getServiceName(serviceId)}`,
                pricing: amount,
                assigneeId,
                type: 'unscheduled'
            };

            await makeRequest2(
                `${assignWorkOrder}/${facilityId}`,
                'POST',
                workOrderData
            );

            const invoiceData = {
                facilityId,
                serviceId: serviceRequest._id,
                customerId,
                amount,
                status: 'Pending'
            };

            await makeRequest2(
                `${addServiceInvoice}/${facilityId}`,
                'POST',
                invoiceData
            );

            toastify('Service request approved successfully', 'success');
            await fetchServiceRequests(facilityId, customerId);
            
            // Generate PDF after approval
            setGeneratingPDF(true);
            const serviceName = getServiceName(serviceId);
            const pdfSuccess = await generateServiceRequestPDF(serviceRequest, serviceName);
            
            if (pdfSuccess) {
                toastify('Service request PDF generated successfully', 'success');
            } else {
                toastify('Failed to generate PDF', 'warning');
            }
            
            setGeneratingPDF(false);
            closeModal();

        } catch (error) {
            setGeneratingPDF(false);
            if (error?.response?.status === 409) {
                toastify('Invoice already exists for this service request', 'warning');
                await fetchServiceRequests(facilityId, customerId);
                closeModal();
            } else {
                toastify(error.message || 'Error approving service request', 'error');
            }
        }
    };

    const validateServiceRequest = (serviceRequest) => {
        const requiredFields = ['_id', 'serviceId', 'customerId', 'assigneeId', 'amount'];
        const missingFields = requiredFields.filter(field => !serviceRequest[field]);

        if (missingFields.length > 0) {
            throw new Error(`Missing required fields: ${missingFields.join(', ')}`);
        }
    };

    const handleReject = async (serviceRequest) => {
        try {
            const url = `${updateServiceRequest}/${facilityId}`;
            await makeRequest2(url, 'POST', {
                serviceRequestId: serviceRequest._id,
                status: 'Cancelled'
            });

            toastify('Service request rejected', 'success');
            await fetchServiceRequests(facilityId, customerId);
            closeModal();
        } catch (error) {
            toastify('Error rejecting service request', 'error');
        }
    };

    useEffect(() => {
        const initializeData = async () => {
            try {
                const storedFacilityId = await getItem('selectedFacilityId');
                const storedCustomerId = await getItem('selectedCustomerId');

                if (storedFacilityId && storedCustomerId) {
                    setFacilityId(storedFacilityId);
                    setCustomerId(storedCustomerId);
                    await Promise.all([
                        fetchServices(storedFacilityId, storedCustomerId),
                        fetchServiceRequests(storedFacilityId, storedCustomerId),
                        fetchResidentUnits()
                    ]);
                } else {
                    toastify('Required data not found', 'error');
                }
            } catch (error) {
                toastify('Error initializing data', 'error');
            }
        };
        initializeData();
    }, [fetchServices, fetchServiceRequests]);

    const getServiceName = useCallback((serviceId) => {
        const service = services.find(service =>
            service.value.toString() === serviceId.toString()
        );
        return service ? service.label : 'Service not found';
    }, [services]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!selectedService || !selectedUnit) {
                toastify('Please select both service and unit', 'error');
                return;
            }

            const hasPendingRequest = serviceData.some(
                request => request.serviceId === selectedService &&
                    request.status.toLowerCase() === 'pending'
            );

            if (hasPendingRequest) {
                toastify('A pending request already exists for this service', 'error');
                return;
            }

            const selectedServiceLabel = services.find(
                (service) => service.value === selectedService
            )?.label;

            const isCommissionService = selectedServiceLabel?.toLowerCase() === 'commission';
            const isUnitManagementService = selectedServiceLabel?.toLowerCase() === 'unit management';

            const data = {
                facilityId,
                serviceId: selectedService,
                status: 'Pending',
                customerId,
                unit: selectedUnit
            };

            if (!isCommissionService && !isUnitManagementService) {
                if (!date || !time) {
                    toastify('Please fill in all required fields', 'error');
                    return;
                }
                data.date = date;
                data.time = time;
            }

            const url = `${addServiceRequest}/${facilityId}`;
            const response = await makeRequest2(url, 'POST', data);

            if (response.success) {
                toastify('Request added successfully', 'success');
                resetForm();
                await fetchServiceRequests(facilityId, customerId);
            } else {
                toastify(response.error || 'Failed to add request', 'error');
            }
        } catch (err) {
            toastify(err.message, 'error');
        }
    };

    const resetForm = () => {
        setSelectedService(null);
        setDate('');
        setTime('');
        setSelectedUnit('');
    };

    const openModal = (serviceDetails) => {
        setSelectedServiceDetails(serviceDetails);
        setVisible(true);
    };

    const closeModal = () => {
        setVisible(false);
        setSelectedServiceDetails(null);
    };

    return {
        selectedService,
        services,
        serviceData,
        date,
        time,
        visible,
        selectedServiceDetails,
        searchTerm,
        units,
        selectedUnit,
        generatingPDF,
        setSelectedService,
        setDate,
        setTime,
        setSearchTerm,
        setSelectedUnit,
        handleSubmit,
        getServiceName,
        openModal,
        closeModal,
        handleApprove,
        handleReject
    };
};
export const backend_url = "https://api.payserve.co.ke";
// export const backend_url = "https://api.sandbox.payserve.co.ke";
// export const backend_url = "http://localhost:3050";

// Authentication urls
export const checkEmailAndPasswordURL = "/api/auth/check_email_and_password";
export const loginURL = "/api/auth/login";
export const forgotPasswordURL = "/api/auth/forgot_password";
export const resetPasswordURL = "/api/auth/reset_password";
export const codeVerificationURL = "/api/auth/otp";
export const resendCodeURL = "/api/auth/resend_code";
export const verifyOTPURL = "/api/auth/verify_otp";
export const checkTokenExpirationURL = "/api/auth/check_jwt_expiration";
// End authentication urls

// Visitor Access  urls
export const residentUnitsURL = "/api/resident/dashboard/get_units";
export const residentFacilitiessURL = "/api/resident/dashboard/get_facilities";

export const addFamilyMemberURL =
  "/api/resident/visitor_access/family/add_family_member";
export const deleteFamilyMemberURL =
  "/api/resident/visitor_access/family/delete_family_member";
export const getFamilyMembersURL =
  "/api/resident/visitor_access/family/get_family_members";
export const handleFamilyMemberStatusURL =
  "/api/resident/visitor_access/family/handle_family_status";
export const updateFamilyMemberURL =
  "/api/resident/visitor_access/family/update_family_member";

export const addVehicleURL = "/api/resident/visitor_access/vehicle/add_vehicle";
export const handleVehicleStatusURL =
  "/api/resident/visitor_access/vehicle/handle_vehicle_status";
export const updatevehicleURL =
  "/api/resident/visitor_access/vehicles/update_vehicle";
export const deleteVehicleURL =
  "/api/resident/visitor_access/vehicle/delete_vehicle";
export const getVehiclesURL =
  "/api/resident/visitor_access/vehicle/get_vehicles";

export const addStaffURL = "/api/resident/visitor_access/staff/add_staff";
export const updateStaffURL =
  "/api/resident/visitor_access/staff/update_staff_member";
export const handleStaffStatusURL =
  "/api/resident/visitor_access/staff/handle_staff_status";
export const deleteStaffURL = "/api/resident/visitor_access/staff/delete_staff";
export const getStaffURL = "/api/resident/visitor_access/staff/get_staffs";

export const addVisitorURL = "/api/resident/visitor_access/visitor/add_visitor";
export const inviteVisitorURL =
  "/api/resident/visitor_access/visitor/invite_visitor";
export const updateVisitorURL =
  "/api/resident/visitor_access/visitor/update_visitor";
export const deleteVisitorURL =
  "/api/resident/visitor_access/visitor/delete_visitor";
export const getVisitorsURL =
  "/api/resident/visitor_access/visitor/get_visitors";

export const getVisitLogsURL =
  "/api/resident/visitor_access/visit_logs/get_visit_logs";
export const getVisitLogURL =
  "/api/resident/visitor_access/visit_logs/get_visit_log";
export const acceptVisitRequestURL =
  "/api/resident/visitor_access/visit_logs/accept_visit";
export const denyVisitRequestURL =
  "/api/resident/visitor_access/visit_logs/deny_visit";
// End visitor access urls

// Ticket urls
export const addTicketURL = "/api/resident/ticket_management/add_ticket";
export const getTicketsURL = "/api/resident/ticket_management/get_tickets";
export const getApprovalTicketsURL =
  "/api/resident/ticket_management/get_approval_tickets";
export const getApprovalTicketURL =
  "/api/resident/ticket_management/get_approval_ticket";
export const approveWorkOrderURL =
  "/api/resident/ticket_management/approve_work_order";
export const denyWorkOrderURL =
  "/api/resident/ticket_management/deny_work_order";
// End ticket urls

// Contract urls
export const getCustomerContractsURL =
  "/api/resident/contract_management/get_customer_contracts";
// End contracts urls

// Lease urls
export const getCustomerLeasesURL =
  "/api/resident/resident_leases/get_customer_leases";
export const getLeaseInvoicesURL =
  "/api/resident/resident_leases/get_customer_lease_invoices";
// End Lease urls

// Invoice urls
export const getResidentInvoicesURL =
  "/api/resident/resident_management/get_resident_invoice";
export const getInvoice = "/api/app/levy_management/get_invoice_by_id";
export const getLeaseInvoice =
  "/api/app/levy_management/invoices/get_single_lease_invoice";
export const getCustomerInvoicesURL =
  "/api/resident/invoice_management/get_customer_invoices";
export const getCompanyInformationURL =
  "/api/app/settings_management/get_company_information";
export const showLevySettings = "/api/app/levy_management/show_levy_settings";
export const get_lease = "/api/app/lease_management/lease_agreements/get_lease";
// End Invoice urls

// VAS urls
export const getVasServices =
  "/api/app/vas_management/get_value_added_services";
export const addServiceInvoice = "/api/app/vas_management/add_service_invoice";
export const getServiceInvoices =
  "/api/app/vas_management/get_service_invoices_by_customer";
export const addServiceRequest = "/api/app/vas_management/add_service_request";
export const getServiceRequests =
  "/api/app/vas_management/get_service_requests_by_customer";
export const getCustomerType = "/api/app/vas_management/get_customer_type";
export const assignWorkOrder = "/api/app/vas_management/assign_work_order";
export const updateServiceRequest =
  "/api/app/vas_management/update_service_request";
export const getVasInvoice = "/api/app/vas_management/get_vas_invoice_by_id";
// End VAS urls

// Utilities urls
export const getResidentWaterMetersURL = "/api/app/analog-meters/customer";
export const getResidentWaterInvoicesURL = "/api/app/water-invoices/customer";
export const getWaterInvoice = "/api/app/water-invoices/account";
export const getCustomerMeterInfoURL = "/api/resident/invoice_management/get_customer_invoices";
export const analog_base_url =  "/api/app/analog-meters";

// Facility urls
export const getFacilityURL = "/api/core/facility_management/get_facility";

//Facility payment details
export const fetchFacilityPaymentDetails = "/api/core/payment_details/get_facility_payment_details";


import 'primereact/resources/themes/lara-light-blue/theme.css';
import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../component/layout';
function ResidentWall() {

    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/resident/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/resident/resident_wall"}>Resident Wall</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            <div className="row">

                <div className="col-md-12">
                    <div className="chat-wrapper">
                        <div className="offcanvas-xxl offcanvas-start chat-offcanvas" tabindex="-1" id="offcanvas_User_list">
                            <div className="offcanvas-header">
                                <button className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#offcanvas_User_list" aria-label="Close"></button>
                            </div>

                        </div>
                        <div className="chat-content">
                            <div className="card mb-0">
                                <div className="card-header p-3">
                                    <div className="d-flex align-items-center">
                                        <ul className="list-inline me-auto mb-0">
                                            <li className="list-inline-item align-bottom">
                                                <a
                                                    href="#"
                                                    className="d-xxl-none avtar avtar-s btn-link-secondary"
                                                    data-bs-toggle="offcanvas"
                                                    data-bs-target="#offcanvas_User_list"
                                                >
                                                    <i className="ti ti-menu-2 f-18"></i>
                                                </a>
                                                <a
                                                    href="#"
                                                    className="d-none d-xxl-inline-flex avtar avtar-s btn-link-secondary"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#chat-user_list"
                                                >
                                                    <i className="ti ti-menu-2 f-18"></i>
                                                </a>
                                            </li>
                                            <li className="list-inline-item">
                                                <div className="d-flex align-items-center">
                                                    <div className="chat-avtar">
                                                        <img className="rounded-circle img-fluid wid-40" src="../assets/images/user/avatar-5.jpg" alt="User image" />
                                                        <i className="chat-badge bg-success"></i>
                                                    </div>
                                                    <div className="flex-grow-1 mx-3 d-none d-sm-inline-block">
                                                        <h6 className="mb-0">Jane</h6>
                                                        <span className="text-sm text-muted">Resident</span>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                        <ul className="list-inline ms-auto mb-0">

                                            <li className="list-inline-item">
                                                <a
                                                    href="#"
                                                    className="d-xxl-none avtar avtar-s btn-link-secondary"
                                                    data-bs-toggle="offcanvas"
                                                    data-bs-target="#offcanvas_User_info"
                                                >
                                                    <i className="ti ti-info-circle f-18"></i>
                                                </a>
                                                <a
                                                    href="#"
                                                    className="d-none d-xxl-inline-flex avtar avtar-s btn-link-secondary"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#chat-user_info"
                                                >
                                                    <i className="ti ti-info-circle f-18"></i>
                                                </a>
                                            </li>
                                            <li className="list-inline-item">
                                                <div className="dropdown">
                                                    <a
                                                        className="avtar avtar-s btn-link-secondary dropdown-toggle arrow-none"
                                                        href="#"
                                                        data-bs-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                    >
                                                        <i className="ti ti-dots f-18"></i>
                                                    </a>
                                                    <div className="dropdown-menu dropdown-menu-end">
                                                        <a className="dropdown-item" href="#">Name</a>
                                                        <a className="dropdown-item" href="#">Date</a>
                                                        <a className="dropdown-item" href="#">Ratting</a>
                                                        <a className="dropdown-item" href="#">Unread</a>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="scroll-block chat-message">
                                    <div className="card-body" style={{overflowY:'auto',height:'100%'}}>
                                        <div className="message-out">
                                            <div className="d-flex align-items-end flex-column">
                                                <p className="mb-1 text-muted"><small>9h ago</small></p>
                                                <div className="message d-flex align-items-end flex-column">
                                                    <div className="d-flex align-items-center mb-1 chat-msg">
                                                        <div className="flex-shrink-0">
                                                            <ul className="list-inline ms-auto mb-0 chat-msg-option">
                                                                <li className="list-inline-item">
                                                                    <div className="dropdown">
                                                                        <a
                                                                            className="avtar avtar-xs btn-link-secondary dropdown-toggle arrow-none"
                                                                            href="#"
                                                                            data-bs-toggle="dropdown"
                                                                            aria-haspopup="true"
                                                                            aria-expanded="false"
                                                                        >
                                                                            <i className="ti ti-dots-vertical f-18"></i>
                                                                        </a>
                                                                        <div className="dropdown-menu">
                                                                            <a className="dropdown-item" href="#"><i className="ti ti-arrow-back-up"></i> Reply</a>
                                                                            <a className="dropdown-item" href="#"><i className="ti ti-arrow-forward-up"></i> Forward</a>
                                                                            <a className="dropdown-item" href="#"><i className="ti ti-copy"></i> Copy</a>
                                                                            <a className="dropdown-item" href="#"><i className="ti ti-trash"></i> Delete</a>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li className="list-inline-item">
                                                                    <a href="#" className="avtar avtar-xs btn-link-secondary">
                                                                        <i className="ti ti-edit-circle f-18"></i>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="flex-grow-1 ms-3">
                                                            <div className="msg-content bg-primary">
                                                                <p className="mb-0">Hi...Henny!!</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center mb-1 chat-msg">
                                                        <div className="flex-shrink-0">
                                                            <ul className="list-inline ms-auto mb-0 chat-msg-option">
                                                                <li className="list-inline-item">
                                                                    <div className="dropdown">
                                                                        <a
                                                                            className="avtar avtar-xs btn-link-secondary dropdown-toggle arrow-none"
                                                                            href="#"
                                                                            data-bs-toggle="dropdown"
                                                                            aria-haspopup="true"
                                                                            aria-expanded="false"
                                                                        >
                                                                            <i className="ti ti-dots-vertical f-18"></i>
                                                                        </a>
                                                                        <div className="dropdown-menu">
                                                                            <a className="dropdown-item" href="#"><i className="ti ti-arrow-back-up"></i> Reply</a>
                                                                            <a className="dropdown-item" href="#"><i className="ti ti-arrow-forward-up"></i> Forward</a>
                                                                            <a className="dropdown-item" href="#"><i className="ti ti-copy"></i> Copy</a>
                                                                            <a className="dropdown-item" href="#"><i className="ti ti-trash"></i> Delete</a>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li className="list-inline-item">
                                                                    <a href="#" className="avtar avtar-xs btn-link-secondary">
                                                                        <i className="ti ti-edit-circle f-18"></i>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="flex-grow-1 ms-3">
                                                            <div className="msg-content bg-primary">
                                                                <p className="mb-0">How can i cap you today?</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="message-in">
                                            <div className="d-flex">
                                                <div className="flex-shrink-0">
                                                    <div className="chat-avtar">
                                                        <img className="rounded-circle img-fluid wid-40" src="../assets/images/user/avatar-3.jpg" alt="User image" />
                                                        <i className="chat-badge bg-success"></i>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1 mx-3">
                                                    <div className="d-flex align-items-start flex-column">
                                                        <p className="mb-1 text-muted">Agilulf Fuxg <small>11:23 AM</small></p>
                                                        <div className="message d-flex align-items-start flex-column">
                                                            <div className="d-flex align-items-center mb-1 chat-msg">
                                                                <div className="flex-grow-1 me-3">
                                                                    <div className="msg-content card mb-0">
                                                                        <p className="mb-0">Hey.. Bill</p>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-shrink-0">
                                                                    <ul className="list-inline ms-auto mb-0 chat-msg-option">
                                                                        <li className="list-inline-item">
                                                                            <div className="dropdown">
                                                                                <a
                                                                                    className="avtar avtar-xs btn-link-secondary dropdown-toggle arrow-none"
                                                                                    href="#"
                                                                                    data-bs-toggle="dropdown"
                                                                                    aria-haspopup="true"
                                                                                    aria-expanded="false"
                                                                                >
                                                                                    <i className="ti ti-dots-vertical f-18"></i>
                                                                                </a>
                                                                                <div className="dropdown-menu">
                                                                                    <a className="dropdown-item" href="#"><i className="ti ti-arrow-back-up"></i> Reply</a>
                                                                                    <a className="dropdown-item" href="#"><i className="ti ti-arrow-forward-up"></i> Forward</a>
                                                                                    <a className="dropdown-item" href="#"><i className="ti ti-copy"></i> Copy</a>
                                                                                    <a className="dropdown-item" href="#"><i className="ti ti-trash"></i> Delete</a>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                        <li className="list-inline-item">
                                                                            <a href="#" className="avtar avtar-xs btn-link-secondary">
                                                                                <i className="ti ti-edit-circle f-18"></i>
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-center mb-1 chat-msg">
                                                                <div className="flex-grow-1 me-3">
                                                                    <div className="msg-content card mb-0">
                                                                        <p className="mb-0">nice to meet you!</p>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-shrink-0">
                                                                    <ul className="list-inline ms-auto mb-0 chat-msg-option">
                                                                        <li className="list-inline-item">
                                                                            <div className="dropdown">
                                                                                <a
                                                                                    className="avtar avtar-xs btn-link-secondary dropdown-toggle arrow-none"
                                                                                    href="#"
                                                                                    data-bs-toggle="dropdown"
                                                                                    aria-haspopup="true"
                                                                                    aria-expanded="false"
                                                                                >
                                                                                    <i className="ti ti-dots-vertical f-18"></i>
                                                                                </a>
                                                                                <div className="dropdown-menu">
                                                                                    <a className="dropdown-item" href="#"><i className="ti ti-arrow-back-up"></i> Reply</a>
                                                                                    <a className="dropdown-item" href="#"><i className="ti ti-arrow-forward-up"></i> Forward</a>
                                                                                    <a className="dropdown-item" href="#"><i className="ti ti-copy"></i> Copy</a>
                                                                                    <a className="dropdown-item" href="#"><i className="ti ti-trash"></i> Delete</a>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                        <li className="list-inline-item">
                                                                            <a href="#" className="avtar avtar-xs btn-link-secondary">
                                                                                <i className="ti ti-edit-circle f-18"></i>
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="message-out">
                                            <div className="d-flex align-items-end flex-column">
                                                <p className="mb-1 text-muted"><small>9h ago</small></p>
                                                <div className="message d-flex align-items-end flex-column">
                                                    <div className="d-flex align-items-center mb-1 chat-msg">
                                                        <div className="flex-shrink-0">
                                                            <ul className="list-inline ms-auto mb-0 chat-msg-option">
                                                                <li className="list-inline-item">
                                                                    <div className="dropdown">
                                                                        <a
                                                                            className="avtar avtar-xs btn-link-secondary dropdown-toggle arrow-none"
                                                                            href="#"
                                                                            data-bs-toggle="dropdown"
                                                                            aria-haspopup="true"
                                                                            aria-expanded="false"
                                                                        >
                                                                            <i className="ti ti-dots-vertical f-18"></i>
                                                                        </a>
                                                                        <div className="dropdown-menu">
                                                                            <a className="dropdown-item" href="#"><i className="ti ti-arrow-back-up"></i> Reply</a>
                                                                            <a className="dropdown-item" href="#"><i className="ti ti-arrow-forward-up"></i> Forward</a>
                                                                            <a className="dropdown-item" href="#"><i className="ti ti-copy"></i> Copy</a>
                                                                            <a className="dropdown-item" href="#"><i className="ti ti-trash"></i> Delete</a>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li className="list-inline-item">
                                                                    <a href="#" className="avtar avtar-xs btn-link-secondary">
                                                                        <i className="ti ti-edit-circle f-18"></i>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="flex-grow-1 ms-3">
                                                            <div className="msg-content bg-primary">
                                                                <p className="mb-0">Hi...Henny!!</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center mb-1 chat-msg">
                                                        <div className="flex-shrink-0">
                                                            <ul className="list-inline ms-auto mb-0 chat-msg-option">
                                                                <li className="list-inline-item">
                                                                    <div className="dropdown">
                                                                        <a
                                                                            className="avtar avtar-xs btn-link-secondary dropdown-toggle arrow-none"
                                                                            href="#"
                                                                            data-bs-toggle="dropdown"
                                                                            aria-haspopup="true"
                                                                            aria-expanded="false"
                                                                        >
                                                                            <i className="ti ti-dots-vertical f-18"></i>
                                                                        </a>
                                                                        <div className="dropdown-menu">
                                                                            <a className="dropdown-item" href="#"><i className="ti ti-arrow-back-up"></i> Reply</a>
                                                                            <a className="dropdown-item" href="#"><i className="ti ti-arrow-forward-up"></i> Forward</a>
                                                                            <a className="dropdown-item" href="#"><i className="ti ti-copy"></i> Copy</a>
                                                                            <a className="dropdown-item" href="#"><i className="ti ti-trash"></i> Delete</a>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li className="list-inline-item">
                                                                    <a href="#" className="avtar avtar-xs btn-link-secondary">
                                                                        <i className="ti ti-edit-circle f-18"></i>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="flex-grow-1 ms-3">
                                                            <div className="msg-content bg-primary">
                                                                <p className="mb-0">Are u listening me ?</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center mb-1 chat-msg">
                                                        <div className="flex-shrink-0">
                                                            <ul className="list-inline ms-auto mb-0 chat-msg-option">
                                                                <li className="list-inline-item">
                                                                    <div className="dropdown">
                                                                        <a
                                                                            className="avtar avtar-xs btn-link-secondary dropdown-toggle arrow-none"
                                                                            href="#"
                                                                            data-bs-toggle="dropdown"
                                                                            aria-haspopup="true"
                                                                            aria-expanded="false"
                                                                        >
                                                                            <i className="ti ti-dots-vertical f-18"></i>
                                                                        </a>
                                                                        <div className="dropdown-menu">
                                                                            <a className="dropdown-item" href="#"><i className="ti ti-arrow-back-up"></i> Reply</a>
                                                                            <a className="dropdown-item" href="#"><i className="ti ti-arrow-forward-up"></i> Forward</a>
                                                                            <a className="dropdown-item" href="#"><i className="ti ti-copy"></i> Copy</a>
                                                                            <a className="dropdown-item" href="#"><i className="ti ti-trash"></i> Delete</a>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li className="list-inline-item">
                                                                    <a href="#" className="avtar avtar-xs btn-link-secondary">
                                                                        <i className="ti ti-edit-circle f-18"></i>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="flex-grow-1 ms-3">
                                                            <div className="msg-content bg-primary">
                                                                <p className="mb-0">How can i cap you today?</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="message-in">
                                            <div className="d-flex">
                                                <div className="flex-shrink-0">
                                                    <div className="chat-avtar">
                                                        <img className="rounded-circle img-fluid wid-40" src="../assets/images/user/avatar-3.jpg" alt="User image" />
                                                        <i className="chat-badge bg-success"></i>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1 mx-3">
                                                    <div className="d-flex align-items-start flex-column">
                                                        <p className="mb-1 text-muted">Agilulf Fuxg <small>11:23 AM</small></p>
                                                        <div className="message d-flex align-items-start flex-column">
                                                            <div className="d-flex align-items-center mb-1 chat-msg">
                                                                <div className="flex-grow-1 me-3">
                                                                    <div className="msg-content card mb-0">
                                                                        <p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                                                            industry's standard dummy text ever since the 1500s.</p>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-shrink-0">
                                                                    <ul className="list-inline ms-auto mb-0 chat-msg-option">
                                                                        <li className="list-inline-item">
                                                                            <div className="dropdown">
                                                                                <a
                                                                                    className="avtar avtar-xs btn-link-secondary dropdown-toggle arrow-none"
                                                                                    href="#"
                                                                                    data-bs-toggle="dropdown"
                                                                                    aria-haspopup="true"
                                                                                    aria-expanded="false"
                                                                                >
                                                                                    <i className="ti ti-dots-vertical f-18"></i>
                                                                                </a>
                                                                                <div className="dropdown-menu">
                                                                                    <a className="dropdown-item" href="#"><i className="ti ti-arrow-back-up"></i> Reply</a>
                                                                                    <a className="dropdown-item" href="#"><i className="ti ti-arrow-forward-up"></i> Forward</a>
                                                                                    <a className="dropdown-item" href="#"><i className="ti ti-copy"></i> Copy</a>
                                                                                    <a className="dropdown-item" href="#"><i className="ti ti-trash"></i> Delete</a>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                        <li className="list-inline-item">
                                                                            <a href="#" className="avtar avtar-xs btn-link-secondary">
                                                                                <i className="ti ti-edit-circle f-18"></i>
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="message-out">
                                            <div className="d-flex align-items-end flex-column">
                                                <p className="mb-1 text-muted"><small>9h ago</small></p>
                                                <div className="message d-flex align-items-end flex-column">
                                                    <div className="d-flex align-items-center mb-1 chat-msg">
                                                        <div className="flex-shrink-0">
                                                            <ul className="list-inline ms-auto mb-0 chat-msg-option">
                                                                <li className="list-inline-item">
                                                                    <div className="dropdown">
                                                                        <a
                                                                            className="avtar avtar-xs btn-link-secondary dropdown-toggle arrow-none"
                                                                            href="#"
                                                                            data-bs-toggle="dropdown"
                                                                            aria-haspopup="true"
                                                                            aria-expanded="false"
                                                                        >
                                                                            <i className="ti ti-dots-vertical f-18"></i>
                                                                        </a>
                                                                        <div className="dropdown-menu">
                                                                            <a className="dropdown-item" href="#"><i className="ti ti-arrow-back-up"></i> Reply</a>
                                                                            <a className="dropdown-item" href="#"><i className="ti ti-arrow-forward-up"></i> Forward</a>
                                                                            <a className="dropdown-item" href="#"><i className="ti ti-copy"></i> Copy</a>
                                                                            <a className="dropdown-item" href="#"><i className="ti ti-trash"></i> Delete</a>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li className="list-inline-item">
                                                                    <a href="#" className="avtar avtar-xs btn-link-secondary">
                                                                        <i className="ti ti-edit-circle f-18"></i>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="flex-grow-1 ms-3">
                                                            <div className="msg-content bg-primary">
                                                                <p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                                                    industry's standard dummy text ever since the 1500s.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer py-2 px-3">
                                    <textarea className="form-control border-0 shadow-none" placeholder="Type a Message" rows="2"></textarea>
                                    <hr className="my-2" />
                                    <div className="d-sm-flex align-items-center">
                                        <ul className="list-inline me-auto mb-0">
                                            <li className="list-inline-item">
                                                <a href="#" className="avtar avtar-xs btn-link-secondary">
                                                    <i className="ti ti-paperclip f-18"></i>
                                                </a>
                                            </li>
                                            <li className="list-inline-item">
                                                <a href="#" className="avtar avtar-xs btn-link-secondary">
                                                    <i className="ti ti-photo f-18"></i>
                                                </a>
                                            </li>
                                            <li className="list-inline-item">
                                                <a href="#" className="avtar avtar-xs btn-link-secondary">
                                                    <i className="ti ti-mood-smile f-18"></i>
                                                </a>
                                            </li>
                                            <li className="list-inline-item">
                                                <a href="#" className="avtar avtar-xs btn-link-secondary">
                                                    <i className="ti ti-volume f-18"></i>
                                                </a>
                                            </li>
                                        </ul>
                                        <ul className="list-inline ms-auto mb-0">
                                            <li className="list-inline-item">
                                                <a href="#" className="avtar avtar-s btn-link-primary">
                                                    <i className="ti ti-send f-18"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="offcanvas-xxl offcanvas-end chat-offcanvas" tabindex="-1" id="offcanvas_User_info">
                            <div className="offcanvas-header">
                                <button className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#offcanvas_User_info" aria-label="Close"></button>
                            </div>
                            <div className="offcanvas-body p-0">
                                <div id="chat-user_info" className="collapse collapse-horizontal">
                                    <div className="chat-user_info">
                                        <div className="card">
                                            <div className="text-center card-body position-relative pb-0">
                                                <h5 className="text-start">Profile View</h5>
                                                <div className="position-absolute end-0 top-0 p-3 d-none d-xxl-inline-flex">
                                                    <a
                                                        href="#"
                                                        className="avtar avtar-xs btn-link-danger btn-pc-default"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#chat-user_info"
                                                    >
                                                        <i className="ti ti-x f-16"></i>
                                                    </a>
                                                </div>
                                                <div className="chat-avtar d-inline-flex mx-auto">
                                                    <img className="rounded-circle img-fluid wid-100" src="../assets/images/user/avatar-5.jpg" alt="User image" />
                                                </div>
                                                <h5 className="mb-0">Alene</h5>
                                                <p className="text-muted text-sm">Sr. Customer Manager</p>
                                                <div className="d-flex align-items-center justify-content-center mb-4">
                                                    <i className="chat-badge bg-success me-2"></i>
                                                    <span className="badge bg-light-success">Available</span>
                                                </div>
                                                <ul className="list-inline ms-auto mb-0">
                                                    <li className="list-inline-item">
                                                        <a href="#" className="avtar avtar-s btn-link-secondary">
                                                            <i className="ti ti-phone-call f-18"></i>
                                                        </a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="#" className="avtar avtar-s btn-link-secondary">
                                                            <i className="ti ti-message-circle f-18"></i>
                                                        </a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="#" className="avtar avtar-s btn-link-secondary">
                                                            <i className="ti ti-video f-18"></i>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="scroll-block">
                                                <div className="card-body">
                                                    <div className="row mb-3">
                                                        <div className="col-6">
                                                            <div className="p-3 rounded bg-light-primary">
                                                                <p className="mb-1">All File</p>
                                                                <div className="d-flex align-items-center">
                                                                    <i className="ti ti-folder f-22 text-primary"></i>
                                                                    <h4 className="mb-0 ms-2">231</h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="p-3 rounded bg-light-secondary">
                                                                <p className="mb-1">All Link</p>
                                                                <div className="d-flex align-items-center">
                                                                    <i className="ti ti-link f-22 text-secondary"></i>
                                                                    <h4 className="mb-0 ms-2">231</h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-check form-switch d-flex align-items-center justify-content-between p-0">
                                                        <label className="form-check-label h5 mb-0" for="customSwitchemlnot1">Notification</label>
                                                        <input
                                                            className="form-check-input h5 m-0 position-relative"
                                                            type="checkbox"
                                                            id="customSwitchemlnot1"
                                                            checked=""
                                                        />
                                                    </div>
                                                    <hr className="my-3 border border-secondary-subtle" />
                                                    <a className="btn border-0 p-0 text-start w-100" data-bs-toggle="collapse" href="#filtercollapse1">
                                                        <div className="float-end"><i className="ti ti-chevron-down"></i></div>
                                                        <h5 className="mb-0">Information</h5>
                                                    </a>
                                                    <div className="collapse show" id="filtercollapse1">
                                                        <div className="py-3">
                                                            <div className="d-flex align-items-center justify-content-between mb-2">
                                                                <p className="mb-0">Address</p>
                                                                <p className="mb-0 text-muted">Port Narcos</p>
                                                            </div>
                                                            <div className="d-flex align-items-center justify-content-between mb-2">
                                                                <p className="mb-0">Email</p>
                                                                <p className="mb-0 text-muted">alene@company.com</p>
                                                            </div>
                                                            <div className="d-flex align-items-center justify-content-between mb-2">
                                                                <p className="mb-0">Phone</p>
                                                                <p className="mb-0 text-muted">380-293-0177</p>
                                                            </div>
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <p className="mb-0">Last visited</p>
                                                                <p className="mb-0 text-muted">2 hours</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr className="my-3 border border-secondary-subtle" />
                                                    <a className="btn border-0 p-0 text-start w-100" data-bs-toggle="collapse" href="#filtercollapse2">
                                                        <div className="float-end"><i className="ti ti-chevron-down"></i></div>
                                                        <h5 className="mb-0">File type</h5>
                                                    </a>
                                                    <div className="collapse show" id="filtercollapse2">
                                                        <div className="py-3">
                                                            <div className="d-flex align-items-center mb-2">
                                                                <a href="#" className="avtar avtar-s btn-light-success">
                                                                    <i className="ti ti-file-text f-20"></i>
                                                                </a>
                                                                <div className="flex-grow-1 ms-3">
                                                                    <h6 className="mb-0">Document</h6>
                                                                    <span className="text-muted text-sm">123 files, 193MB</span>
                                                                </div>
                                                                <a href="#" className="avtar avtar-xs btn-link-secondary">
                                                                    <i className="ti ti-chevron-right f-16"></i>
                                                                </a>
                                                            </div>
                                                            <div className="d-flex align-items-center mb-2">
                                                                <a href="#" className="avtar avtar-s btn-light-warning">
                                                                    <i className="ti ti-photo f-20"></i>
                                                                </a>
                                                                <div className="flex-grow-1 ms-3">
                                                                    <h6 className="mb-0">Photos</h6>
                                                                    <span className="text-muted text-sm">53 files, 321MB</span>
                                                                </div>
                                                                <a href="#" className="avtar avtar-xs btn-link-secondary">
                                                                    <i className="ti ti-chevron-right f-16"></i>
                                                                </a>
                                                            </div>
                                                            <div className="d-flex align-items-center mb-2">
                                                                <a href="#" className="avtar avtar-s btn-light-primary">
                                                                    <i className="ti ti-id f-20"></i>
                                                                </a>
                                                                <div className="flex-grow-1 ms-3">
                                                                    <h6 className="mb-0">Other</h6>
                                                                    <span className="text-muted text-sm">49 files, 193MB</span>
                                                                </div>
                                                                <a href="#" className="avtar avtar-xs btn-link-secondary">
                                                                    <i className="ti ti-chevron-right f-16"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

            </div>



        </Layout>
    )
}


export default ResidentWall

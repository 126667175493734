import React, { useState, useEffect, useRef } from 'react'
import { DataTable } from 'primereact/datatable';
import { Link, useNavigate } from "react-router-dom";
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import QRCode from "react-qr-code";
import { toPng } from "html-to-image";
import download from "downloadjs";
import 'primereact/resources/themes/lara-light-blue/theme.css';
import { makeRequest2 } from '../../../utils/makeRequest'
import { toastify } from '../../../utils/toast'
import { Dialog } from 'primereact/dialog';
import { getFamilyMembersURL, deleteFamilyMemberURL, handleFamilyMemberStatusURL } from '../../../utils/urls'
import { getItem } from '../../../utils/localStorage';
import Layout from '../component/layout'
import ConfirmDialog from '../component/confirmDialog';
import { filterObjectsByAllProperties } from '../../../utils/filterSearch';

function QRCodeScreen() {
    const qrCodeRef = useRef();
    const navigate = useNavigate()
    const [family, setFamily] = useState([])
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedQRCode, setSelectedQRCode] = useState({})
    const [visibleQRCode, setVisibleQRCode] = useState(false)
    const [customerId, setCustomerId] = useState('')
    const [customerNo, setCustomerNo] = useState("")
    const [qrData, setQRData] = useState(null)
    const [selectedNo, setSelectedNo] = useState(null);
    const [confirmAction, setConfirmAction] = useState('');  // Track the current action ('delete', 'enable', 'disable')
    const [selectedDisableStatus, setSelectedDisableStatus] = useState(false);  // Track the current disable status
    const [isConfirmDialogVisible, setConfirmDialogVisible] = useState(false);
    const [selectedFamilyData, setSelectedFamilyData] = useState(null);
    const [selectedVehicleData, setSelectedVehicleData] = useState(null);
    const [selectedStaffData, setSelectedStaffData] = useState(null);
    const [actionType, setActionType] = useState('')


    const handleDownloadQRCode = () => {
        if (qrCodeRef.current) {
            console.log(qrCodeRef.current)
            toPng(qrCodeRef.current)
                .then((dataUrl) => {

                    download(dataUrl, "qrcode.png");
                })
                .catch((err) => {
                    console.error("Oops, something went wrong!", err);
                });
        }
    };

    const fetchFamilyMembers = async () => {
        try {
            const CustomerId = await getItem('selectedCustomerId');
            const response = await makeRequest2(getFamilyMembersURL + '/' + CustomerId, 'GET', {})

            if (response.success) {
                setFamily(response.data.familyMembers)
            }
            else {
                throw new Error(response.error)
            }
        }
        catch (err) {
            throw new Error(err.message)
        }
    }




    const getCustomerId = async () => {
        const cID = await getItem('selectedCustomerId');
        setCustomerId(cID)
    }
    const getCustomerNo = async () => {
        try {
            const customerNo = await getItem('selectedCustomerNo')
            setCustomerNo(customerNo)
        }
        catch (err) {
            console.log(err.message)
        }
    }


    const getCurrentUserType = async () => {
        const value = await getItem('RESIDENTUSER')
        const data = JSON.parse(value);
        const role = data.user.role
        console.log(data.user)

        if (role === 'admin') {
            setQRData(JSON.stringify({
                type: 'Customer',
                customerId: customerId
            }))
        }
        else if (role === 'family') {


            if (value) {
                const data = JSON.parse(value)
                const familyMember = data.user.familyMember.familyMembers.filter((x) => {
                    return x.email === data.user.email
                })


                if (familyMember[0]) {
                    let data = familyMember[0]
                    data.type = 'Family'
                    data.customerId = customerId

                    setCustomerNo(familyMember[0].no)
                    setQRData(JSON.stringify(data))
                }


            }

        }
    }

    useEffect(() => {
        fetchFamilyMembers()
        getCustomerId()
        getCustomerNo()
        getCurrentUserType()
    }, [])

    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" label="CSV" rounded onClick={() => { }} data-pr-tooltip="CSV" />
            <Button type="button" label="XLS" severity="success" rounded onClick={() => { }} data-pr-tooltip="XLS" />
            <Button type="button" label="PDF" severity="warning" rounded onClick={() => { }} data-pr-tooltip="PDF" />
        </div>
    );
    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/resident/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Visitor Access</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>QR Code </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">


                    <div className="row">

                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">

                                        <div className="col-md-4 col-xs-12 mt-3" >


                                        </div>

                                        <div className="col-md-4 col-xs-12 mt-3 text-center" >

                                            <div ref={qrCodeRef}>
                                                {
                                                    qrData !== null && <QRCode value={qrData} />
                                                }

                                                <br />
                                                <br />
                                                <p style={{ color: 'white', backgroundColor: 'black' }}>{customerNo}</p>
                                            </div>
                                            <br />
                                            <br />
                                            <button className="btn btn-primary mt-3" onClick={handleDownloadQRCode}>
                                                Download QR Code
                                            </button>
                                        </div>
                                        <div className="col-md-4 col-xs-12 mt-3" >

                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>


                    </div>

                </div>
            </div>


        </Layout>
    )
}


export default QRCodeScreen
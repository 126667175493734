import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import Layout from "../component/layout";
import ConfirmDialog from "../component/confirmDialog";
import { toastify } from "../../../utils/toast";
import { getItem } from "../../../utils/localStorage";
import { makeRequest2 } from "../../../utils/makeRequest";
import { getApprovalTicketURL, approveWorkOrderURL, denyWorkOrderURL } from "../../../utils/urls";

const ViewApprovalTicket = () => {
    const { ticketId } = useParams();
    const navigate = useNavigate();

    const [ticket, setTicket] = useState(null);
    const [isConfirmDialogVisible, setConfirmDialogVisible] = useState(false);
    const [confirmAction, setConfirmAction] = useState("");




    const fetchTicketDetails = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');
            const response = await makeRequest2(`${getApprovalTicketURL}/${facilityId}/${ticketId}`, 'GET', {});

            if (response.success) {
                setTicket(response.data);
            }
            else {
                throw new Error(response.message || 'Failed to fetch ticket details');
            }
        } catch (err) {
            toastify(err.message, 'error');
        }
    };


    const handleAccept = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');
            const customerId = await getItem('selectedCustomerId');

            const response = await makeRequest2(`${approveWorkOrderURL}/${facilityId}/${ticketId}`, 'POST', {
                status: 'in progress',
                requester: customerId,
            });

            if (response.success) {
                toastify('Work order activated successfully!', 'success');
                navigate('/resident/view_tickets');
            } else {
                throw new Error(response.message || 'Failed to activate work order');
            }
        } catch (err) {
            toastify(err.message, 'error');
        }
    };


    const handleDeny = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');
            const customerId = await getItem('selectedCustomerId');


            const response = await makeRequest2(`${denyWorkOrderURL}/${facilityId}/${ticketId}`, 'POST', {
                status: 'cancelled',
                requester: customerId,
            });

            if (response.success) {
                toastify('Work order cancelled successfully!', 'success');
                navigate('/resident/view_tickets');
            } else {
                throw new Error(response.message || 'Failed to cancel work order');
            }
        } catch (err) {
            toastify(err.message, 'error');
        }
    };



    const actionBodyTemplate = () => {
        return (
            <div className="d-flex justify-content-center gap-3 mt-4">
                <button
                    className="btn btn-success px-4 py-2"
                    onClick={() => {
                        setConfirmAction("accept");
                        setConfirmDialogVisible(true);
                    }}
                >
                    Accept
                </button>
                <button
                    className="btn btn-danger px-4 py-2"
                    onClick={() => {
                        setConfirmAction("deny");
                        setConfirmDialogVisible(true);
                    }}
                >
                    Deny
                </button>
            </div>
        );
    };


    const handleConfirmAction = async () => {
        setConfirmDialogVisible(false);

        if (confirmAction === "accept") {
            await handleAccept();
        } else if (confirmAction === "deny") {
            await handleDeny();
        }
    };


    useEffect(() => {
        fetchTicketDetails();
    }, []);

    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/facility/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/resident/approvals"}>Ticket Approvals</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>View Ticket</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">

                    <div className="card">
                        <div className="card-header">
                            <Link to={"#"} onClick={() => navigate(-1)}><span><i className="ti ti-arrow-narrow-left"></i> Back</span></Link>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body">
                            <div className="card-header text-center py-3 h4">
                                🎟️ Ticket Details
                            </div>
                            <div className="card p-4">
                                <div className="card-body p-4">
                                    <div className="row mb-4">
                                        {/* Ticket Number and Ticket Type */}
                                        <div className="col-md-6 mb-3">
                                            <h5 className="text-dark">Ticket Number:</h5>
                                            <p className="text-dark fw-bold">{ticket?.ticketNumber || "N/A"}</p>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <h5 className="text-dark">Ticket Type:</h5>
                                            <p className="text-dark fw-bold">{ticket?.ticketType || "N/A"}</p>
                                        </div>

                                        {/* Description */}
                                        <div className="col-md-12 mb-3">
                                            <h5 className="text-dark">Description:</h5>
                                            <p className="text-dark">{ticket?.description || "No description provided."}</p>
                                        </div>

                                        {/* Review Text */}
                                        <div className="col-md-12 mb-3">
                                            <h5 className="text-dark">Review Text:</h5>
                                            <p className="text-dark">{ticket?.reviewText || "No review provided."}</p>
                                        </div>

                                        {/* Services */}
                                        <div className="col-md-12 mb-3">
                                            <h5 className="text-muted">Services:</h5>
                                            {ticket?.services && ticket.services.length > 0 ? (
                                                <div>
                                                    {ticket.services.map((service, index) => (
                                                        <div
                                                            key={index}
                                                            className="d-flex justify-content-between align-items-center py-2"
                                                            style={{ borderBottom: "1px solid #e9ecef" }} // Optional: Add a subtle divider between items
                                                        >
                                                            <span className="text-dark">
                                                                <strong>Description:</strong> {service.description}
                                                            </span>
                                                            <span className="text-primary fw-bold">
                                                                ${service.amount}
                                                            </span>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                <p className="text-dark">No services available for this ticket.</p>
                                            )}
                                        </div>


                                        {/* Cost Attachment */}
                                        {ticket?.costAttachment && (
                                            <div className="col-md-12 mb-3">
                                                <h5 className="text-dark">Cost Attachment:</h5>
                                                <a
                                                    href={`/${ticket.costAttachment}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="btn btn-link text-primary"
                                                >
                                                    View Attachment
                                                </a>
                                            </div>
                                        )}

                                        {/* Total Cost */}
                                        <div className="col-md-12 mb-3">
                                            <h5 className="text-dark">Total Amount:</h5>
                                            <p className="text-dark fw-bold">${ticket?.totalAmount || 0}</p>
                                        </div>
                                    </div>

                                    {/* Action Buttons */}
                                    {actionBodyTemplate()}
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
            {/* Confirm dialog */}
            <ConfirmDialog
                visible={isConfirmDialogVisible}
                onHide={() =>
                    setConfirmDialogVisible(false)
                }
                message={`Are you sure you want to ${confirmAction} this ticket?`}
                onConfirm={handleConfirmAction}
            />
        </Layout>
    );
};

export default ViewApprovalTicket;

import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import Layout from '../component/layout'
import { toastify } from "../../../utils/toast";
import { getItem } from '../../../utils/localStorage'
import { makeRequest2 } from '../../../utils/makeRequest'
import { getApprovalTicketsURL } from '../../../utils/urls'

const Approvals = () => {

    const [tickets, setTickets] = useState([]);

    const getApprovalTickets = async () => {
        try {
            const customerId = await getItem('selectedCustomerId');
            const facilityId = await getItem('selectedFacilityId');

            const response = await makeRequest2(getApprovalTicketsURL + '/' + facilityId + '/' + customerId, 'GET', {});

            console.log("Response", response);

            if (response.success) {
                const filteredTickets = response.data.filter(ticket => ticket.payer === 'tenant' && ticket.status === 'on hold');
                setTickets(filteredTickets);
            }
        } catch (err) {
            toastify(err.message, 'error');
        }
    };

    const statusTemplate = (rowData) => {
        const statusStyles = {
            open: { color: '#28a745', backgroundColor: '#e9fbe9' },
            closed: { color: '#007bff', backgroundColor: '#e0f7ff' },
            resolved: { color: '#17a2b8', backgroundColor: '#e0f4f7' },
            cancelled: { color: '#dc3545', backgroundColor: '#fde9e9' },
            ongoing: { color: '#ffc107', backgroundColor: '#fff8e1' },
        };

        const style = statusStyles[rowData.status] || {
            color: '#6c757d',
            backgroundColor: '#f1f1f1',
        };

        return (
            <span
                style={{
                    ...style,
                    padding: '0.5em 1em',
                    borderRadius: '12px',
                    fontWeight: 'bold',
                    fontSize: '0.9rem',
                    display: 'inline-block',
                }}
            >
                {rowData.status}
            </span>
        );
    };

    const actionTemplate = (rowData) => {
        return (
            <Link
                to={`/resident/approvals/view_approval_ticket/${rowData._id}`}
                style={{
                    color: '#007bff',
                    textDecoration: 'none',
                    fontSize: '18px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                title="View Ticket"
            >
                <i className="ti ti-eye"></i>
            </Link>
        );
    };

    useEffect(() => {
        getApprovalTickets();
    }, []);


    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/resident/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/resident/approvals"}>My Approvals</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">

                            <div className="tab-pane fade show active mt-2" id="analytics-tab-1-pane" role="tabpanel"
                                aria-labelledby="analytics-tab-1" tabindex="0">
                                <div className="table-responsive">
                                    <DataTable value={tickets} emptyMessage="No Approval tickets found." sortMode="multiple" paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>
                                        <Column field="ticketNumber" header="Ticket Number"></Column>
                                        <Column field="ticketType" header="Ticket Type"></Column>
                                        <Column field="description" header="Description"></Column>
                                        <Column field="status" header="Status" body={statusTemplate}></Column>
                                        <Column header="Actions" body={actionTemplate}></Column>
                                    </DataTable>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </Layout>
    )
}

export default Approvals
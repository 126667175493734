import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { makeRequest2 } from "../../../../utils/makeRequest";
import { getItem } from '../../../../utils/localStorage';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Layout from "../../component/layout";
import styles from "./waterInvoice.module.css";
import PaymentModal from '../../../../utils/paymentModal';

// API endpoint URLs
import {
  getWaterInvoice,
  showLevySettings,
  getCompanyInformationURL,
  fetchFacilityPaymentDetails
} from "../../../../utils/urls";

const WaterInvoicePage = () => {
  const navigate = useNavigate();
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [paymentSettings, setPaymentSettings] = useState(null);
  const [companyDetails, setCompanyDetails] = useState(null);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showReceiptsDialog, setShowReceiptsDialog] = useState(false);

  const location = useLocation();
  const { invoiceId } = location.state || {};
  const toast = useRef(null);
  const isMounted = useRef(true);

  const fetchInvoiceData = useCallback(async (currentFacilityId) => {
    if (!currentFacilityId || !invoiceId) return;

    try {
      const response = await makeRequest2(
        `${getWaterInvoice}/${invoiceId}?facilityId=${currentFacilityId}`,
        "GET",
        {}
      );

      if (response.success) {
        const invoiceData = response.data?.data?.invoice || response.data?.invoice;
        
        if (invoiceData && isMounted.current) {
          setInvoice(invoiceData);
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error",
            detail: "Invoice data is invalid",
          });
        }
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: response.message || "Failed to fetch invoice data",
        });
      }
    } catch (error) {
      console.error("Error fetching invoice:", error);
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: error.message || "An unexpected error occurred",
      });
    }
  }, [invoiceId]);

  const fetchCompanyDetails = useCallback(async (currentFacilityId) => {
    if (!currentFacilityId) return;

    try {
      const response = await makeRequest2(
        `${getCompanyInformationURL}/${currentFacilityId}`,
        "GET",
        {}
      );

      if (response.success && isMounted.current) {
        setCompanyDetails(response.data);
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to fetch company details",
        });
      }
    } catch (err) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: err.message,
      });
    }
  }, []);

  useEffect(() => {
    const initializeData = async () => {
      try {
        const currentFacilityId = await getItem('selectedFacilityId');
        if (!currentFacilityId) {
          toast.current?.show({
            severity: "error",
            summary: "Error",
            detail: "Facility ID not found",
          });
          return;
        }

        setLoading(true);
        try {
          await Promise.all([
            fetchInvoiceData(currentFacilityId),
            fetchCompanyDetails(currentFacilityId)
          ]);
        } catch (error) {
          toast.current?.show({
            severity: "error",
            summary: "Error",
            detail: "Error fetching data",
          });
        } finally {
          if (isMounted.current) {
            setLoading(false);
          }
        }
      } catch (err) {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: "Error retrieving data",
        });
        setLoading(false);
      }
    };

    isMounted.current = true;
    initializeData();

    return () => {
      isMounted.current = false;
    };
  }, [fetchInvoiceData, fetchCompanyDetails]);

  const calculateBalance = useCallback(() => {
    if (!invoice) return 0;
    return (invoice.charges?.totalMonthlyBill || 0) - (invoice.amountPaid || 0);
  }, [invoice]);

  const handleShowPaymentModal = useCallback(() => {
    if (!invoice?.unitName) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Unit Number is missing'
      });
      return;
    }

    if (!invoice?.customerId) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Customer ID is missing'
      });
      return;
    }

    if (!invoice?.accountNumber) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Account number is missing'
      });
      return;
    }

    setShowPaymentModal(true);
  }, [invoice]);

  const handleDownloadPDF = async () => {
    const element = document.getElementById("printable-water-invoice");
    const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
    const filename = `WaterInvoice_${invoice?.invoiceNumber}_${timestamp}.pdf`;

    try {
      setLoading(true);
      toast.current?.show({
        severity: "info",
        summary: "Processing",
        detail: "Generating PDF...",
      });

      const canvas = await html2canvas(element, {
        scale: 2,
        useCORS: true,
        logging: false,
        backgroundColor: "#ffffff",
      });

      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      const imgWidth = 210;
      const pageHeight = 297;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight, "", "FAST");

      pdf.save(filename);

      toast.current?.show({
        severity: "success",
        summary: "Success",
        detail: "PDF downloaded successfully!",
      });
    } catch (error) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to generate PDF. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  const formatCurrency = (amount) => {
    const symbol = invoice?.currency?.symbol || "KSh";
    return `${symbol} ${Number(amount).toFixed(2)}`;
  };

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    try {
      return new Date(dateString).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    } catch (error) {
      console.error("Error formatting date:", error);
      return "Invalid Date";
    }
  };

  const viewReceipt = (paymentData) => {
    navigate(`/resident/statements/receipts/water_receipt/${paymentData._id}`, {
      state: {
        receipt: paymentData,
        invoice: invoice,
        companyDetails: companyDetails
      }
    });
  };

  const actionBodyTemplate = (rowData) => {
    const isMpesa = rowData.type.toUpperCase() === 'MPESA';
    return (
      <Button
        icon="fas fa-eye"
        className="p-button-sm p-button-text"
        onClick={() => viewReceipt(rowData)}
        disabled={!isMpesa}
        tooltip={isMpesa ? "View Receipt" : "Receipt not available"}
        tooltipOptions={{ position: 'left' }}
      />
    );
  };

  const isKenyaShilling = invoice?.currency?.code === 'KES';

  if (loading) {
    return <div className={styles.loading}>Loading...</div>;
  }

  if (!invoice) {
    return (
      <Layout>
        <Toast ref={toast} />
        <div className={styles.pageContainer}>
          <div className={styles.errorMessage}>
            No invoice data available. Please check the invoice ID and try again.
          </div>
          <Link to="/resident/water_meter_invoices/my_water_invoices">
            <Button label="Back to Invoices" className={styles.backButton} />
          </Link>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <Toast ref={toast} />
      <div className={styles.pageContainer}>
        {/* Action buttons with Payment Modal and Receipts */}
        <div className={styles.actionCard}>
          <div className={styles.actionCardLeft}>
            <button onClick={() => navigate(-1)} className={styles.backLink}>
              <i className="ti ti-arrow-narrow-left"></i>
              <span>Back</span>
            </button>
          </div>
          <div className={styles.actionCardRight}>
            <Button
              label="Receipts"
              onClick={() => setShowReceiptsDialog(true)}
              className="mr-2 btn btn-primary"
              style={{ marginRight: '10px' }}
            />
            <Button
              label="Download Invoice"
              onClick={handleDownloadPDF}
              className="mr-2 btn btn-primary"
              style={{ marginRight: '10px' }}
            />
            {calculateBalance() > 0 && isKenyaShilling && (
              <Button
                label="Pay Now"
                onClick={handleShowPaymentModal}
                className="mr-2 btn btn-primary"
              />
            )}
          </div>
        </div>

        {/* Receipts Dialog */}
        <Dialog
          header="Payment Receipts"
          visible={showReceiptsDialog}
          style={{ width: '50vw' }}
          onHide={() => setShowReceiptsDialog(false)}
        >
          <DataTable
            value={invoice?.reconciliationHistory || []}
            responsiveLayout="scroll"
            emptyMessage="No payment records found"
            className="p-datatable-sm"
          >
            <Column
              field="date"
              header="Date"
              body={(rowData) => formatDate(rowData.date)}
              sortable
            />
            <Column
              field="amount"
              header="Amount"
              body={(rowData) => formatCurrency(rowData.amount)}
              sortable
            />
            <Column
              field="type"
              header="Type"
              sortable
            />
            <Column
              field="paymentReference"
              header="Reference"
              sortable
            />
            <Column
              field="paymentCompletion"
              header="Completion"
              sortable
            />
            <Column
              body={actionBodyTemplate}
              exportable={false}
              style={{ width: '4rem' }}
            />
          </DataTable>
        </Dialog>

        {/* Payment Modal */}
        <PaymentModal
          visible={showPaymentModal}
          onHide={() => setShowPaymentModal(false)}
          accountNumber={invoice?.accountNumber}
          facilityId={invoice?.facilityId}
          customerId={invoice?.customerId}
          amount={calculateBalance()}
          onSuccess={async () => {
            try {
              const currentFacilityId = await getItem('selectedFacilityId');
              await fetchInvoiceData(currentFacilityId);
              setShowPaymentModal(false);
              toast.current.show({
                severity: 'success',
                summary: 'Success',
                detail: 'Payment processed successfully!'
              });
            } catch (error) {
              console.error('Error refreshing invoice data:', error);
            }
          }}
          onError={(error) => {
            console.error('Payment Error Details:', error);
            toast.current.show({
              severity: 'error',
              summary: 'Error',
              detail: error.response?.data?.message || 'Payment processing failed. Please try again.'
            });
            setShowPaymentModal(false);
          }}
        />

        {/* Actual invoice section */}
        <div
          id="printable-water-invoice"
          className={styles.invoiceWrapper}
        >
          {/* Header / Title */}
          <div className={styles.invoiceHeader}>
            <h1 className={styles.invoiceTitle}>WATER INVOICE</h1>
            <div className={styles.invoiceInfo}>
              <div>
                <span className={styles.infoLabel}>
                  Invoice #:
                </span>{" "}
                <span>
                  {invoice.invoiceNumber || "N/A"}
                </span>
              </div>
              <div>
                <span className={styles.infoLabel}>Date:</span>{" "}
                <span>
                  {formatDate(invoice.dateIssued)}
                </span>
              </div>
              <div>
                <span className={styles.infoLabel}>
                  Due Date:
                </span>{" "}
                <span>
                  {formatDate(invoice.dueDate)}
                </span>
              </div>
            </div>
          </div>

          {/* Company & Customer Section */}
          <div className={styles.companyCustomerSection}>
            <div className={styles.companyBlock}>
              <h2 className={styles.sectionHeading}>From</h2>
              <p className={styles.companyName}>
                {companyDetails?.name || invoice?.FacilityInfo?.name || "N/A"}
              </p>
              <p>{companyDetails?.address || invoice?.FacilityInfo?.location || "N/A"}</p>
              <p>{companyDetails?.country || "Kenya"}</p>
            </div>
            <div className={styles.customerBlock}>
              <h2 className={styles.sectionHeading}>Bill To</h2>
              <p className={styles.customerName}>
                {invoice?.CustomerInfo?.fullName || "N/A"}
              </p>
              <p>Unit: {invoice.unitName || "N/A"}</p>
              <p>Account: {invoice.accountNumber || "N/A"}</p>
            </div>
          </div>

          {/* Water Meter Details */}
          <div className={styles.meterDetails}>
            <h2 className={styles.sectionHeading}>
              Meter Details
            </h2>
            <table className={styles.meterTable}>
              <thead>
                <tr>
                  <th>Meter #</th>
                  <th>Current Reading</th>
                  <th>Previous Reading</th>
                  <th>Usage</th>
                  <th>Water Charge</th>
                  <th>Sewer Charge</th>
                  <th>Total Bill</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {invoice.meterNumber || "N/A"}
                  </td>
                  <td>
                    {invoice.meterReadings?.currentReading || 0}
                  </td>
                  <td>
                    {invoice.meterReadings?.previousReading || 0}
                  </td>
                  <td>
                    {invoice.meterReadings?.usage || 0} units
                  </td>
                  <td>
                    {formatCurrency(invoice.charges?.waterCharge || 0)}
                  </td>
                  <td>
                    {formatCurrency(invoice.charges?.sewerCharge || 0)}
                  </td>
                  <td>
                    {formatCurrency(invoice.charges?.totalMonthlyBill || 0)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Consumption & Notes */}
          <div className={styles.consumptionBlock}>
            <p>
              <span className={styles.infoLabel}>
                Consumption Period:
              </span>{" "}
              {invoice.consumptionPeriod ? (
                `${formatDate(invoice.consumptionPeriod.startDate)} - ${formatDate(invoice.consumptionPeriod.endDate)}`
              ) : (
                "N/A"
              )}
            </p>
            <p>
              <span className={styles.infoLabel}>
                Billing Type:
              </span>{" "}
              {invoice.billingType ? invoice.billingType.charAt(0).toUpperCase() + invoice.billingType.slice(1) : "N/A"}
            </p>
            <p>
              <span className={styles.infoLabel}>
                Payment Status:
              </span>{" "}
              <span className={styles[`status${invoice.status || "Pending"}`] || ""}>
                {invoice.status || "Pending"}
              </span>
            </p>
            <p>
              <span className={styles.infoLabel}>
                Invoice Note:
              </span>{" "}
              {invoice.invoiceNote || "N/A"}
            </p>
          </div>

          {/* Payment Details / Footer */}
          <div className={styles.footerSection}>
            <h2 className={styles.sectionHeading}>
              Payment Details
            </h2>
            <p>
              <strong>Bank:</strong>{" "}
              {paymentSettings?.bankName || "N/A"}
            </p>
            <p>
              <strong>Account:</strong>{" "}
              {paymentSettings?.accountNumber || "N/A"}
            </p>
            <p>
              <strong>Terms & Conditions:</strong>{" "}
              {paymentSettings?.termsAndConditions || "Payment is due within 10 days of invoice date"}
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default WaterInvoicePage;
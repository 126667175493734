import React, { useState, useEffect } from 'react'
import { Button } from 'primereact/button';
import { Link, useNavigate, useParams } from "react-router-dom";
import 'primereact/resources/themes/lara-light-blue/theme.css';
import { toastify } from '../../../utils/toast'
import { Dialog } from 'primereact/dialog';
import Layout from '../component/layout'
import { makeRequest2 } from '../../../utils/makeRequest';
import { getItem } from '../../../utils/localStorage';
import { addFamilyMemberURL, residentUnitsURL } from '../../../utils/urls'
function AddFamily() {
    // const { unitId } = useParams();
    const [unit, setUnit] = useState([])
    const [fullName, setFullName] = useState('');
    const [relation, setRelation] = useState('');
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [visitorQRCode, setVisitorQRCode] = useState(false)
    const [addVisitor, setAddVisitor] = useState(false)
    const [receiveMessage, setReceiveMessage] = useState(false)

    const navigate = useNavigate()

    const generateUniqueCode = () => {
        return Math.floor(10000 + Math.random() * 90000).toString(); // Generates a 5-digit unique number
    };

    const fetchResidentUnits = async () => {
        try {
            const CustomerId = await getItem('selectedCustomerId');
            const FacilityId = await getItem('selectedFacilityId');

            const response = await makeRequest2(residentUnitsURL + '/' + CustomerId + '/' + FacilityId, 'GET', {})
            if (response.success) {

                setUnit(response.data[0]._id)
            }
            else {
                throw new Error(response.error)
            }
        }
        catch (err) {

        }
    }



    const HandleSubmit = async () => {
        try {

            if (fullName === '') {
                throw new Error('Full Name is required.')
            }
            else if (relation === '') {
                throw new Error('Relation is required.')
            }
            else if (phoneNumber === '') {
                throw new Error('Phone Number is required.')
            }
            else if (!/^(07\d{8}|254\d{9})$/.test(phoneNumber)) {
                throw new Error('Phone number must be in the format "0712345678" or "254712345678".');
              }
            else if (email === '' && addVisitor) {
                throw new Error('Email is required.')
            }
            else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
                throw new Error('Invalid email address format.');
            }
            
            else {
                const CustomerId = await getItem('selectedCustomerId');
                const uniqueCode = generateUniqueCode();

                const response = await makeRequest2(addFamilyMemberURL + '/' + CustomerId, 'POST', {
                    fullName, relation, email, phoneNumber, uniqueCode, visitorQRCode, addVisitor, receiveMessage, unit
                })
                console.log("Unit", unit)
                if (response.success) {
                    toastify(response.data, 'success')
                    navigate('/resident/visitor_access/family')
                }
                else {
                    throw new Error(response.error)
                }
            }

        }
        catch (err) {
            console.log(err)
            toastify(err.message, 'error')
        }
    }


    useEffect(() => {
        fetchResidentUnits()
    }, [])



    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/resident/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Visitor Access</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/resident/visitor_access/family"}>Family</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Add Family</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <Link to="/resident/visitor_access/family">
                                <i className="ti ti-arrow-left"></i>
                                &nbsp;
                                Back
                            </Link>

                        </div>

                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label className="form-label">Fullname</label>
                                        <input type="text" className="form-control" value={fullName} placeholder="Full Name" onChange={(e) => {
                                            setFullName(e.target.value)
                                        }} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label className="form-label">Relation</label>
                                        <input type="text" className="form-control" value={relation} placeholder="Relation" onChange={(e) => {
                                            setRelation(e.target.value)
                                        }} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label className="form-label">Phone Number</label>
                                        <input type="text" className="form-control" placeholder='e.g 254711223344' value={phoneNumber} onChange={(e) => {
                                            setPhoneNumber(e.target.value)
                                        }} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label className="form-label">Email</label>
                                        <input type="email" className="form-control" value={email} placeholder="Email" onChange={(e) => {
                                            setEmail(e.target.value)
                                        }} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <input type="checkbox" placeholder="name"
                                            value={visitorQRCode}
                                            checked={visitorQRCode}
                                            onChange={(e) => {

                                                setVisitorQRCode(!visitorQRCode)

                                            }}
                                        ></input>
                                        &nbsp;&nbsp;
                                        <label>Generate QR Code</label>
                                        <br />
                                        <input type="checkbox" placeholder="name"
                                            value={addVisitor}
                                            checked={addVisitor}
                                            onChange={(e) => {

                                                setAddVisitor(!addVisitor)

                                            }}
                                        ></input>
                                        &nbsp;&nbsp;
                                        <label>Can Add a Visitor</label>
                                        <br />
                                        <input type="checkbox" placeholder="name"
                                            value={receiveMessage}
                                            checked={receiveMessage}
                                            onChange={(e) => {

                                                setReceiveMessage(!receiveMessage)

                                            }}
                                        ></input>
                                        &nbsp;&nbsp;
                                        <label>Can Receive Visitor Requests Messages</label>
                                    </div>

                                </div>


                            </div>
                        </div>
                        <div className="card-footer text-end btn-page">

                            <div className="btn btn-primary" onClick={() => {
                                HandleSubmit()
                            }}>Submit</div>
                        </div>
                    </div>
                </div>
            </div>

        </Layout>
    )
}


export default AddFamily